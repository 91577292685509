import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl, Token, Currency} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate, sort, formatGroupLabel, FormatNumber } from '../component/globalFunction'
import cellEditFactory from 'react-bootstrap-table2-editor';

const ClassFees =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [schoolClass, setSchoolClass]= useState([]);
    const [feecharge, setFeecharge] = useState([]);
const [studentFee, setStudentFee]=useState({
    studentClass:[],
    feecharge:[],
    remark:''
});
const [errors, setErrors] = useState({});
const [studentList, setStudentList] = useState([]);
const [classFee, setClassFee] =  useState([]);
const [actualFee, setActualFee] =  useState([]);
const [students, setStudents] =  useState([]);
    
    const config = {
        headers: { 'content-type': 'multipart/form-data'}
    }

const [structure, setStructure] =  useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);
  


const fetchFeecharge =()=>{
    let Url = ServerUrl+'/fetch_controller/tbl_fees_charge/'+Token;
    axios.get(Url).then(result=>setFeecharge(result.data))
}



const fetchStudent =(sectionID)=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT admissionNumber, registerNumber, studentName, code from tbl_students where sectionID ='"+sectionID+"' and termination <>'Yes' and admissionStatus = 'Studying'"

const fd = new FormData();
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller/'+Token;
axios.post(url, fd, config).then(result=>{
    
setStudentList(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}



const  handleFetchClassfee =(sectionID)=>{
    let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+sectionID+'/'+Token;
    axios.get(url)
    .then(result => {
        setClassFee(result.data)
        setActualFee(result.data)
    })
    .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
     }

       
        const handleSelect = (option, action)=>{
            
            setStudentFee({...studentFee, [action.name]: option});
            setErrors({...errors, [action.name]:''})
            if(action.name==='studentClass'){
                fetchStudent(option.value);
                handleFetchClassfee(option.value)
            }
            setStudents('')
         }



             const handleChange = event =>{
                const {name, value} = event.target
                setStudentFee({...studentFee, [name] : value });
               setErrors({...errors, [name]:''})
            }

            const handleChangeFee =(index, e)=>{
        
                const fees =JSON.parse(JSON.stringify(classFee))   
               const value =e.target.value;
              fees[index].price = value;
   
               setClassFee(fees)
                
           }


           const getActualFee = (ID) =>{        
            const result = actualFee && actualFee.filter(list=>list.ID===ID);
            const answer =result.length!==0?result[0].price:''
            return   answer
    
            }




const handleFetchStructure =()=>{
    var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.feeCharge, s.code, s.dateCreate, s.classID, s.sectionID  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code group by s.feeCharge, s.sectionID order by s.dateCreate DESC" ;
    
	const fd = new FormData();
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller/'+Token;
	axios.post(url, fd, config).then(result=>setStructure(result.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	})
}

   
const handleFetchStudent=(row)=>{
    setNotice({...notice, 
        isDataFetching: true})
    var sql ="SELECT CONCAT(s.admissionNumber, ' -', s.studentName) as student,  f.total, f.payment_status, f.remark, f.code, f.classID, f.sectionID from tbl_students s, tbl_student_fees f where f.studentCode = s.code and f.sectionID ='"+row.sectionID+"' and f.feeCharge ='"+row.feeCharge+"' " ;
    
	const fd = new FormData();
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller/'+Token;
	axios.post(url, fd, config).then(result=>setStudents(result.data))
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false})
            
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
    })
}

 const  handleFetchClass =()=>{
    let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1/'+Token;
    axios.get(url)
    .then(result => setSchoolClass(result.data))
    .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
     }


     

const ValidateInput =(values)=>{
    let errors = {};     
    if(studentList.length===0){
        errors.studentClass ='There are no student found in the selected class';
    }
    if(classFee.length===0){
        errors.studentClass ='There are no fees structure define for the selected class';
    }
    if(values.studentClass.length===0){
        errors.studentClass ='Please select class';
    }
    if(values.feecharge.length===0){
        errors.feecharge ='Please select fee charge';
    }
    
    return errors;
    }

const handleSubmit =(event)=>{
        event.preventDefault();
        setErrors(ValidateInput(studentFee));
        setIsSubmitting(true);
    }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }else{
            window.scrollTo(0,0);
        }
    },[errors])

   

function submit(){  
    
    var total = 0
     classFee.map(dat=>total+=Number(dat.price))
    
     const fd = new FormData(); 
    fd.append('classID', studentFee.studentClass.classID);
    fd.append('sectionID', studentFee.studentClass.value);
    fd.append('feecharge', studentFee.feecharge.value);
    fd.append('studentList', JSON.stringify(studentList, null, 2));
     fd.append('classFee', JSON.stringify(classFee, null, 2));
     fd.append('remark', studentFee.remark);
     fd.append('total', total);
     setNotice({...notice, 
        isLoading: true}) 

        const config = {
            headers: { 'content-type': 'multipart/form-data'}
        }
         let url = ServerUrl+'/save_controller/tbl_student_fees/'+Token;
        axios.post(url, fd, config)
        .then(response =>{

          if (response.data.type ==='success') {
                Alerts('Saved!', 'success', response.data.message)
            }else if (response.data.type ==='info') {
                Alerts('Update!', 'default', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }   
                
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false})  
        setIsSubmitting(false)
        setStudentFee({...studentFee, studentClass: [], feecharge:[], remark:'' });
        handleFetchStructure(); 
        setClassFee('')
        setActualFee('')
        setStudentList('')
        })  
          
}

const handleDelete = (feeID)=>{  
    setNotice({...notice, 
       isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+feeID)
          close.click();
       const fd = new FormData();
       fd.append('ID', 'ID')
		fd.append('data', feeID)

       const config = {
           headers: { 'content-type': 'multipart/form-data'}
       }
        let url = ServerUrl+'/delete_controller/tbl_student_fees/'+Token;
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchStructure()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}



    const getClassName = (ID) =>{        
        const result = schoolClass.filter(list=>list.ID===ID);
        return   result.map(c=>c.cname);
      }

        const getSectionName = (cID, sID) =>{       
           const result = schoolClass && schoolClass.filter(list=>list.ID===cID); 
           const section = result.map((data) =>
        data.section.filter(list=>list.ID===sID))
  
        const newResult = section[0]?section[0]:[]
        const answer = newResult.map(c=>c.sname); 
        const ClassName = getClassName(cID);
        const finalAnswer = answer.length===0?ClassName:answer
        return finalAnswer
        
      }


      const tableStudentHeader = [
        {dataField: 'student', text: 'Student Details',  editable: false},
        {dataField: 'classID', text: 'Class', formatter:(cell, row)=>getSectionName(row.classID, row.sectionID), editable: false},
        {dataField: 'total', text: 'Total ('+Currency+')', editable: false, formatter:(cell)=>FormatNumber(cell)},
        {dataField: 'payment_status', text: 'Status',  editable: false, formatter: (cell)=>cell==='PAID'?<span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
        {dataField: 'remark', text: 'Remark',  editable: false},
        {dataField: 'code', text: 'Action', editable: false, formatter:(cell)=><div><button className="btn btn-success btn-sm" type="button"> <i className="fa fa-print"></i></button>&nbsp;
        <WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' />
         <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`} title="Delete"><i className="fa fa-trash"></i></button>
        </div>},      
     ];

     const TableStudent=(props)=>{
       
            
    
        const { SearchBar } = Search;
        
        const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
        );
        const options = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
        { text: 'All', value: props.data.length }]
        
        };
        return  <ToolkitProvider search columnToggle 
                    keyField='code' data={ props.data } columns={ props.columns } >
                        
                        {
                            props => (
                            
                            <div className="form-group">
    
                        <SearchBar
                                     { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                    
                                   
                    <BootstrapTable
                                { ...props.baseProps }
                                            striped
                                        hover
                                        condensed
                                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                    pagination={paginationFactory(options) }
                                    
                                />
                            </div>
                            )
                        }
    </ToolkitProvider>
    }
    


    const tableClassFeesHeader = [
        {dataField: 'fees', text: 'Fees',  editable: false},
        {dataField: 'classID', text: 'Class', formatter:(cell, row)=>getSectionName(row.classID, row.sectionID), editable: false},
        {dataField: 'dateCreate', text: 'Created On', editable: false, formatter:(cell)=>longDate(cell)},
        {dataField: 'code', text: 'Action', editable: false, formatter:(cell, row)=><button className="btn btn-primary btn-sm" type="button" onClick={()=>handleFetchStudent(row)}> <i className="fa fa-graduation-cap"></i>View Student</button>},      
     ];

    

     const TableClassFees=(props)=>{
       
            
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">

                    <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                               
                <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

useEffect(()=>{
    handleFetchStructure()
    handleFetchClass();
    fetchFeecharge()
},[]);

        return (  <>
   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">

        <PageHeader title="Student Fees Structure">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a></li>
      <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
<li className="breadcrumb-item"><a href="#!">Student Fees Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
                
                <div className="row  ">
					<div className="col-sm-12">
	<div className="card z-depth-0">
                <div className="card-header">
                    <h5><i className="fa fa-edit" ></i> Student Fees Structure</h5>
                    <div className="card-header-right">
                        <ul className="list-unstyled card-option">
                            <li><i className="feather icon-maximizes full-card"></i></li>
                            <li><i className="feather icon-minus minimize-card"></i></li>
                            <li><i className="feather icon-trash-2 close-card"></i></li>
                        </ul>
                    </div>
                </div>
		<div className="card-block">
		    
            <div className="row">
		    
            <section className="col-md-6">
            <div className="form-group">
         <label> Fee Charge For <span style={{color:'red'}}>*</span></label><a href="/fees/fee_charge" className="pull-right" >Add more fee charge</a>
         <Select  options={feecharge&&feecharge.map((list, idx)=> {
                               return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                             })
                        } 
                        getOptionLabel={option =>`${option.session}  ${option.label}`}       
onChange={handleSelect} className={errors.feecharge ? 'form-control form-control-danger' : ''} name="feecharge" value={studentFee.feecharge}  /> <span style={{color:'red'}}>{errors.feecharge && errors.feecharge}</span>

            </div>
            </section>


            <section className="col-md-6">
            <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={studentFee.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
            </section>

            </div>




    {classFee.length!==0  ?     <div className="row">
    
    <div className="col-md-12 table-responsive"> 
    <label><b>Class Structure</b></label>

      <table className="table ">
        <tbody >

{ classFee.length!==0  ? classFee.map((fee, idx)=> {
                return   <tr key={idx}>
				<td>{fee.fees}<br/>
                
        <span style={{fontSize: '10px', color: '#fa0000'}}>Actual Fee {Currency+FormatNumber(getActualFee(fee.ID))}</span></td>

				<td>
                    <input type="number" min="0" className="form-control" onChange={(e)=>handleChangeFee(idx, e)}  name="feePrice[]" value={fee.price} />
                </td>

			</tr>
				}):
            <tr>
                <td colSpan="2">
					<div className="alert alert-danger background-danger">There are no fee structure define for the selected class <a href="/fees/class_fees">[Click here to Set Fee]</a></div>
					</td>
                </tr>
			
			}

<tr>
			<td colSpan="2">

			<textarea  name="remark" className="form-control" placeholder="Remark" value={studentFee.remark} onChange={handleChange} rows="2" >{studentFee.remark}</textarea>
			</td>
			</tr>

				</tbody>
              
		</table>
        </div>
        </div>:""}
        
        
	
<hr/>

	<footer className="pull-right">
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-inverse ">Save Fee Structure</button>
	</footer>				
            </div>
            </div>
            </div>
</div>


<div className="row">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i>  All Student Fees Schedule</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <TableClassFees data={structure}  columns={tableClassFeesHeader} />}

	</div></div>

</div>
</div> 
</div> 

{students.length!==0?
<div className="row">
<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-th" ></i>  Student Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
	<div className="card-block">
    <div className="col-md-12 table-responsive">
      
       
    {notice.isDataFetching ? <TableLoader />:
        <TableStudent data={students}  columns={tableStudentHeader} />}

	</div></div>

</div>
</div> 
</div> :''}
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ClassFees) 