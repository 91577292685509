import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const classID = Cookies.get('kasclkysd')?Cookies.get('kasclkysd'):'null'
export const sectionID = Cookies.get('kassckayd')?Cookies.get('kassckayd'):'null'
export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

export const staffCode= '';
export const staffName ='';


export const reset_password = Cookies.get("katkrspw") ? Cookies.get("katkrspw") : null;


export const familyCode= Cookies.get('kapkauscd')?Cookies.get('kapkauscd'):'null'
export const familyName = Cookies.get('kapkstnm')?CryptoJS.AES.decrypt(Cookies.get('kapkstnm'), 'kayus').toString(CryptoJS.enc.Utf8):null
export const Token = Cookies.get('kapkamtk')?Cookies.get('kapkamtk'):null
export const PortalType = Cookies.get('kaportal')?Cookies.get('kaportal'):null;

export const userCode = Cookies.get('kapkauscd')?Cookies.get('kapkauscd'):'null'



export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'

//export  const PSKLIVE ='pk_test_d41f0e6058222200abe204e6ffd010ccf9c122ff';
export  const PSKLIVE = process.env.REACT_APP_PSKLIVE
export  const LocalUrl = 'localhost';
export const schoolName = 'ADUVIE INTERNATIONAL SCHOOL'
export  const KAYUS_URL = 'kayus.org';

export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://form."+mainUrl
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'

export  const API_IMAGES = "https://"+mainUrl+'/public/images'
export  const API_URL = "https://"+mainUrl+'/api'
export const APP_KEY =process.env.REACT_APP_APP_KEY;