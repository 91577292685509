import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Alerts } from '../component/notify'

import {API_URL, ServerUrl, Token, config, sectionID} from '../component/include'
import axios from 'axios'
import { getDays, longDate, getTime} from '../component/globalFunction'


import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'


const Dashboard =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [CurrentSession, setCurrentSession] = useState('')
    const [allHolidays, setAllHolidays]= useState([])    
   
   

const [attendance, setAttendance] = useState([])
const [attendanceAbsent, setAttendanceAbsent] = useState([])
var today = new Date().toISOString().slice(0,10);

const [schoolClass, setSchoolClass]=useState([]);

const [statistics, setStatistics] = useState({
    student:0,
    absent:0,
    present:0,
    male:0,
    female:0
});


const [allHoliday, setAllHoliday]= useState([])
    
const fetchAllHoliday =()=>{

  const fd = new FormData();
    let sql = "Select startDate as date, eventType as event, color as colors, title as comment,  startTime as begin, endTime as stop from tbl_events_holidays "

      
        let url = API_URL+'/exam/result/fetch_raw' 
        fd.append("sql", sql)
       
        axios.post(url, fd, config).then(result =>setAllHoliday(result.data.data)) 
}



  function renderEventContent(eventInfo){ 
    var data = eventInfo.event.extendedProps 
    return  <div style={{backgroundColor:data.colors}} className="attachment-block text-center">  
    <b className="text-green">{data.event}</b><br/>
    <b className="text-green" style={{display:'block',  width:150, whiteSpace:'normal', wordWrap:'break-word' }}>{data.comment}</b>
<br/>
<strong className="text-green">{getTime(data.begin)+ ' ~ '+getTime(data.stop)} </strong>
<br/>
    
      
        
        <br/>
    </div>
}




    const fetchAllHolidays =()=>{
      
        const fd = new FormData();



        let sql = "Select eventType, title, startDate, endDate, ID from tbl_events_holidays where sessionYear = '"+CurrentSession+"'  order by ID ASC"
     
        let url = API_URL+'/exam/result/fetch_raw' 
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>setAllHolidays(result.data.data)) 
    }

    const tableHeader = [
        {dataField: 'eventType', text: 'Title', editable:false},
        {dataField: 'title', text: 'Reason', sort:true, editable:false},
        {dataField: 'startDate', text: 'Date',sort:true, editable:false, formatter: (cell, row)=>`${longDate(cell)} `},       
        {dataField: 'endDate', text: 'Day', editable:false, sort:true, formatter: (cell, row)=>getDays(row.startDate)}
     ];


     const HolidayTable=(props)=>{

       
  
       
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                    
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    





      const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        let url = API_URL+'/exam/result/fetch_raw' 
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.data.length!==0){
            setCurrentSession(result.data.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }
    

   
      



      const fetchAttendance =()=>{
        var date = new Date()
        const day = 1000 * 60 * 60 * 24
        var answer = []
        for(var i =0; i<7; i++){
            
            answer.push(date.toISOString().slice(0,10))
            date.setTime(date.getTime()-day)
        }

    
var sql = "select attendanceDate as label, count(*) as data from tbl_student_attendance where attendanceType ='fullday' and indicator ='P' and sectionID = '"+sectionID+"' and attendanceDate IN("+answer.map(data=>'"'+data+'"')+")  GROUP BY attendanceDate order by label  "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
            
             setAttendance(result.data)
         })
     }

     const fetchAttendanceAbsent =()=>{
        var date = new Date()
        const day = 1000 * 60 * 60 * 24
        var answer = []
        for(var i =0; i<7; i++){
            
            answer.push(date.toISOString().slice(0,10))
            date.setTime(date.getTime()-day)
        }

    
var sql = "select attendanceDate as label, count(*) as data from tbl_student_attendance where attendanceType ='fullday' and indicator ='A' and sectionID = '"+sectionID+"' and attendanceDate IN("+answer.map(data=>'"'+data+'"')+")  GROUP BY attendanceDate order by label  "

         const fd = new FormData();
         fd.append("sql", sql);
         fd.append('jwt', Token)
         let url = ServerUrl+'/fetchBySql_controller';
         axios.post(url, fd, config).then(result=>{ 
            
             setAttendanceAbsent(result.data)
         })
     }





     const fetchStatistics =()=>{
        var sql ="Select (select count(*) from tbl_students where admissionStatus='Studying' and  sectionID = '"+sectionID+"' ) as student, (select count(*) from tbl_student_attendance where attendanceType ='fullday' and indicator ='P' and sectionID = '"+sectionID+"' and attendanceDate = '"+today+"') as present,  (select count(*) from tbl_student_attendance where attendanceType ='fullday' and indicator ='A' and sectionID = '"+sectionID+"' and attendanceDate = '"+today+"') as absent, (select count(*) from tbl_students where admissionStatus='Studying' and gender='Male' and  sectionID = '"+sectionID+"' ) as male, (select count(*) from tbl_students where admissionStatus='Studying' and gender='Female' and  sectionID = '"+sectionID+"' ) as female "
          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        
        axios.post(url, fd, config).then(result=>{ 
            
            setStatistics({
                student:result.data[0].student,
                present:result.data[0].present,
                absent:result.data[0].absent,
                male :result.data[0].male,
                female:result.data[0].female
            })
        })
    
    }
        
       
        


          


           var className =  '';//getClassName(classID, sectionID, schoolClass);
           var cName = "Dashboard -"+className



           const data = {
            labels: attendance.map((data)=>data.label),
            datasets: [
              {
                label: 'Present',
                data: attendance.map((data)=>data.data),
                fill: false,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                yAxisID: 'y-axis-1',
              },
              {
                label: 'Absent',
                data: attendanceAbsent.map((data)=>data.data),
                fill: false,
                backgroundColor: 'rgb(54, 162, 235)',
                borderColor: 'rgb(255, 99, 132)',
                yAxisID: 'y-axis-2',
              },
            ],
          };
          
         

          const options = {
            scales: {
              yAxes: [
                {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  id: 'y-axis-1',
                },
                {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  id: 'y-axis-2',
                  gridLines: {
                    drawOnArea: false,
                  },
                },
              ],
            },
          }
          
         
          



        
   useEffect(()=>{
    fetchAllHoliday()
    handleSession()
        fetchAllHolidays();
},[]);

        return (  
            <React.Fragment>
    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Dashboard'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Dashboard</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



      <div className="row">

<div className='col-sm-2'>

<button className="btn " onClick={()=>window.open('/students/view_students', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-graduation-cap fa-2x"></i> 
    <br/>Students </button>
</div>

<div className='col-sm-2'>

<button className="btn " onClick={()=>window.open('/conference', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-book fa-2x"></i> 
    <br/>Conference </button>
</div>

<div className='col-sm-2'>
<div className="label-icon">
<button className="btn btn-success" onClick={()=>window.open('/fees/fees_payment', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-money fa-2x"></i> 
    <br/>Fees </button>
</div>

</div>


<div className='col-sm-3'>

<button className="btn btn-success" onClick={()=>window.open('/attendance/display_student_attendance', '_self')} style={{height:'100%', width:'100%'}} ><i className="fa fa-calendar fa-2x"></i> 
    <br/>Attendance </button>
</div>

<div className='col-sm-3'>

<button className="btn btn-app" onClick={()=>window.open('/noticeboard', '_self')} style={{height:'100%', width:'100%'}} ><i className="feather icon-bell fa-2x"></i> 
    <br/>Noticeboard </button>
</div>



</div><br/>


       



    <div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <FullCalendar
             events={allHoliday}          
             eventContent={renderEventContent}
            headerToolbar = { {
            left: "prev, today",
            center: "title",
            right: "next"
          }}
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
             </div>
          </div>
        </div>
      </div>




<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Event and Holidays</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <HolidayTable data={allHolidays}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div> 



   </div>
          {/* The Page Body close here */}

            </div>
            </div>
</React.Fragment>
 );
        
}

export default React.memo(Dashboard) 