import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, ImagesUrl, config, familyCode, ParentUrl, PSKLIVE, Currency, FormatNumber, API_URL} from '../component/include'
import { globalId, removeglobalId } from '../component/globalFunction'

import { usePaystackPayment } from 'react-paystack';
import CartItem from './cartItem'
import Confirmation from './confirmation'
const Checkout =()=> {
    

    
const [notice, setNotice] = useState({
    isLoading : false,
    isDataFetching: false
});

const [isSubmitting, setIsSubmitting] = useState(false); 
const [activeStep, setActiveStep] = useState(1);
const [paymentGateway, setPaymentGateway] = useState([]);
const [subtotal, setSubtotal] = useState([]);
const [shippingMethod, setShippingMethod] = useState([]);
const [tax, setTax] = useState(0);
const [errors, setErrors] = useState({});
const [family, setFamily] = useState({
    address:'',
code:'',
email:'',
familyName:'',
latitude:'',
longitude:'',
othername:'',
telephone:'',
update_profile: "true"
    
});

console.log(family)
const [pickupAddress, setPickupAddress] = useState([]);
const [order, setOrder] = useState([]);
const [cart, setCart] = useState([]);
const [content, setContent] = useState({
  code:'c'+Math.random().toString(36).substr(2,9),
  customerDetails:[
    {
      fullname: "",
      email: "",
      company: "",
      city: "",
      address: "",
      telephone: ""
    }
  ],
  shippingAddress:[
    {
      ID:'1',
      fullname: "",
      email: "",
      company: "",
      address: "",
      telephone: ""
    }
  ],
  shippingType:'',
  pickupID:'',
  customerId:familyCode,
  shippingMethod:'',
  handlingFee:0,
  shippingFee:0,
  isPickupAddress:false,
 isShippingAddress:true,
  profileComplete:false,
  isReview:false,
  paymentOption:'',
  tax:0,
  discount:0,
  orderTotal:0,
  orderId:globalId()
})

const [orderId, setOrderId] = useState(globalId());


const fetchCartItem =()=>{
   
    

    var sql ="Select o.ID, p.productName, p.coverUrl, o.files, o.quantityPrice, o.productCode, p.isPrinting, p.bulkPrices, o.quantity, o.total, o.attributes, p.price, o.totalAttributePrice, o.graphicFee, o.shippingFee, o.displayOrder from tbl_product_orders o, tbl_products p where o.productCode = p.code and o.orderId = '"+ orderId+"' order by o.ID DESC" ;
        
    const fd = new FormData();
    fd.append("sql", sql);
    
    let url = API_URL+'/exam/result/fetch_raw'
    
    axios.post(url, fd, config).then(result=>{ 
      if(result.data.data.length===0){
        //window.open('/tuckshop/products', '_self')
      }else{
      var total = 0 
      result.data.data.map(item=>total+=Number(item.total))
      setCart(result.data.data)
      setSubtotal(total)
      }
    })

}




const fetchOrder =()=>{
  let orderId = globalId()
  var sql ="Select * from tbl_orders where orderId = '"+ orderId+"'" ;
  const fd = new FormData();
  fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
  axios.post(url, fd, config).then(result=>{ 
    if(result.data.data.length===0){
      //window.open('/tuckshop/products', '_self')
    }else{
     setOrder(result.data.data[0])
    }
  })

}



const handlePaystackFees =()=>{
	var amount = 0
	var amountToCharge = (0.015 * Number(content.orderTotal))
if(Number(content.orderTotal) <2500){

	amount =  (Number(content.orderTotal)+ (amountToCharge)) * 100

}else if(amountToCharge>2000){
		amount = (Number(content.orderTotal)+ (2000)) * 100
	}else{
		
	amount =  (Number(content.orderTotal)+ (amountToCharge+100)) * 100
	}
	return amount;
}


var amountTopay = handlePaystackFees()
var txRef = (new Date()).getTime().toString();

const paystackConfig = {
	reference: txRef,
	email: family.email,
	amount: amountTopay,
	publicKey: PSKLIVE 
	};

    const onSuccess = (ref) => {
        handleSubmitPayment('Paystack', 'Successful', content.orderTotal)
		handlePaymentReport(ref)

	};
	
	const onClose = () => {
	// implementation for  whatever you want to do when the Paystack dialog closed.
	//console.log('closed')
	}

    

const handlePaystackPayment = usePaystackPayment(paystackConfig);
/* 

const flutterConfig = {
public_key: 'FLWPUBK-1f9d8e7c1f161bcdb997da08d6916016-X',
tx_ref: globalId(),
amount: Number(content.orderTotal),
currency: "NGN",
country: "NG",
payment_options: 'card,mobilemoney,ussd',
customer: {
  email: content.customerDetails[0].email,
  phonenumber: content.customerDetails[0].telephone,
  name: content.customerDetails[0].fullname,
},
customizations: {
  title: 'Piece To Master Piece',
  description: 'Order Payment',
  logo: ImagesUrl+'/logo.png',
},
}; */

//const handleFlutterPayment = useFlutterwave(flutterConfig);



const handlePaymentReport=(result)=>{
			
    const fd = new FormData();
    fd.append('jwt', Token);
    
    fd.append('amount', order.orderTotal);
    fd.append('currency', 'NGN');
    fd.append('flw_ref', JSON.stringify(result, null, 2));
    fd.append('transaction_id ', result.trans);
    fd.append('status', result.status);
    fd.append('tx_ref', txRef);
    
    fd.append('remark', 'Tuckshop Purchase');
    fd.append('customers', JSON.stringify(family, null,2));
    fd.append('code', Math.random().toString(36).substring(2,9));
         let url = ServerUrl+'/save_controller/tbl_online_payment_report'
        axios.post(url, fd, config)
        .then(response =>{
             if (response.data.type ==='success') {
                //Alerts('Saved!', 'success', response.data.message)
            }  else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }   
                
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
}


const fetchPaymentGateway =()=>{
var sql ="Select ID, title, details from tbl_payment_gateway where status ='true'" ;
const fd = new FormData();
fd.append("sql", sql);

    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{  
  setPaymentGateway(result.data.data)
})

}

function getLocationPrice(){
var km = 2;
var kmPrice = 100;
return km*kmPrice;

}
const fetchAddress =()=>{
var sql ="Select pickupAddress, tax from tbl_tuckshop_settings" ;
const fd = new FormData();
fd.append("sql", sql);

    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{ 
  if (result.data.data.length!==0){
    let answer =result.data.data[0].pickupAddress
    let address = answer.length!==0?JSON.parse(answer):[]
    setTax(answer.length!==0?result.data.data[0].tax:0)
     setPickupAddress(address)
  }
})

}


const fetchShippingMethod =()=>{
var sql ="Select ID, code, methodName, location, handlingFee, deliveryPeriod  from tbl_shipping_method where isActive='true' order by ID ASC";
 
const fd = new FormData();
fd.append("sql", sql);

    let url = API_URL+'/exam/result/fetch_raw'

axios.post(url, fd, config).then(result=>{ 
    setShippingMethod(result.data.data)
})

}




const handleChangeShippingType =(value)=>{

  if(value === 'Deliver to me'){
  //Reset fee
  var shipping = Number(getLocationPrice()) + Number(content.handlingFee)

  //var total = ( Number(subtotal) + Number(shipping)+ Number(getTaxAmount())) - Number(order.promoAmount)

  let address = {
    
      ID:'1',
      fullname: family.familyName+ ' '+ family.othername,
      email: family.email,
      company: "",
      address: family.address,
      telephone: family.telephone
    
  }
  var total = ( Number(subtotal) + Number(shipping)+ Number(getTaxAmount())) 
  setContent({...content, isShippingAddress:true, isPickupAddress:false, shippingAddress:[address], pickupID:'', shippingType:value, shippingFee:Number(shipping), tax:getTaxAmount(), orderTotal:total});

  }else{
    setContent({...content, shippingType:value});
  }
  setErrors({...errors, shippingType:''});
}





const getTaxAmount=()=>{
var percent  =  Number(tax)/100
var amount = percent * Number(subtotal)
return amount
}



const fetchHandling =(code)=>{
const result = shippingMethod.filter(list=>list.code===code);
const answer = result.length!==0?result.map(ans=>ans.handlingFee):0
return Number(answer)
}

const handleShippingMethod=(event)=>{
const {name, value} = event.target;
var shipping = Number(content.shippingFee) + Number(fetchHandling(value)) - Number(content.handlingFee)
//var total = Number(subtotal) + Number(getTaxAmount()) + Number(shipping) - Number(order.promoAmount)
var total = Number(subtotal) + Number(getTaxAmount()) + Number(shipping)

setContent({...content, shippingMethod:value, handlingFee:fetchHandling(value), tax:getTaxAmount(), shippingFee:shipping,  orderTotal:total});
}



    
const handleOnClickStepper = (step) => {
if(step!==4 && !isSubmitting){
    handleValidateName(step)
    handleSubmitOrder()
}
}



const handleChangeFamily =(event)=>{
    let {name, value} = event.target;

    if(name==='update_profile'){
        value=value==='true'?'false':'true'
    }
    setFamily({...family, [name] : value });

    console.log(family)
   setErrors({...errors, [name]:''});
} 




const handleValidateFinal = (option, status, amount)=>{
    
  let errors = {};  
/*   if(content.shippingType===''  ){
    errors.pickupAddress ='Please select delivery method';
    Alerts('Error!', 'danger', errors.pickupAddress)
  }
 
  if(content.shippingType==='Self Pickup' && content.pickupID==='' ){
    errors.pickupAddress ='Please select pickup address';
    Alerts('Error!', 'danger', errors.pickupAddress)
  }

 if(content.shippingType==='Deliver to me' && !content.shippingMethod){
      errors.pickupAddress ='Please select shipping method';
      Alerts('Error!', 'danger', errors.pickupAddress)
    } */

  setErrors(errors)

 
  if(Object.keys(errors).length === 0){
    if(option==='online'){
      handlePaystackPayment(onSuccess, onClose)
    }else{

      handleSubmitPayment(option, status, amount)
    }
     
      }else{
          setActiveStep(3); 
      }

} 


const handleValidateName = (step)=>{
    
    let errors = {};  
    let contact = JSON.parse(JSON.stringify(family))
    let email = contact.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    

    if(!contact.familyName){
        errors.familyName ='This field is required';
    } 

   /*  if(!contact.othername){
        errors.othername ='This field is required';
    }  */

    /* if(!contact.telephone){
        errors.telephone ='This field is required';
    } */

    if(!email){
        errors.email ='Please enter valid email address';
    }



    /* if(!contact.address){
        errors.address ='This field is required';
    } */


    setErrors(errors)

    if(Object.keys(errors).length === 0){
      

        setActiveStep(step); 
       
        }else{
            setActiveStep(2); 
        }

        handleChangePickup({address:'Aduvie School', city:'FCT Abuja', country:'Nigeria', ID:'1', deliveryFee:0})
} 

const handleFinish =()=>{
  window.open('tuckshop/myorder', '_self')
}

const handleOnClickNext = useCallback(() => {
  setActiveStep(activeStep + 1);
}, [activeStep])


/* const handleOnClickNext = useCallback(() => {

    if(activeStep===2){
        handleValidateName(activeStep + 1)
    }else{
        setActiveStep(activeStep + 1);
    }

}, [activeStep]) */

const handleOnClickBack = useCallback(() => {
setActiveStep(activeStep - 1);
}, [activeStep])





const ValidateInputProfile =()=>{
let errors = {};   

if(familyCode!=='null'){

if(content.shippingType==='Self Pickup' && content.pickupID==='' ){
  errors.pickupAddress ='Please select pickup address';
  Alerts('Error!', 'danger', errors.pickupAddress)
}

if(content.shippingType==='Deliver to me' && !content.customerDetails[0].address){
  errors.pickupAddress ='Please update your address';
  Alerts('Error!', 'danger', errors.pickupAddress)
  window.scrollTo(0,0)
}

} else{
let email = content.customerDetails[0].email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 

if(!content.customerDetails[0].fullname){
  errors.fullname ='Customer fullname is required';
  window.scrollTo(0,0)
}
if(!email){
  errors.email ='Please enter valid email address';
  window.scrollTo(0,0)
}

if(!content.customerDetails[0].telephone){
    errors.telephone ='Please enter your telephone';
    window.scrollTo(0,0)
}

if(content.shippingType==='Self Pickup' && content.pickupID==='' ){
  errors.pickupAddress ='Please select pickup address';
  Alerts('Error!', 'danger', errors.pickupAddress)
}

if(content.shippingType==='Deliver to me' && !content.customerDetails[0].address){
  errors.address ='Please update your address';
  
}

}
return errors;
} 







const handleSubmitOrder =()=>{

  let formData = content;
  const fd = new FormData();
  for(let k in formData){
    fd.append(k, formData[k])
  }
  
  fd.append('jwt', Token)
  fd.append("customerDetail", JSON.stringify(family, null, 2))
  fd.append("shippingAddresses", JSON.stringify(content.shippingAddress, null, 2))
  fd.append("subtotal", subtotal)
  fd.append("promoAmount", '0')
  
  let url = ServerUrl+'/update_controller.php?tablename=tbl_update_orders';
 
axios.post(url, fd, config)
.then(response =>{
 if(response.data.type ==='success'){
  //  Alerts('Saved!', 'success', 'order successfully updated')
        } else{
    Alerts('Error!', 'danger', JSON.stringify(response.data, null, 2))
        }   
})
.catch((error)=>{
  Alerts('Error!', 'danger', error.message)
})
}



const handleSubmitPayment =(paymentOption, status, paid)=>{
setNotice({...notice, 
isLoading: true
})
const fd = new FormData();


fd.append("email", family.email)
fd.append("customerName", family.familyName+' '+family.othername)

fd.append('jwt', Token)
fd.append("billingAddress", family.address)
fd.append("shippingAddress", content.shippingAddress[0].address)
fd.append("shippingMethod", content.shippingType+' '+content.shippingMethod)

fd.append("products", ParentUrl+'/order/tracking/'+content.orderId)


fd.append("paymentMethod", paymentOption)
fd.append("amountTopay", content.orderTotal)
fd.append("amountPaid", paid)
fd.append("paymentStatus", status)
fd.append("customerCode", familyCode)
fd.append("orderId", content.orderId)
    
let url = ServerUrl+'/save_controller.php?tablename=tbl_payment_history_market';

axios.post(url, fd, config)
.then(response =>{
if(response.data.type ==='success'){

    setErrors({...errors, successMessage: 'order successfully recorded '})
    
  Alerts('Saved!', 'success', 'order successfully recorded')

  //move to step 5 confirmation
  
  setActiveStep(5);
  //remove global id
  removeglobalId()

  //set submitted to true
  setIsSubmitting(true)
      } else{

        setErrors({...errors, errorMessage: JSON.stringify(response.data)})
      }   
})
.catch((error)=>{
    setErrors({...errors, errorMessage: error.message})
}).finally(()=>{
  setNotice({...notice, 
      isLoading: false
  })
}) 
}


const handleUpdateCart =()=>{

    setNotice({...notice, 
        isLoading: true})  
const fd = new FormData();
fd.append('jwt', Token)
    fd.append('data', JSON.stringify(content))
let url = ServerUrl+'/update_controller.php?tablename=tbl_update_product_orders';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
          Alerts('Saved!', 'success', response.data.message)
          
              } else{
          Alerts('Error!', 'danger', response.data)
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          setNotice({...notice, 
              isLoading: false
          })
          //fetchContent()
      }) 
}

const handleChangePayment=()=>{

}



const fetchFamily =()=>{
       
    var sql ="Select familyName, code, othername, email, telephone, address, latitude, longitude from tbl_family where code = '"+familyCode+"'";
    
    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){

            setFamily({
                address:result.data.data[0].address,
            email:result.data.data[0].address,
            familyName:result.data.data[0].familyName,
            latitude:result.data.data[0].latitude,
            longitude:result.data.data[0].longitude,
            othername:result.data.data[0].othername,
            telephone:result.data.data[0].telephone,
            update_profile: "true"
                
            
            })
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })

}

const handleChangePickup = (data)=>{

  const shippingAddress = content.shippingAddress
  shippingAddress[0].address = data.address +' '+data.city +' '+data.country
  shippingAddress[0].telephone = family.telephone
  shippingAddress[0].fullname = family.familyName+' '+family.othername
  var shipping = Number(data.deliveryFee) + Number(content.handlingFee)
  
  //var total = (Number(subtotal) + Number(getTaxAmount()) + Number(shipping)) - Number(order.promoAmount)

  var total = (Number(subtotal) + Number(getTaxAmount()) + Number(shipping))

  setContent({...content, isShippingAddress:false, isPickupAddress:true, shippingAddress: shippingAddress, pickupID:data.ID, shippingFee:Number(shipping), tax:getTaxAmount(), orderTotal:total});
    setErrors({...errors, pickupAddress:''});
}

useEffect(()=>{
    fetchFamily()
    fetchCartItem()



     fetchAddress()
     fetchOrder()
    fetchShippingMethod()
    fetchPaymentGateway() 
   }, []);



   const steps = [ {title: 'Cart Items'},{title: 'Basic Details'}, {title: 'Payment Details'}, {title: 'Confirmation'}]


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Tuckshop">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bar-chart"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Tuckshop</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Checkout Order</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
      {errors.errorMessage?
<div className="alert alert-danger background-danger">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.errorMessage}</strong> 
  </div>:''}


  {errors.successMessage?
<div className="alert alert-success background-success">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.successMessage}</strong> 
  </div>:''}
                
          {cart&&cart.length!==0?     
                <div className="row">

<div className="col-sm-12">

<div className="card">
<div className="card-block">
<div className="row">
<div className="col-md-12">


<div className="wizard-form wizard ">
             <div className="steps clearfix">
    
    <ul role="tablist">

    {steps.map((item, idx)=>{

return  <li key={idx} onClick={()=>handleOnClickStepper(idx+1)} className={activeStep===(idx+1)?'current':'done'} ><a  href="#!"><span className="number">{idx+1}.</span>  {item.title} </a></li>
})}

        </ul>
        
        </div>
        </div>



<div id="wizard">
<section>
<div className="wizard-form wizard clearfix" >



<div className="content clearfix">



{activeStep===1?
<fieldset id="basic-forms-p-0" className='p-10'>
<h3 className="title current"> Your Shopping Cart </h3>
<hr/>




<CartItem />



</fieldset>:
activeStep===2?


<fieldset className="bank-detail p-10 "  >
    
<h3 id="basic-forms-h-1"  className="title"> Basic Details </h3>
<hr/>
<div className="row justify-content-center">
<div className="col-md-8">
<div className="row">
<div className="col-sm-12">


<form>

<div className="form-row">
       <div className="col form-group">
           <label>First Name</label>

           <input type="text" onChange={handleChangeFamily} role='presentation' autoComplete='off' name="familyName" value={family.familyName} className={errors.familyName  ? 'form-control form-control-lg form-control-danger' : 'form-control form-control-lg'}/>
   <span className=" form-bar error">{ errors.familyName} </span>
       </div> 


     {/*   <div className="col form-group">
           <label>Othername</label>
          
           <input type="text" role='presentation' autoComplete='off' onChange={handleChangeFamily}  name="othername" value={family.othername} className={errors.othername  ? 'form-control form-control-lg form-control-danger' : 'form-control form-control-lg'}/>

       </div>  */}
       <div className="col form-group">
      <label>Telephone</label>
        <input type="text" role='presentation' autoComplete='off' className={errors.telephone  ? 'form-control form-control-lg form-control-danger' : 'form-control form-control-lg'} value={family.telephone} name="telephone"  onChange={handleChangeFamily}   />
      </div> 

   </div>  
   

      <div className="form-row">
      <div className="col form-group">
       <label>Email</label>
       <input type="email" role='presentation' autoComplete='off' onChange={handleChangeFamily}  name="email" value={family.email} className={errors.email  ? 'form-control form-control-lg form-control-danger' : 'form-control form-control-lg'}/>

       <i>You need a valid email address to place an order</i>
       </div> 

       
  </div>


 {/*  <div className="form-row">
       <div className="form-group col-md-12">
         <label>Address</label>
         <input className={errors.address  ? 'form-control form-control-lg form-control-danger' : 'form-control form-control-lg'}  role='presentation' autoComplete='off' type="text" id="address" name="address" value={family.address} onChange={handleChangeFamily} />
       </div> 
       
       <span className=" form-bar error">{ errors.address} </span>
       </div> */}

       <div className="form-row">

       <div className="checkbox-color checkbox-primary">
<input id="checkbox18" type="checkbox" name='update_profile' value={family.update_profile} checked={family.update_profile==='true'?true:false}  onChange={handleChangeFamily} />
<label htmlFor="checkbox18" >
Update my profile
</label>
</div>
</div>


 </form>



  </div> 
</div>

</div></div>
</fieldset>
:
activeStep===3?

/* 
<fieldset className=" p-10"  >
    
<h3 id="basic-forms-h-2"  className="title"> Delivery Method </h3>
<hr/>




<div className="card mb-4">
    <div className="card-body">
    <h4 className="card-title mb-4">Delivery Mode</h4>
	<form action="" >
		
        <div className="form-radio">
            <div className="row">

            <div className={`p-10 form-group col-sm-5 m-l-20 m-r-20 ${content.shippingType ==='Self Pickup'?`border-success`:''} `}  onClick={()=>handleChangeShippingType('Self Pickup')} >
<div className="radio radiofill radio-inline">
<label style={{cursor:'pointer'}}>
<input type="radio"  name="delivery" value="Self Pickup" checked={content.shippingType ==='Self Pickup'?true:false} />
<i className="helper "></i><span className='bold f-16'>Self Pickup</span>

<p className="text-muted">You will come to our school to get it</p>
</label>
</div>
</div>


<div className={`p-10 form-group col-sm-5 ${content.shippingType ==='Deliver to me'?`border-success`:''} `}  onClick={()=>handleChangeShippingType('Deliver to me')} >
<div className="radio radiofill radio-inline" >
<label style={{cursor:'pointer'}}>
<input type="radio"  name="delivery" value="Deliver to me" checked={content.shippingType ==='Deliver to me'?true:false} />
<i className="helper"></i><span className='bold f-16'>Deliver to me</span>

<p className="text-muted">We will bring it to your door step </p>
</label>
</div>
</div>
</div>
</div>

	</form>

    </div> </div>




{content.shippingType ==='Deliver to me'?
<table className="table table-borderless table-shopping-cart">
<thead >
<tr className=" text-uppercase">

                  <th className="align-middle"></th>
                  <th className="align-middle">Shipping method</th>
                  <th className="align-middle">Delivery time</th>
                  <th className="align-middle">Handling fee</th>
                
</tr>
</thead>
<tbody>
{shippingMethod&&shippingMethod.map(item =>
                <tr key={item.code}>
                  <td>

                      <input  type="radio" id={item.code} name={item.methodName} checked={content.shippingMethod===item.code?true:false} onChange={handleShippingMethod} value={item.code} />
                

                  </td>

                  <td className="align-middle"><span className="text-dark font-weight-medium">{item.methodName}</span><br/><span className="text-muted">{item.location}</span></td>
                  <td className="align-middle">{item.deliveryPeriod}</td>
                  <td className="align-middle">{Currency+FormatNumber(item.handlingFee)}</td>
                </tr>)}

</tbody>
</table>:[]}


{content.shippingType ==='Self Pickup'?
<section className="container-fluid pt-grid-gutter">
   <h5>Click to Choose Pickup Location</h5>   
   <div className="row m-t-20">


{pickupAddress&&pickupAddress.map(data=>data.isActive===true? 
        <div className="col-xl-4 col-md-6 mb-grid-gutter " onClick={()=>handleChangePickup(data)} key={data.ID} style={{cursor:'pointer'}} >

          <div className="card " style={{  borderWidth:'2px'}}>
         
            <div className={`card-body text-center ${content.pickupID===data.ID?'border-success':''}`}><i className="fa fa-location h3 mt-2 mb-4 text-primary"></i>
            <h3 className="h6 mb-3"> {data.country}</h3>
<p className="font-size-sm text-muted">{data.address} -{data.city}  </p>
<ul className="list-unstyled font-size-sm mb-0">
                <li><span className="text-muted mr-1">Contact Person:</span><a className="nav-link-style" href={`tel:${data.telephone}`}>{data.contactName}</a></li>
                <li className="mb-0"><span className="text-muted mr-1">Telephone:</span><a className="nav-link-style" href={`tel:${data.telephone}`}>{data.telephone}</a></li>
              </ul>

            </div>

          </div></div>:''
)}

      </div>
    </section>:[]


}
</fieldset> */



<div className='p-10'>
<h3 id="basic-forms-h-2"  className="title"> Payment Details </h3>
<hr/>


<div className='row'>
<aside className="col-md-7">
  <div className="card mb-4 z-depth-0">
      <div className="card-body">
      
      <article className="accordion" id="accordion_pay">

	


	<div className="card p-10 pointer">
	<div className="card-header">
		<img src={ImagesUrl+"/payments.png"} className="float-right" height="24" />  
		<label className="form-check collapsed" data-toggle="collapse" data-target="#pay_payme" aria-expanded="true">
			<input className="form-check-input" name="payment-option" type="radio" onChange={handleChangePayment} value="option2" />
			<h5 className="form-check-label bold"> Credit Card  </h5>
		</label>
	</div>
	<div id="pay_payme" className="collapse show" data-parent="#accordion_pay" >
		<div className="card-body">
			<p className="alert alert-success">Online payment is more easier and faster. Click on the below button to make payment.</p>
			<div className="card-body">

                  <button className="btn btn-outline-secondary btn-block mt-0" onClick={()=>handleValidateFinal('online', 'Pending', '0')} type="button"   >CLICK TO PAY ONLINE</button>
                </div>
		</div> 
	</div> 
	</div> 

  <div className="card p-10 pointer">
		<div className="card-header">
			<label className="form-check collapsed" data-toggle="collapse" data-target="#pay_paynet" aria-expanded="false">
				<input className="form-check-input" name="payment-option" checked="" onChange={handleChangePayment} type="radio" value="option3" />
				<h5 className="form-check-label bold"> 
					Pay on Arrival 
				</h5>
			</label>
		</div>
		<div id="pay_paynet" className="collapse" data-parent="#accordion_pay" >
    <div className="card-body ">
                  <p><span className="font-weight-medium"></span> Pay when you receive your item ?</p>
               
                  <a  className="btn btn-outline-secondary btn-block" href="#!" onClick={()=>handleValidateFinal('Pay On Arrival', 'Pending', '0')}> Pay On Arrival </a>
                </div>
		</div> 
	</div> 


	<div className="card p-10 pointer">
		<header className="card-header"> 
			<label className="form-check" data-toggle="collapse" data-target="#pay_card" aria-expanded="false">
				<input className="form-check-input" name="payment-option" type="radio" value="option1" onChange={handleChangePayment} />
				<h6 className="form-check-label"> Bank Transfer </h6>
			</label>
		</header>
		<div id="pay_card" className="collapse " data-parent="#accordion_pay" >
			<div className="card-body">
				<div className="card-body ">
                  <p><span className="font-weight-medium">Your delivery day will start counting when we confirm your payment. Kindly pay into the following account details: </span> </p>


                  {paymentGateway&&paymentGateway.filter(item=>item.title ==='Bank').map(data=><div key={data.ID}>
                  <div dangerouslySetInnerHTML={{__html: data.details}} ></div>
                  <a className="btn btn-secondary btn-block" href="#!" onClick={()=>handleValidateFinal('Bank Payment', 'Pending', '0')} > I CHOOSE BANK PAYMENT </a></div>)}
                </div>
			</div> 
		</div> 
	</div> 
</article>

      </div> 
    </div> 
    </aside>
    
<aside className="col-md-5 ">

<div className="card z-depth-0">
    <div className="card-body">



            <dl className="dlist-align">
              <dt>Subtotal:</dt>
              <dd className="text-right h5"><strong>{Currency+FormatNumber(subtotal)}</strong></dd>
            </dl>

            <dl className="dlist-align">
              <dt>Delivery & Service Charge:</dt>
              <dd className="text-right">{Currency+FormatNumber(content.shippingFee)}</dd>
            </dl>

  <dl className="dlist-align">
              <dt>Discount:</dt>
              <dd className="text-right">{Currency+FormatNumber(0)}</dd>
            </dl>
            
            <dl className="dlist-align"> 
              <dt>Tax:</dt>
              <dd className="text-right">{Currency+FormatNumber(content.tax)}</dd>
            </dl>
            <hr/>

  <h3 className="font-weight-normal text-center my-4">{Currency+FormatNumber(content.orderTotal).slice(0, FormatNumber(content.orderTotal).length-2)}<small>{FormatNumber(content.orderTotal).slice(FormatNumber(content.orderTotal).length-2, FormatNumber(content.orderTotal).length)}</small></h3>

            <p className="text-center mb-3">
                <img src={ImagesUrl+"/payments.png"} height="26" />
            </p>
            
    </div> 
</div>  
</aside> 

    </div></div>:
    
<fieldset id="basic-forms-p-3" className='p-10' >
    <Confirmation orderId={orderId} />
    </fieldset>}




</div>


</div>
</section>


{/* 
<button onClick={activeStep === (steps.length-1) ? handleFinish : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-warning pull-right':'btn btn-success  btn-round pull-right'}  type="button" >{activeStep === (steps.length-1) ? <i className="icofont icofont-check-circled"> Finish</i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-success  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }  */}



</div>
</div>
</div>
</div>
</div>

</div>
</div>:
<div className="card ">
    <div className="card-body text-center">
	<h4 className=' m-b-20'>Hi! Your cart is currently empty.</h4>
	<a href="/tuckshop/products" className="btn btn-success m-l-5">Click Here To Add Item</a>
    </div>
</div> }





   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(Checkout) 