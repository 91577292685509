import React, { useState} from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = (props)=>{

let location =  useLocation()
const menu  = useSelector(state => state.rootReducer.sidebar);

const [isDisplay, setIsDisplay] = useState({ "0": true });
const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });
const handleToggleDisplaySubmenu=(main, sub)=>{
// handleToggleDisplay(main)
if(sub!=='0'){

setIsDisplay({[main]: true});
setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
}else{
setIsDisplay({[main]: !isDisplay[main]})
}
}


let activeHasmenuOpen ='pcoded-hasmenu pcoded-trigger active'


    return (  
      <nav className="pcoded-navbar no-print"  >
      <div className="pcoded-inner-navbar main-menu " >
          <div className="pcoded-navigatio-lavel" menu-title-theme="theme5" ></div>
          
            
          <ul className="pcoded-item pcoded-left-item no-print">
             {menu.length!==0?menu.map((data, index)=>data.isActive==='Yes'?
      <li  key={index} dropdown-icon={data.submenu.length!==0?"style2":''} subitem-icon={data.submenu.length!==0?"style2":''} 
      
      className={data.submenu.length ===0 && location.pathname===data.menuUrl?'active':
         
          isDisplay[data.ID] && props.menu===data.menuName && data.submenu.length!==0?activeHasmenuOpen:
          props.menu===data.menuName && data.submenu.length!==0?'pcoded-hasmenu active':
          isDisplay[data.ID] && data.submenu.length !==0 ?'pcoded-hasmenu pcoded-trigger':
      
      data.submenu.length !==0?'pcoded-hasmenu':  ''}
      
      >
                
{data.submenu.length!==0? <a href='#!' title={data.menuName} onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}>
<span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                      <span className="pcoded-mtext ">{data.menuName}</span>

</a>:

<Link to={data.menuUrl} > 
 <span className="pcoded-micon"><i className={data.menuIcon}></i></span>
                      <span className="pcoded-mtext ">{data.menuName}</span>
               
                  </Link>
                  
                  }
                  {data.submenu.length!==0?
                  <ul className="pcoded-submenu">
                {data.submenu.map((smenu, sid)=>smenu.isActive==='Yes'?
        <li key ={sid} 

  className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'active':
  
      isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName?activeHasmenuOpen: 
      
      isDisplay[data.ID] && isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0 && props.submenu===smenu.subName ?'pcoded-hasmenu pcoded-trigger active': 
      

      isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length !==0?'pcoded-hasmenu pcoded-trigger':
       smenu.subsubmenu.length !==0?'pcoded-hasmenu':
                      ''}  
                      dropdown-icon={smenu.subsubmenu.length!==0?"style3":''} subitem-icon={smenu.subsubmenu.length!==0?"style3":''}
                      
                      >
              

{smenu.subsubmenu.length!==0?    <a href='#!'
                      onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>

<span className="pcoded-micon "><i className="feather icon-menu"></i></span>
                      <span className="pcoded-mtext " >{smenu.subName}</span>

                      </a>  :    
<Link to={smenu.subUrl} >   
                      <span className="pcoded-micon"><i className="feather icon-menu"></i></span>
                      <span className="pcoded-mtext" >{smenu.subName}</span>
             
                  </Link>}
                  {smenu.subsubmenu.length!==0?
                  <ul className="pcoded-submenu">

                      {smenu.subsubmenu.map((subsub, k)=>subsub.isActive==='Yes'?
                              <li   className={location.pathname===subsub.subsubUrl?'active':'' } key={k}  >
                                
<Link to={subsub.subsubUrl} > 
                                      <span className="pcoded-mtext">{subsub.subsubName}</span>
                             
                                  </Link>
                              </li>:'')}

                          </ul>:''}
                      </li>:'')}
                     
                  </ul>:''}
              </li>:''):<div className='loadit'></div>}

              <li className="">
                  
              <a href="/logout">
                     
              <span className="pcoded-micon"><i className="feather icon-power"></i></span>
                                  <span className="pcoded-mtext">Log Out</span>
                  </a>
                          </li>

          </ul> 


      </div>
  </nav> );
}
export default React.memo(Navbar)