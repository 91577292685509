import React from 'react'
import {ImagesUrl} from '../component/include'
import Preloader from '../component/preloader'
import { useHistory } from 'react-router-dom'

 const Error404 =()=> {
	let history = useHistory()
        return (<>
          <Preloader />

		  <section style={{background: `url(${ImagesUrl}/bg.jpg) no-repeat`}} 
		  className="login-block">
      <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center error-pages">
                            <h1 className="error-title text-inverse"> Error 404</h1>
    
                            <p className="error-message text-white text-uppercase">Sorry, an error has occured, We could not find the page you were looking for!</p>
                            
                            <div className="mt-4">
                              <a href="/" className="btn btn-inverse btn-round shadow-inverse m-1">Go To Home </a>
                              <a href="#!" onClick={()=>history.goBack()} className="btn btn-outline-primary btn-round m-1">Previous Page </a>
                            </div>
    
                            <div className="mt-4">
                                <p className="text-light">Copyright © 2020  <span className="text-inverse">Admotek Solutions </span>| All rights reserved.</p>
                            </div>
                               <hr className="w-50" />
                           
                        </div>
                    </div>
                </div>
            </div>
    </section>



         
</> 

 );
        
}

export default React.memo(Error404) 