import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}




export const studentName = Cookies.get('stkstnm')?CryptoJS.AES.decrypt(Cookies.get('stkstnm'), 'kayus').toString(CryptoJS.enc.Utf8):'null'

export const classID = Cookies.get('sclkysd')?Cookies.get('sclkysd'):'null'
export const sectionID = Cookies.get('ssckayd')?Cookies.get('ssckayd'):'null'

export const familyCode = Cookies.get('fmcdk')?Cookies.get('fmcdk'):'null'
export const studentCode= Cookies.get('stkauscd')?Cookies.get('stkauscd'):'null'
export const Token = Cookies.get('stkamtk')?Cookies.get('stkamtk'):'null'


export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'

/* 
export  const ServerUrl = 'https://administrator.kayus.com.ng/controller';
export  const ImagesUrl = 'https://administrator.kayus.com.ng/images'; */

export  const ServerUrl = 'https://app.aduvieportal.org/controller';
export  const ImagesUrl = 'https://app.aduvieportal.org/images';  
export  const LocalUrl = 'portal.aduvieportal.org'; 
//export  const LocalUrl = 'localhost'; 

export const schoolName = 'ADUVIE INTERNATIONAL SCHOOL'

export  const PSKLIVE = 'pk_live_a678773fd32141f0c716254badad828122a503be';
 


