import React, {useState, useEffect, useRef} from 'react'
import {Alerts} from '../component/notify'
import axios from 'axios'
import {ServerUrl,  ImagesUrl, config,  userCode, Token} from '../../component/include'


 const ProfileCard =()=> {

	const [content, setContent] = useState([])

const fetchContent =()=>{

    var sql ="select t.applicationName, s.startYear,  r.applicationID,  r.passport, r.schoolSession, r.admissionStatus, r.studentName,  r.gender from tbl_admission_request r, tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.ID = a.schoolSession and t.ID = a.applicationType and r.applicationType = a.ID and r.applicationID ='"+userCode+"'";

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{ 
        
        if(result.data.length!==0){
        setContent(result.data[0])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    
}

useEffect(()=>{
    fetchContent()
},[]);

        return ( content.length!==0? <div className="col-xl-3 col-md-6">
                    <div className="card user-card">
                        <div className="card-block text-center">
                            <div className="usre-image">
                            <img id="viewPassport2" src={ImagesUrl+'/students/'+content.passport} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+content.gender+".png"}} className="img-radius" alt="User-Profile" />
                            </div>
                            <h6 className="m-t-25 m-b-10">{content.studentName}</h6>
                    <p className="text-muted">{content.applicationID}</p>
                            <a href="/overview" className="text-c-red d-block">{content.applicationName.toUpperCase()}</a>
                           
                        </div>
                    </div>
                </div>:''

 );
        
}

export default React.memo(ProfileCard) 