import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, familyCode, API_URL} from '../component/include'
import Select from 'react-select'
import { longDate} from '../component/globalFunction'
import { formatGroupLabel} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'

const ExamSchedule =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    const staff  = useSelector(state => state.rootReducer.staff);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);

    const [studentClass, setStudentClass]=useState([])
    const [mystudent, setMystudent]=useState([])
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [subject, setSubject]= useState([])


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[]
    })

  

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        }   
        if(values.examtype.length===0){
            errors.examtype ='Please select subject';
        } 
        return errors;
        }

        const filterStudentClass =()=>{
            let allClass = [...schoolClass]
            let fstudent =[...mystudent]
            let newClass = []
            
            for (var i in allClass){
            for(var k in fstudent){
                if(allClass[i].ID===fstudent[k].classID){
                    newClass.push(allClass[i])
                }    
            }
            }
            
            const fclass =	newClass.map(e=>e['ID'])
            .map((e,i,final)=>final.indexOf(e)===i&&i)
            .filter(e=>newClass[e])
            .map(e=>newClass[e])
            
            setStudentClass(fclass)
                }
    
        const fetchMyStudentClass =()=>{
            var sql ="Select id, code, admissionNumber, classID, sectionID  from tbl_students where family_name ='"+familyCode+"' order by sectionID ASC"; 
        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setMystudent(result.data)
        })
        }
        

        const fetchExamType =()=>{
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
        let ans = result.data.examType.filter(item=>item.publish ==='No')
                setExamtype(ans)
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }
       
      

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])

const handleSelect =(option, action)=>{     
          
            setResult({...result, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            filterStudentClass()
            setSubject('')
        }


        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            let url = API_URL+'/exam/display_result/'+result.studentClass.value+"/"+result.examtype.value;

        axios.get(url,  config).then(result=>{
             if(result.data.data.length!==0){
        setSubject(result.data.data)
        structure.current.scrollIntoView({ behavior: "smooth" });
            }else{
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            } 
        })
        .catch(()=>{
        //Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        })
        }
        
        const handleStaffName=(cell)=>{
            if(cell!=='[]'){
            return JSON.parse(cell).map((itm, idx)=>{
                return <div  key={idx}>{getStaff(itm)}</div>
            } );
        }else{
            return ''
        } 
        }
        
        
        const getStaff = (code) =>{   
            
             const result = staff&&staff.filter(list=>list.code===code);
            const answer = result.length!==0?result.map(c=>` ${c.staffName}` ):''
        
              return  answer;
            }




useEffect(()=>{
    fetchExamType()
    fetchMyStudentClass()
},[]);


        return ( <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Exams Schedule">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Exam Schedule</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Exam Schedule</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

                    
    
     <section className="col-md-6">
            <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
      
      </section>

      <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                             studentClass&&studentClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section> 
      </div>

      <div className="row">
      <div className="col-md-12 ">  
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search Exam Schedule</button>
		 </div>
			</div>	             
				
							</div>
                            </div>
							</div></div>
</div>

</div>
{subject.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={'schoolName'}  />
     </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>EXAMINATION SCHEDULE DETAILS </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.studentClass.label}</b></td>
                    <td>Exam Type: <b> {result.examtype.label} Examination</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
        

            <thead>
                <tr>
                    <th>Exam Date</th>
                    <th>Subject</th>
                    <th>Max Mark</th>
                    <th>Subject Teacher</th>
                    
                </tr>
            </thead>
            <tbody>{subject.length!==0?subject.map((st, idx)=> 
                <tr key={idx}>
                <td>
                {longDate(st.examDate)}
                </td>
                    <td>{st.subjectName}</td>
                    <td>{st.maxMark}
                        
                    </td>
                    <td><b>{handleStaffName(st.teacher)}</b>
                    </td>
                    
                    
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
        
        <form method='POST' action={ServerUrl+'/printout/exam_schedule.php?token='+userToken} target='_blank'>
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}
 


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ExamSchedule) 