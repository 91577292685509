import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { Alerts} from '../component/notify'

import {ServerUrl,  Token, ImagesUrl, config, ParentUrl, schoolName, Currency, familyCode, FormatNumber} from '../component/include'
import { ValidateIsNew, shortText } from '../component/globalFunction';

const RelatedProducts =(props)=>{


	const [notice, setNotice] = useState({
		isLoading : false, 
		isDataFetching: false,
	})
	const handleWhislist =(code)=>{
       
		setNotice({...notice, isDataFetching: true }); 
	  const fd = new FormData();
	  fd.append('code', Math.random().toString(36).substr(2,9))
	  fd.append('productCode', code)
	  fd.append('jwt', Token)
	  fd.append('customerCode', familyCode)
	  let url = ServerUrl+'/save_controller.php?tablename=tbl_whishlist';
		axios.post(url, fd, config)
		.then(response =>{
		 if(response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
			
				}else if(response.data.type ==='info'){
					Alerts('Saved!', 'info', response.data.message)
					
						} else{
			Alerts('Error!', 'danger', response.data)
				}   
		})
		.catch((error)=>{
		  Alerts('Error!', 'danger', error.message)
		}).finally(()=>{
			setNotice({...notice, 
				isDataFetching: false
			});
		}) 
		
	  }


return ( <section className="padding-bottom">

<header className="section-heading mb-4">
	<h3 className="title-section">{props.title}</h3>
</header>

<div className="row row-sm">

{props.products.slice(0,6).map((item, id) =><div className="col-xl-4 col-md-6 col-sm-6 " key={id}>

<div className="  card prod-view z-depth-0">
<div className="prod-item text-center">
<div className="prod-img">
<div className="option-hover">


<a href={'/tuckshop/product/details/'+item.code}  className="btn btn-success btn-icon waves-effect waves-light m-r-15 hvr-bounce-in option-icon">
<i className="icofont icofont-cart-alt f-20"></i>
</a>




<button type="button" onClick={()=>handleWhislist(item.code)} className="btn btn-danger btn-icon waves-effect waves-light hvr-bounce-in option-icon">
<i className="icofont icofont-heart-alt f-20"></i>
</button>
</div>


<a href={'/tuckshop/product/details/'+item.code} className="hvr-shrink">
<img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpeg"}} src={`${ImagesUrl}/product/${item.coverUrl}`} title={item.productName} className="img-fluid o-hidden product-img"  />
</a>

{ValidateIsNew(item.isNew, item.isNewStart, item.isNewEnd)?
<div className="p-new"><a href={'/tuckshop/product/details/'+item.code}> New </a></div>:[]}


</div>
<div className="prod-info">
<a href={'/tuckshop/product/details/'+item.code} className="txt-muted">
<h4>{shortText(item.productName, 30)}</h4>
</a>
<div className="m-b-10">
<label className="label label-success">{3.5} <i className="fa fa-star"></i></label><a className="text-muted f-w-600">{item.rating} Ratings &amp; 3
Reviews</a>
</div>
<span className="prod-price"><i className="icofont "></i>{Currency+ FormatNumber(item.price)} 

{Number(item.oldPrice)>0?<small className="old-price"><i className="icofont "></i>{Currency+ FormatNumber(item.oldPrice)}</small>:[]}
</span>
</div>
</div>
</div>
	</div>)} 
</div> 
</section>
)

}

export default React.memo(RelatedProducts)