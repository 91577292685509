import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Alerts} from '../component/notify'
import {ImagesUrl, ServerUrl,  Token, config, familyCode} from '../component/include'

const Policy =()=>{


  const [allDocument, setAllDocument]= useState([])  
    const [notice, setNotice] = useState({
      isLoading : false,
      isDataFetching: true
  });
  

          function submit_policy(){  
              setNotice({...notice, isLoading: true})     
            
                const fd = new FormData();
                fd.append('jwt', Token);
                fd.append('user_code', familyCode)
                fd.append('code', 'pl'+Math.random().toString(36).substring(2,9));

                 let url = ServerUrl+'/save_controller/tbl_accept_policy'
                axios.post(url, fd, config)
                .then(response =>{
                 if(response.data.type ==='success'){
                  let open = document.getElementById('btnInfoDialog').click()
                Alerts('Success', 'default', 'Thank you');
                    }  
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice, isLoading: false}) 
                })
            }



            const fetchSchoolDocument =()=>{
              const fd = new FormData(); 

              var sql ="SELECT code, link, file_url, document_type from tbl_school_documents where ID NOT IN (6, 7, 8, 9, 10)"
    
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
              axios.post(url, fd, config)
              .then(response =>{
                
                const option  = response.data
                const list  = []
            
                for (var i = 0; i< option.length;  i++) {
                  list.push(
                      {  
                          code:option[i].code,
                          link:ImagesUrl+'/students/documents/'+option[i].file_url,
                          file_url:option[i].file_url,
                         type:option[i].document_type,
                      });
                      }
                setAllDocument(list)
              })
            }

            const background = ['bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow', 'bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow'];

            const textColor = ['btn-success', 'btn-primary', 'btn-dark', 'btn-danger', 'btn-warning', 'btn-info', 'btn-inverse', 'btn-success', 'btn-primary', 'btn-dark', 'btn-danger', 'btn-warning', 'btn-info', 'btn-inverse'];


            
useEffect(()=>{   
  fetchSchoolDocument();
},[]);


        return (  
          <div className="modal fade " id='licenseModal' data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content ">
                  
            <div className="modal-header">                                       
              <h4 className="modal-title">Please read and accept our policies</h4>        
                        
            </div> 
    
                  <div className="modal-body">
                   <h6> 
                    <b>Dear Parent,</b><br/><br/>
                    Thanks for choosing Aduvie and welcome to the Aduvie Portal, our comprehensive Student Information Management System.<br/><br/><br/>
    
    To ensure the quality of our service and continue using this system, we require that you study the following school policies and click on the accept to confirm that you and your child/ren agree in totality to the contents of the documents.
    <br/><br/><br/>
    
    
    
    
    </h6> 
    
    
    
    <div className="row">
    {allDocument.map((item, index)=><div className="col-xl-6 col-md-12" key={index}>
            <form  method='POST' action={item.link} target='_blank' >
    
    
            <input type='hidden' name='jwtToken' value ={Token} />
    
    
                  <div className= {`card social-card ${background[index]}`}>
                      <div className="card-block">
                          <div className="row align-items-center">
                              <div className="col-auto">
                                  <i className={`feather icon-file-text f-34 social-icon text-c-blue`}></i>
                              </div>
                              <div className="col">
                                  <h4 className="m-b-0">{item.type}</h4>    
                          <button type='submit' className={`btn btn-inverse btn-sm btn-round mt-1 `}><i className="feather icon-arrow-down"></i> Preview</button>
                              </div>
                          </div>
                      </div>
                  </div>
                  </form>
              </div>)}
    
    
              </div>
                  </div>


                  <div className="modal-footer">
                  <button type="button"  id={`btnInfoDialog`} className="btn btn-default waves-effect " data-dismiss="modal">No, Cancel</button>
               {notice.isLoading?<button type="button" disabled  className="btn btn-success active">Please wait ...</button>:  <button type="button" onClick={submit_policy} className="btn btn-success active">I UNDERSTAND AND ACCEPT</button> }
              </div>


              </div>
          </div>
      </div>
 );
        
}

export default React.memo(Policy) 