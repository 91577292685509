import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {API_URL, ServerUrl,  Token, config, familyCode} from '../component/include'
import Select from 'react-select'
import { Alerts } from '../component/notify'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'
import { longDate } from '../component/globalFunction'
const StudentReview =()=>{

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [student, setStudent] = useState([]);
    const [review, setReview] = useState([]);
const [profile, setProfile]=useState({
    student:[],
    review:'',
    dateReview:'',
    reviewBy:'',
    visibleToStudent:'YES',
    spotType:'',
    criteria:'',
    allotedPoint:''
});


const handleSelect = (option, action)=>{
    setProfile({...profile, [action.name]: option});
    fetchReview(option.value)
 }
   
    const handleFetchStudent =()=>{
        setNotice({...notice,  isLoading: true})
        var sql ="Select studentName, code, admissionNumber  from tbl_students where family_name ='"+familyCode+"'"; 
        const fd = new FormData(); 
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>setStudent(result.data.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isLoading: false})
    })
    }


    const fetchReview =(code=profile.student.value)=>{
        setNotice({...notice, isLoading: true})
        var sql ="Select r.ID, s.studentName, s.admissionNumber, r.review, r.dateReview, r.allotedPoint, r.spotType, r.criteria, r.visibleToStudent, r.code  from tbl_student_review r, tbl_students s where s.code =r.studentCode and r.studentCode ='"+code+"' and r.visibleToStudent ='YES' "; 
        const fd = new FormData(); 
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>setReview(result.data.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    
    

    const tableHeader = [

        {dataField: 'studentName', text: 'Student Name', sort: true, editable: false},
          {dataField: 'review',  text: 'Review', editable: false, sort: true, formatter:(cell)=><span style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
          {dataField: 'spotType', text: 'Spot Type', sort: true, editable: false, formatter: (cell)=>cell==='Red Spot'? <span className="pcoded-badge label label-danger">{cell}</span>: <span className="pcoded-badge label label-success">{cell}</span>},
          {dataField: 'allotedPoint', text: 'Point', sort: true, editable: false},
        {dataField: 'dateReview',  text: 'Date Added', editable: false, formatter:(cell)=>longDate(cell)}
     ];


    const StudentTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
      
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 
      
			


                 
                 <form method='POST' action={ServerUrl+'/printout/student_review.php?studentCode='+profile.student.value+'&token='+userToken} target='_blank'>	
                <input type='hidden' name='jwtToken' value ={Token} />
                <button type="submit" className="btn btn-outline-success" > <i className="fa fa-print"></i> Print</button>
        
        </form>
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                   
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
     


useEffect(()=>{   
    handleFetchStudent()
    
},[]);

        return (  
            <React.Fragment>
    
    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Student Review">
        <li className="breadcrumb-item"><a  href="/dashboard"> <i className="fa fa-dashboard"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Student</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Review</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div>
                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Select Student </label>
        <Select  options={student&&student.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber }
                                     })
                                }    
                    getOptionLabel={option =>option.label +' '+ option.admissionNumber}    
        onChange={handleSelect}  name="student" value={profile.student}  />   
                    </div>
			</section>

									</div>
										
							</div>
							</div>

							</div>
                </div>




           {profile.student.length!==0?     <div className="row">
                                    
                <div  className="col-md-12" >                   
<div className="card z-depth-0">
            <div className="card-header">
                <h5>Student Review</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StudentTable data={review}  columns={tableHeader}  />}
            </div></div>

    </div></div>
            
            </div>:''}
    
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</React.Fragment>
 );
        
}
export default React.memo(StudentReview) 