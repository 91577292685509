import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config,  API_URL, familyCode} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, FormatNumber, sort, getResultHead} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
const ViewMidResult =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

const [remarkResults, setRemarkResults] = useState([])
const [disabledResult, setDisabledResult]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
const [sqlQuery, setSqlQuery] = useState([]);  
const [remark, setRemark]=useState([])
const [studentClass, setStudentClass]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [mystudent, setMystudent]=useState([])

    
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        code:'',
        teacherRemark:'',
        teacherName:'',
        headRemark:'',
        headName:'',
        headofSchool:'',
        resultHead:'Secondary',
        obtained:0
    })

    

    const filterStudentClass =()=>{
   /*    const studentSection = mystudent.map(list=>list.sectionID)
let allClass = [...schoolClass]
let fstudent =[...mystudent]
let newClass = []

for (var i in allClass){
for(var k in fstudent){
    if(allClass[i].ID===fstudent[k].classID && newClass.includes(newClass[i])===false){

        const cl = allClass[i].section.filter(sec=>studentSection.includes(sec.ID)==true)
        allClass[i].section = cl;
        newClass.push(allClass[i])
    }    
}
} */


setStudentClass(schoolClass)
    }

        
      
    const fetchSettings =()=>{
        let url = API_URL+'/exam/result/session/all';
        axios.get(url,  config).then(result=>{
            if(result.data.length!==0){
                try{
                let config = JSON.parse(result.data.settings[0].settings)
                setSettings(config[0])

            }catch(e){
                   
            }
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }

        const fetchExamType =()=>{
       
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
            
                let setting = result.data.grademark;
                let examtype = result.data.examType
                var grad = setting[0].grade
                
                var gradeRemar = setting[0].gradeRemark
                let gradeMar = setting[0].gradeMark

                let examtyp = result.data.examType.filter(item=>item.publish ==='Yes')
                setExamtype(examtyp)
                setGrade(JSON.parse(grad).sort(sort))
                setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))

                setGrademark(JSON.parse(gradeMar).sort((a, b) =>(a.classtype > b.classtype) ? 1 : -1))

            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }

   


const handleSelectClass =(option, action)=>{     
          
    setErrors({...errors, [action.name]:''})
        setResult({...result, [action.name]:option, student:[]});
        fetchStudentList(option.value, result.examtype.value)
        fetchClassSubject(option.value, result.examtype.value)
        fetchClassTeaher(option.classID, option.value)
        fetchGradeMark(option.classtype)
        setStudent([])
        setRemarkResults([])
}

    const handleSelectExam =(option, action)=>{     
            setResult({...result, [action.name]:option, studentClass:[], student:[]});
            setErrors({...errors, [action.name]:''})
            filterStudentClass()
            setStudent([])
            setRemarkResults([])
        
    }


    const handleSelectStudent =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
        fetchStatistics()
        setScores([])
    }


    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 
         const fd = new FormData();
     fd.append("exam", result.examtype.value);
     fd.append("sectionID", result.studentClass.value);
    fd.append("sql", sqlQuery);
    let url = API_URL+'/exam/result/statistics';
    axios.post(url, fd, config).then(result=>{
       
         if(result.data.data.length!==0){
    setStudent(result.data.data)
    setRemarkResults(result.data.remarks)
        }else{
            setStudent([])
            setRemarkResults([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    
    const fetchMyStudentClass =()=>{
        var sql ="Select s.ID, s.code, s.admissionNumber, e.classID, e.sectionID  from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  s.family_name ='"+familyCode+"' group by e.classID"; 
       
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
       
        setMystudent(result.data)
    })
    }


           

    const fetchStudentList =async(section, exam)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/student/disabled/'+section+'/'+exam;
            await   axios.get(url,  config).then(result=>{

        if(result.data.data.length!==0){
            setStudentList(result.data.data)

            let studentList = result.data.studentList
            let std = studentList.length!==0?studentList[0].studentList:[]
            const options = std.length!==0? JSON.parse(std):[]

            if(options.length!==0){
                const rs = options.map((item)=>item.studentCode)
                setDisabledResult(rs)
            }else{
                
        setDisabledResult([])
            }

            }else{
                setStudentList([]) 
            } 
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
       
    })
    }




        const fetchClassSubject =(sectionID, exam)=>{
            let url = API_URL+'/exam/subject_list/'+sectionID+'/'+exam;
            axios.get(url, config).then(response=>{
                if(response.data.data.length!==0){
                var subj = response.data.data;
    
               


             
let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"' and e.absent='No'  then e.firstCA else 0 end) AS '"+data.subjectName+"'")
                   
            

             let final =   'SELECT  s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.firstCA >0 and e.absent="No" then ((e.firstCA/20)*100) else 0 end) as obtained,  sum(case when e.firstCA >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.firstCA >0 and e.absent="No" then  ((e.firstCA/20)*100) else 0 end)/sum(case when e.firstCA >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode '
    
            setSqlQuery(final)
               
            }else{
                setSqlQuery([]) 
            }
            })

        }

     
       
      



    
        
const getRemark =(code)=>{
    const ans = remark.filter(item=>item.code===code)
    return ans[0].remarkName
    }

const getGrade =(score, field)=>{
    const ans = grademark.filter(itm=>Number(score) >= Number(itm.minimum) && Number(score) <= Number(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   
return field ==='grade'?String(grade):String(remark)
}




        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            let url = API_URL+'/exam/mid_term_result/'+result.studentClass.value+'/'+result.examtype.value;
            axios.get(url,  config).then(response=>{
    
                 if(response.data.data.length!==0){
                    var obtained = 0;
                    var rs = response.data.data;
             
    var list = [];
    for (var i = 0; i< rs.length;  i++) {

        
        var sc = (Number(rs[i].firstCA)/20)*100
           obtained+=sc;

        list.push(

            {  
                

                abbreviation:rs[i].abbreviation,
                absent:rs[i].absent,
                comment:rs[i].comment,
                exam:rs[i].exam,
                position:rs[i].position,
                
                studentCode:rs[i].studentCode,
                totalAverage:FormatNumber((Number(rs[i].totalAverage)/20)*100),
                firstCA:FormatNumber((Number(rs[i].firstCA)/20)*100),
                grade:getGrade(sc, 'grade'),
                remark:getGrade(sc, 'remark'),
                total:FormatNumber(sc),
                subjectName:rs[i].subjectName,
                teacher:rs[i].teacher,

            });
            }
        setScores(list)


        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            }else{
                setScores([])
                Alerts('Info!', 'info', 'There are no schedule found for the selected exam')
            }  
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

        const fetchGradeMark =(classType)=>{
            if(grademark.length!==0){
                var grade = grademark.filter(item=>item.classtype===classType)
                setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))
            }else{
                setGrademark([])
            }             
        }

        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }

            
    const handleSearch = event =>{
        event.preventDefault(); 
        
        let errors = {};   
         
        if(result.examtype.length===0){
            errors.examtype ='Please select exam type';
        } 

        if(result.student.length===0){
            errors.student ='This field is required';
        } 


        if(result.studentClass.length===0){
            errors.studentClass ='This field is required';
        } 

        setErrors(errors)

        if(Object.keys(errors).length === 0){
            fetchResult()
            }

    } 




     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }




       const getStaffField = (code, field) =>{   
        const result = staff&&staff.filter(list=>list.code===code);
        const answer = result.map((c)=>c[field] ); 
        return  String(answer);
       }
 
       const getScores =(code)=>{
        let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code)
        return rs.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
    
    }
    
    const getStudent =(code)=>{
        let scr =  [...student];
        const rs = scr.filter(item=>item.code===code)
        return rs.length!==0? rs[0]:{obtainable:0, obtained:0, average:0};
    
    }
    
    
    
    
    const getData =(code)=>{
         let scr =  [...remarkResults];
        const rs = scr.filter(item=>item.studentCode===code)
        return rs[0];
    
    } 


useEffect(()=>{
    fetchMyStudentClass()
    fetchSettings()
    fetchExamType()
},[]);



        return ( <>
            
            {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Mid Term Result">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Mid Term Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Mid Term Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-4">
     <div className="form-group">
          <label> Exam Type <span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam, term: list.term }
                             })
                        } 
                       
onChange={handleSelectExam} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

 
             </div>
     
     </section>

                    <section className="col-md-4">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                          studentClass&&studentClass.map((list, idx)=> {
                               return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !=='' ? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectClass}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>       
  

     <section className="col-md-4">
     <div className="form-group">
						<label>Student</label>
						<Select  options={
                            studentList.filter(item=>item.family_name===familyCode).map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{scores.length!==0?studentList.filter(item=>item.code===result.student.value).map((std, index)=>
<div className="card z-depth-0" key={index}>
		<div className="card-block">
	
                       <div className="row">

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    
                    
                    <td className="text-center" >
                        
                        <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolInfo.schoolName}  />
     </td>

 <td > 
     
     <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  />
 
 
 </td>
                </tr>
            </tbody>
       </table>

</div> 

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>MID-TERM REPORT {result.examtype.label} </b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>


       <tr>
                <td >ADMISSION NO: <b> {std.admissionNumber} </b></td>
                    <td >NAME: <b>{std.studentName}</b></td>
                    
                    <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
                </tr>
                <tr>
                    
                <td>CLASS: <b> {result.studentClass.label} </b></td>
                    <td >NUMBER IN CLASS: <b>{studentList.length}</b></td>
                    <td >GENDER: <b>{std.gender}</b></td>
                   
                </tr>
                <tr>
                   
                <td >MARK OBTAINABLE: <b>{getStudent(std.code).obtainable}</b></td>
                    
                    <td >MARKS OBTAINED: <b>{getStudent(std.code).obtained && Number(getStudent(std.code).obtained).toFixed(0)} </b></td>
                   
                        <td >STUDENT'S AVERAGE: <b> {getStudent(std.code).average && Number(getStudent(std.code).average).toFixed(2)}  </b></td>
                    </tr>
                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >Subjects</th>
                     {getResultHead(result.studentClass.classtype)==='Secondary'? <th >Teacher</th>:''}
                    <th >1st CAT (100%)</th>
                    <th >Total</th>
                    <th >Grade</th>
                    
                    <th >Remark</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=> st.absent!=='Yes' && Number(st.firstCA) >0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                      {getResultHead(result.studentClass.classtype)==='Secondary'?  <td style={{maxWidth:'120px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(st.teacher)}</td>:''}
                    <td>{st.firstCA}</td>
                    <td>{st.total}</td>
                    <td>{st.grade}</td>
                    <td>{st.remark}</td>
                    </tr>
                :[])} 
            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12">
     <h6>*CAT Continuous Assessment Test</h6>
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td  style={{ align:'center', valign:'center' }}  colSpan={2} rowSpan={2}><b >KEY TO GRADES </b></td>
                 <td><b>Score Range </b></td>
                 {grademark.map((data, index)=><td key={index}>{data.minimum + ' - ' + data.maximum}</td>)}

             </tr>
             <tr>
                 <td><b>Grade </b></td>
                 {grademark.map((data, index)=><td key={index}>{getGradeScore(data.gradeCode)}</td>)}
                 
             </tr>
         </tbody>
     </table>
     
 <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'5px' }}><b>Form Tutor(s) </b></td>
                 <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>

             </tr>
         {getData(std.code).teacherRemark!==''?    <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'95px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                 
             </tr>:[]}
         </tbody>
     </table>
     {getData(std.code).resultHead==='Nursery'||getData(std.code).resultHead==='Pre-Nursery'?  <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Nursery </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofNursery, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                    
                {getData(std.code).headRemark}

                </td>
                <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofNursery, 'signature')}`}   alt={settings&& getStaffField(settings.headofNursery, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>: getData(std.code).resultHead==='Primary'?
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Primary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>:

     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }} ><b>Head of Secondary </b></td>
                 <td colSpan={2} style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(getData(std.code).headName, 'staffName')}</td>
                 
             </tr>
             <tr>
                 <td style={{maxWidth:'5px'}}><b>Comments </b></td>
                <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>
                <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(getData(std.code).headName, 'signature')}`}   alt={getStaffField(getData(std.code).headName, 'staffName')} /></td>
                 
             </tr>
         </tbody>
     </table>}
     <table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> 
                 <img id="viewPassport2" className="img-50"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings&&settings.headofSchool, 'signature')}`}   alt={settings&& getStaffField(settings.headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/student_midterm_result.php?token='+userToken} target='_blank'>
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />

                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='examCode' value ={result.examtype.value} />

                <input type='hidden' name='studentList' value ={JSON.stringify(studentList.filter(item=>item.code===result.student.value), null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
 
 {disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'black',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'white',  }}> Result not available at the momemt, please contact the Account's Department</h3>

</div>
:''} 
        </div>):[]}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(ViewMidResult) 