import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, familyCode} from '../component/include'

const ChangePassword =()=>{


    const [notice, setNotice] = useState({
      isLoading : false,
      isDataFetching: true
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [errors, setErrors] = useState({});
  
  const [content, setContent] = useState({
      code:familyCode,
      oldPassword:'',
      password:'',
      confirmPassword:''
  })  	
  
  const handleChange =(event)=>{
      const {name, value} = event.target;
      setContent({...content, [name] : value });
      setErrors({...errors, [name]:''});
  }
  
  const handleSubmit = (event) =>{
      event.preventDefault();         
      setErrors(ValidateInput(content));
        setIsSubmitting(true);
    }
    
    useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors, isSubmitting])
    
    
    const ValidateInput =(values)=>{
          let errors = {};


             
let password = values.password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{6,}/);

if(!password){
  errors.password ='Please provide a minimum of 6 character, 1 Uppercase, 1 Lowercase, 1 Special Character length password.';
}

          if(values.password!==values.confirmPassword){
            errors.confirmPassword ='Password does not match.';
        }

        if(values.password.length <6){
          errors.password ='Please provide a minimum of 6 character, 1 Uppercase, 1 Lowercase, 1 Special Character length password.';
        }
          
        if(!values.password){
          errors.password ='This field is required';
      }

      if(!values.confirmPassword){
        errors.confirmPassword ='This field is required';
    }

         
          return errors;
          }
  

          function submit(){  
              setNotice({...notice, 
                isLoading: true})     
            
                let formData = content;
                const fd = new FormData();
                fd.append('jwt', Token);
                for(let k in formData){
                fd.append(k, formData[k])
                }
                
                 let url = ServerUrl+'/update_controller/tbl_parent_password'
                axios.post(url, fd, config)
                .then(response =>{
                 if(response.data.type ==='info'){
                  Alerts('Saved!', 'default', response.data.message)
                  setTimeout(() => {
                    
                    window.open('/logout', '_self')
                    }, 3000);
                    } else{
                  Alerts('Error!', 'danger', response.data)
                    }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice, isLoading: false })
                  setIsSubmitting(false);
                }) 
            }

        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Please update your password">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Parent</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Password</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
                <div  className="row  ">
							
                               
                          <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Password</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
    <div  className="row">
									
                  <section className="col-md-12 ">
                <div className="form-group">
                  <label className='bold'>New Password</label>
                              <input  type="password" id="account-pass" placeholder="Enter new password"  onChange={handleChange} role='presentation' autoComplete='off'  name="password" value={content.password} className={errors.password  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} />	
                              <span style={{color:'red'}}>{errors.password && errors.password}</span>
            </div>
                  </section>
                  </div>
      
      <div className="row">
                  <section className="col-md-12">
                  <div className="form-group">
                  <label className='bold'>Repeat New Password</label>
                              <input autoComplete="off" onChange={handleChange} placeholder="Repeat new password" role='presentation'   name="confirmPassword" value={content.confirmPassword} className={errors.confirmPassword  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} type="password" id="account-confirm-pass" />	
                              <span style={{color:'red'}}>{errors.confirmPassword && errors.confirmPassword}</span>
            </div></section>
                  </div>
											

                                        <div  className="row">
                                        <section className="col-md-4">
	<footer className="pull-left">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-warning "> <i className='fa fa-lock'></i> Change Password</button>
	
								</footer>	
                                </section>			
							</div>
							</div>
                            </div>
                            </div>
                            
                            </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(ChangePassword) 