import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {

    const clearCookies=()=>{
      Cookies.remove('kasclkysd')	
      Cookies.remove('kafmcdk')
     
       Cookies.remove('kaportal')	
      Cookies.remove('kapkamtk')
      Cookies.remove('kapkauscd')
      Cookies.remove('katkrspw')
      Cookies.remove('_kapbexpt')
      Cookies.remove('_kapexpt')
      Cookies.remove('kassckayd')	


    }

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut