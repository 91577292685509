import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {ServerUrl,  config,   Token, userCode} from '../../component/include'

import { Alerts } from '../component/notify'
import ProfileCard from './profileCard'

const ChangePassword =()=> {
    

const [notice, setNotice] = useState({
    isLoading : false,
    isDataFetching: false
});

const [isSubmitting, setIsSubmitting] = useState(false);

const [applicant, setApplicant] = useState({
    password:'',
    confirmPassword:'',
    rsMessage:'',
    rsMessageError:''
})

const [errors, setErrors] = useState({});

const handleChange = event =>{		
    let {name, value} = event.target;	
    setApplicant({...applicant, [name] : value });
   setErrors({...errors, [name]:''});
}





const handleSubmit = event =>{
    event.preventDefault();
     
setErrors(ValidateInput(applicant));
    setIsSubmitting(true);
}
const ValidateInput =(values)=>{
    let errors = {};   
   
    if(values.password.length <6){
        errors.password ='Please enter minimum of 6 characters';
    } 

    if(values.confirmPassword.length <6){
        errors.confirmPassword ='Please enter minimum of 6 characters';
    }


  if(values.password!==values.confirmPassword){
      errors.password ='Password does not match.';
  }
    
    return errors;
    }



    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
        },[errors])


function submit(){  
    setNotice({...notice,  isLoading: true}) 	
       
    let formData = applicant;
    const fd = new FormData();
    for(let k in formData){
      fd.append(k, formData[k])
    }
    fd.append('jwt', Token);
    fd.append('code', userCode)
       let url = ServerUrl+'/update_controller/tbl_update_applicant_password';
    axios.post(url, fd, config)
    .then(response =>{
       
    if (response.data.type==='success'){
        setApplicant({...applicant, rsMessage:response.data.message})
        
        }else if (response.data.type ==='error'){
            setApplicant({...applicant, rsMessageError:response.data.message})
            
        }    
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
         
    }).finally(()=>{
        setIsSubmitting(false)
        setNotice({...notice,  isLoading: false }) 
        
}) 
}



        return (  <Template menu='password' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                                    <div className="page-body m-t-50">
                                    

                                        <div className="row pull-center">

                                        
    <div className="col-xl-9 col-md-12">
            <div className="card ">
                <div className="card-block">
                   
                   <div className="row">

                   
                <section className="col-md-12">

{applicant.rsMessage!==''?
				<div className='alert bg-success'>{applicant.rsMessage}</div>:''}
       
       {applicant.rsMessageError?
				<div className='alert alert-danger'>{applicant.rsMessageError}</div>:''}
						
<div className="form-group">
        <label >New Password</label>
        <input type="password" id="password" name="password"  value={applicant.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
                            <span className=" form-bar error">{errors.password }</span>
          </div>

          <div className="form-group">
        <label >Repeat Password</label>
        <input type="password" name="confirmPassword"   placeholder="Confirm Password"  value={applicant.confirmPassword}  onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
                            <span className=" form-bar error">{errors.password }</span>
                                
                            </div>

		</section>
                                       

                                        </div>
            </div>
        </div>

        <div className="text-center">
        <button type="button" onClick={handleSubmit} className="btn btn-primary btn-md waves-effect waves-light text-center m-b-20"><i className="fa fa-key"></i> Update My  Password</button>

                    </div>

        </div>

<ProfileCard />





                                    </div>


                                   

                    <br/>
                                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(ChangePassword) 