import React, {useEffect, useState, useRef} from 'react';
import { config,  ServerUrl, ImagesUrl, API_URL, FormatNumber, Currency, familyCode, Token } from '../component/include';
import axios from 'axios';
import { Alerts} from '../component/notify'
import { globalId, compare, shortText } from '../component/globalFunction';
import { TableLoader } from '../component/loader';

const CartItem =()=> {

    const [notice, setNotice] = useState({
        isLoading : false,
        isQuick:false,
        isDataFetching: false
    });

    let myRef = useRef()
    const [content, setContent] = useState([]);
    const [globalOrder, setGlobalOrder] = useState([]);
    const [promo, setPromo] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [errors, setErrors] = useState({});

    const [order, setOrder] = useState({
        shippingFee:0,
        tax:0,
        discount:0,
        isPromo:false,
        promoId:'',
        customerId:familyCode,
        promoCode:'',
        promoAmount:0,
        logisticMethod:'',
        logisticFee:0,
        orderId:globalId(),
        comments:''
    });
    
    

  
    const fetchOrder =()=>{
      let orderId = globalId()
      var sql ="Select orderId from tbl_orders where orderId = '"+ orderId+"'" ;
      const fd = new FormData();
      fd.append("sql", sql);
      
    let url = API_URL+'/exam/result/fetch_raw'
      axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){
          setGlobalOrder(result.data.data)
        }else{

        setGlobalOrder(0)
        }
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
      })
  
  }

    const fetchContent =()=>{
       
        setNotice({...notice, isDataFetching:true})
        let orderId = globalId()

        var sql ="Select o.ID, p.productName, p.shortDescription, p.coverUrl, o.files, o.quantityPrice, o.productCode, p.isPrinting, p.bulkPrices, o.quantity, o.total, o.attributes, p.price, o.totalAttributePrice, o.graphicFee, o.shippingFee, o.displayOrder from tbl_product_orders o, tbl_products p where o.productCode = p.code and o.orderId = '"+ orderId+"' order by o.ID DESC" ;
            
        const fd = new FormData();
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{ 
          if(result.data.data.length!==0){
            var total = 0 
            result.data.data.map(item=>total+=Number(item.total))
          setContent(result.data.data)
          setSubtotal(total)

          }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching:false})
        })
    
    }


    const fetchPromo =()=>{
       
        var sql ="Select code, percentage, ID, usageHistory, expiryDate from tbl_promo_code where isActive ='true' and expiryDate >=date_format(now(), '%Y-%m-%d') ";
        
        const fd = new FormData();
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        axios.post(url, fd, config).then(result=>{ 
          setPromo(result.data.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    
    }

  

    const handleRemoveItem =(row)=>{

        var response = window.confirm("Once removed, you will no be able to recover this record. Are you realy sure?");
        if (response === true) {
         
    let dataId = []
    let dataImage = []
    dataId.push(row.ID)
    var selectItem = row.files!=='[]'? JSON.parse(row.files):[]

    for (var i in selectItem){
            dataImage.push('../images/design/'+selectItem[i].fileUrl)
    }
    
        setNotice({...notice, 
            isLoading: true})  
    var data = JSON.stringify(dataId, null, 2)
    var imageLink = JSON.stringify(dataImage, null, 2);

    const newContent = content.filter(item=>item.ID!==row.ID)
    setContent(newContent)

  const fd = new FormData();
        fd.append('data', data)
        fd.append('jwt', Token)
        fd.append('imagelink', imageLink)
    let url = ServerUrl+'/deleteMultipleWithImage_controller.php?tablename=tbl_product_orders';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              handleUpdateCart()
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
              window.location.reload()
          }) 
        
    } 
}


const handlePromocode =()=>{
    const result = promo.filter(item=>item.code===order.promoCode)
    if(result.length!==0){
    var percent  =  Number(result[0].percentage)
    var amount = (percent/100) * Number(subtotal)
    var newSub = subtotal - amount
    setSubtotal(newSub)
    setOrder({...order, isPromo:true, promoId:result[0].ID, promoAmount:amount})
    Alerts('Saved!', 'success', percent+'% discount successfully added')
}
}


const ValidatePromo=()=>{
    if(order.promoCode!==''){
let result = promo.filter(item=>item.code===order.promoCode)
if (result.length===0){
    myRef.current.focus()
    setErrors({...errors, promoCode:order.promoCode+ ' is inavlid Code'})
    setOrder({...order, promoCode:''})

   // return true
}
    }
} 

const handleUpdateCart =()=>{
 
const fd = new FormData();
fd.append('jwt', Token)
    fd.append('data', JSON.stringify(content))
let url = ServerUrl+'/update_controller.php?tablename=tbl_update_product_orders';
      axios.post(url, fd, config)
      .then(response =>{
       if(response.data.type ==='success'){
         // Alerts('Saved!', 'success', response.data.message)
          
              } else{
          Alerts('Error!', 'danger', response.data)
              }   
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      })
}


const handleSubmitOrder =(total)=>{
 
      let formData = order;
      const fd = new FormData();
      for(let k in formData){
        fd.append(k, formData[k])
      }
     
      fd.append("subtotal", total)
      fd.append("newTotal", total)
      fd.append('jwt', Token)
      let url = ServerUrl+'/save_controller.php?tablename=tbl_orders';
      if(globalOrder.length!==0){
        url = ServerUrl+'/update_controller.php?tablename=tbl_orders';
      }

    axios.post(url, fd, config)
    .then(response =>{
     if(response.data.type ==='success'){
     //   Alerts('Saved!', 'success', response.data.message)
       // window.open('/order/checkout', '_self')
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    })
}


      const getBulkQuantity=(data, quantity)=>{
        let answer = data.filter(item=>item.quantity===quantity);
       let result = answer.length!==0?answer[0].price:0
       return result 
      }






useEffect(()=>{

  handleSubmitOrder(subtotal)
}, [globalOrder])
    
    
     const handleChangeSub =(row, i)=>{
       

        var value = Number(row.quantity)
    
            if(Number(row.quantity) >1){
          value = Number(row.quantity) - 1
        }

        var price  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.price)

        var qtyPrice  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.quantityPrice)


        var shipping = Number(row.shippingFee)

        var graphicFee = Number(row.graphicFee)

        var totalPrice = price+  Number(row.totalAttributePrice) + shipping + graphicFee
        var  subtotal = JSON.parse(row.bulkPrices).length!==0? totalPrice: totalPrice * value
      
        const newContent = [...content]

        newContent[i] = {...newContent[i], quantity: value, quantityPrice:qtyPrice, price:price, total: subtotal };
        setContent(newContent);
      }
    
  const handleChangeAdd =(row, i)=>{
       
    var value = Number(row.quantity) + 1

        var price  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.price)

        var qtyPrice  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.quantityPrice)


        var shipping = Number(row.shippingFee)

        var graphicFee = Number(row.graphicFee)

        var totalPrice = price+  Number(row.totalAttributePrice) + shipping + graphicFee
        var  subtotal = JSON.parse(row.bulkPrices).length!==0? totalPrice: totalPrice * value
      
        const newContent = [...content]

        newContent[i] = {...newContent[i], quantity: value, quantityPrice:qtyPrice, price:price, total: subtotal };
        setContent(newContent);
      }
      
    

    const handleChangeCart =(event, row, i)=>{
        let {name, value} = event.target;
         if(Number(value) <1){
            value =1
        } 

        var price  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.price)

        var qtyPrice  = JSON.parse(row.bulkPrices).length!==0?Number(getBulkQuantity(JSON.parse(row.bulkPrices), value)): Number(row.quantityPrice)


        var shipping = Number(row.shippingFee)

        var graphicFee = Number(row.graphicFee)

        var totalPrice = price+  Number(row.totalAttributePrice) + shipping + graphicFee
        var  subtotal = JSON.parse(row.bulkPrices).length!==0? totalPrice: totalPrice * value
      
        const newContent = [...content]

        newContent[i] = {...newContent[i], quantity: value, quantityPrice:qtyPrice, price:price, total: subtotal };
        setContent(newContent);
      }



      const handleChange =(event)=>{
        const {name, value} = event.target;
        setOrder({...order, [name]:value})
        setErrors({...errors, [name]:''})

      }
    

      


    useEffect(()=>{
        fetchContent()
        fetchPromo()
        fetchOrder()
       }, []);
  return (
    <div >
    <div className='table-responsive'> 
    {notice.isDataFetching?
    <TableLoader />:

    <table  className="table  table-striped  " >
    <thead>
    <tr>
    <th>Image</th>
    <th>Product Name</th>
    <th>Amount</th>
    <th className='w-20'>Qty</th>
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
    

{content.length!==0? content.map((item, index)=>
    <tr className={(index+1)%2==0?'odd':'even'} key={index}>
    <td className="pro-list-img" tabIndex={0}>
    <img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+item.coverUrl} alt={item.productName} className="img-fluid img-100"  />
    </td>
    <td className="pro-name">
    <h6>{item.productName}</h6>
    
<span dangerouslySetInnerHTML={{__html: shortText(item.shortDescription, 70)}}></span>
    
    </td>
    <td>
    <div className="price-wrap"> 
			<var className="price">{Currency+FormatNumber(item.total)}</var><br/> 
			<small className="text-muted">QP: {Currency+FormatNumber(item.quantityPrice)} </small> 
		</div> 
    </td>
    <td className='w-20'>

    {JSON.parse(item.bulkPrices).length!==0?
                 <select className="form-control" value={item.quantity} onBlur={handleUpdateCart} name="bulkQuantity" onChange={(e)=>handleChangeCart(e, item, index)} >
                      {JSON.parse(item.bulkPrices).map(price =>
                      <option key={price.ID} value={price.quantity} price={price.price}>{price.quantity} </option>
                      )}
                    </select>:
                   
    <input type="text" className="form-control" onFocus={(e)=>e.target.select()} onBlur={handleUpdateCart}  value={item.quantity} min='1' onChange={(e)=>handleChangeCart(e, item, index)} />
                      }

                      
    </td>
    <td className="action-icon text-center">
    <button type='button' onClick={()=>handleRemoveItem(item)} className=" btn btn-sm btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"><i className="icofont icofont-delete-alt"></i></button>
    </td>
    </tr>):[]}

    </tbody>
    </table>}
    
    </div></div>
  )
}

export default React.memo(CartItem)