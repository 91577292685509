import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, ImagesUrl, config, ParentUrl, schoolName, Currency, familyCode, API_URL} from '../component/include'
import { FormatNumber, compare, globalId } from '../component/globalFunction'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router';
import RelatedProducts from './RelatedProducts'
import Review from './review'
import OrderOption from './orderOption'

const ProductDetails =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
let params = useParams()

const [isSubmitting, setIsSubmitting] = useState(false); 
const [errors, setErrors] = useState({});
const [image, setImage] = useState('')
const [content, setContent] = useState([]);
const [shippingFee, setShippingFee] = useState([]);
const [products, setProducts] = useState([]);
const [images, setImages] = useState([]);
const [review, setReview] = useState([])
const [attributes, setAttributes] = useState([])
const [bulkPrice, setBulkPrice] = useState([])
const [productAttributes, setProductAttributes] = useState([])
const [brand, setBrand] = useState([])
const  [cartItem, setCatItem]= useState({
  catId:'cart-'+Math.random().toString(36).substr(2,9),
  productCode:params.code,
  customerCode:'',
  quantity:1,
  status:'',
  discount:0,
  attributes:[],
  files:[],
  price:0,
  globalId:globalId(),
  orderId:globalId(),
  shipping:0,
  tax:0,
  displayOrder:0,
  subtotal:0,
  quantityPrice:0,
  totalAttributePrice:0,
  info:'',
  profit:0,
  graphicFee:0,
  mode:'notPrintingItem'
})

const handleToggleImage=(url)=>{

  //adminImages+"/product/"+url}
  setImage(url)
}

const handleReview =()=>{
    var sql ="Select productId, customerId, reviewText, replyText, dateCreate, rating from tbl_product_review where isApproved ='true' and productId ='"+params.code+"'"

    const fd = new FormData();
    fd.append("sql", sql);
    
    let url = API_URL+'/exam/result/fetch_raw'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){
        setReview(result.data.data)
        }else{
            setReview([]) 
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
  }

    const handleWhislist =(code)=>{
       
          
        setNotice({...notice, isLoading: true})  
      const fd = new FormData();
      fd.append('code', Math.random().toString(36).substring(2,9))
      fd.append('productCode', code)
      fd.append('jwt', Token)
      fd.append('customerCode', familyCode)
      let url = ServerUrl+'/save_controller.php?tablename=tbl_whishlist';
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                
                    }else if(response.data.type ==='info'){
                        Alerts('Saved!', 'info', response.data.message)
                        
                            } else{
                Alerts('Error!', 'danger', response.data)
                    }    
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false })
        }) 
        
      }

    const fetchProductAttribute =()=>{
    
        var sql ="Select code, attributeName, predefineValues from tbl_products_attributes " ;
            
        const fd = new FormData();
        fd.append("sql", sql);
        
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{  
            if(result.data.data.length!==0){
          setAttributes(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    
    }

    const fetchBrand =()=>{
    
        var sql ="Select manufacturerName, imageUrl, code, pageName from tbl_manufacturer where published ='true'" ;
          
        const fd = new FormData();
        fd.append("sql", sql);
        
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{  
            if(result.data.data.length!==0){
           setBrand(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    
    }

    const handleSubmit = (event, url) =>{
        event.preventDefault();         
        setErrors(ValidateInput(cartItem));
            setIsSubmitting(true);
      }


    const ValidateInput =(values)=>{
        let errors = {};   
        
        if(values.price===0){
            errors.price ='Please select at least one attribute';
            Alerts('Error!', 'danger', errors.price)
        }
        if(Number(values.subtotal)<=0){
          errors.subtotal ='Unable to process your selection';
          Alerts('Error!', 'danger', errors.subtotal)
      }
        if(values.quantity===0){
          errors.quantity ='Quantity is required';
          Alerts('Error!', 'danger', errors.quantity)
      }
    
        if( bulkPrice.length!==0 && values.quantityPrice===0){
          errors.quantityPrice ='Quantity is required';
          Alerts('Error!', 'danger', errors.quantityPrice)
        } 
       
       
        return errors;
        }

    const handleAddToCart = () =>{

        setNotice({...notice, 
            isLoading: true})     
           
            let formData = cartItem;
            const fd = new FormData();
            for(let k in formData){
              fd.append(k, formData[k])
            }
            
          fd.append('jwt', Token)
              fd.append('fileItems',  '[]')
              fd.append('attribute',  JSON.stringify(cartItem.attributes, null, 2))
        
               let url = ServerUrl+'/save_controller.php?tablename=tbl_product_orders';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  window.open('/tuckshop/checkout', '_self')
                      } else{
                  Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                  setNotice({...notice, 
                      isLoading: false
                  })
              })  
        }

    const fetchContent =()=>{ 
    
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT code, productName, youtubeUrl, fullDescription,	shortDescription, isPrinting, designNote, crossSell, related, attributes, bulkPrices, metaTitle, metaKeywords, isNew, price, oldPrice, manufacturers, imageList, rating, coverUrl, isNewStart, isNewEnd, isShippable,  isFreeShipping, shippingDetails, isOutOfStock, ID FROM  tbl_products where isPublished ='true'"
            
          const fd = new FormData();
          fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
          axios.post(url, fd, config).then(result=>{

            
            let current = result.data.data.filter(item=>item.code===params.code )[0]
           if(current){
              setContent(current)
              setImage(current.coverUrl)
              setImages(result.data.data.length!==0?JSON.parse(current.imageList):'')
            setProducts(result.data.data)
            setProductAttributes(result.data.data.length!==0?JSON.parse(current.attributes):'')
            setBulkPrice(result.data.data.length!==0?JSON.parse(current.bulkPrices):'') 
            setShippingFee(result.data.data.length!==0?JSON.parse(current.shippingDetails):0)

            setCatItem({...cartItem, 
            quantityPrice:current.price
            })
           }else{
            window.open('/tuckshop/products', '_self')
           }
          })
          .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            
            setNotice({...notice, isLoading: false}) 
          })
        }


        const getBrandName =(code, field)=>{

            const result = brand&&brand.filter(item=>item.code===code)
            const answer = result.length!==0?result[0][field]:''
            return String(answer)
          } 
        const getItemName =(code, field)=>{
          const result = products&&products.filter(item=>item.code===code)
          const answer = result.length!==0?result[0][field]:''
          return String(answer)
        }
    
        const getAttribute =(code, field)=>{
          const result = attributes&&attributes.filter(item=>item.code===code)
          const answer = result.length!==0?result[0][field]:[]
          return answer
        } 
        const getAttributeValue =(code)=>{
          const result = attributes&&attributes.filter(item=>item.code===code)
          const answer = result.length!==0?result[0].predefineValues:[]
          return answer.length!==0?JSON.parse(answer):[]
        } 
    
          const getProductDetails =(data)=>{
            let list = content.length!==0?JSON.parse(data):[]
            let result = []
             content&&list.map((item, id)=>result.push({
              code:item.code, 
              key:id, 
              isNew:getItemName(item.code, 'isNew'), 
              productName:getItemName(item.code, 'productName'),
              oldPrice:getItemName(item.code, 'oldPrice'), 
              price:getItemName(item.code, 'price'),
              imageList:getItemName(item.code, 'imageList'),
              shortDescription:getItemName(item.code, 'shortDescription'), 
              rating:getItemName(item.code, 'rating'),
              isNewStart:getItemName(item.code, 'isNewStart'), 
              isNewEnd:getItemName(item.code, 'isNewEnd'),
              isShippable:getItemName(item.code, 'isShippable'), 
              coverUrl:getItemName(item.code, 'coverUrl'),
              isOutOfStock:getItemName(item.code, 'isOutOfStock'),
              brand:getItemName(item.code, 'manufacturers'),
              ID:getItemName(item.code, 'ID')
            }))
            return result.sort(compare)
            
          }
    
    
    
      const getShippingCharge=()=>shippingFee.length!==0?Number(shippingFee[0].shippingCharge):0
      
      const getBulkQuantity=(ID, field)=>{
        let answer = bulkPrice.filter(item=>item.quantity===ID);
       let result = answer.length!==0?answer[0][field]:0
       return result
      }
    
   
    
     
  useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
    handleAddToCart()
	}
  },[errors, isSubmitting])
  


    
      const handleChangeSub =()=>{
    
        var value = Number(cartItem.quantity)
    
            if(Number(cartItem.quantity) >1){
          value = Number(cartItem.quantity) - 1
        }
    
            var price  = Number(content.price)
            var shipping = content.isShippable==='true'? getShippingCharge():0
            var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
          
            var  subtotal = totalPrice * value
          
            setCatItem({...cartItem, quantity : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
          }
    
    
      const handleChangeAdd =()=>{
    var value = Number(cartItem.quantity) + 1
        
        var price  = Number(content.price)
        var shipping = content.isShippable==='true'? getShippingCharge():0
        var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
      
        var  subtotal = totalPrice * value
      
        setCatItem({...cartItem, quantity : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
      }
    
    const handleChangeCart =(event)=>{
    
      let {name, value} = event.target;
      if(Number(value) <1){
        value = 1
      }
    
      var price  = Number(content.price)
      var shipping = content.isShippable==='true'? getShippingCharge():0
      var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
    
      var  subtotal = totalPrice * Number(value)
    
      setCatItem({...cartItem, [name] : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
    }
    
    
    
    const handleChangeBulk =(event)=>{
      let {name, value} = event.target;
     let qtyPrice = value!==''? Number(getBulkQuantity(value, 'price')):0
      
      var price  = bulkPrice.length!==0?qtyPrice: Number(content.price)
      var shipping = content.isShippable==='true'? getShippingCharge():0
      var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
      var subtotal = price+ Number(cartItem.totalAttributePrice)
    
      setCatItem({...cartItem, quantity : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
    }
    
    
    const getOptionItem=(attributeCode, optionID, field)=>{
    let predefine = getAttributeValue(attributeCode)
    const answer = predefine.length!==0&&optionID!==''?predefine.filter(item=>item.ID===optionID)[0][field]:0
    return answer
    }
    
    
    
    const handleChangeAttribute =(event, code, attName)=>{
              
      let {name, value} = event.target;
      const newAttribute = cartItem.attributes;
    let items =[]
        items.push({attributeCode:code, attributeName:attName, optionID:value, optionName:String(getOptionItem(code, value, 'name')), optionPrice: (Number(getOptionItem(code, value, 'price')) * Number(cartItem.quantity))  })
    
        const attributeList = items.concat(newAttribute)
        const allAttribute =	attributeList.map(e=>e['attributeCode'])
                    .map((e,i,final)=>final.indexOf(e)===i&&i)
                    .filter(e=>attributeList[e])
            .map(e=>attributeList[e])
    
          var totalAttributePrice = 0;
          allAttribute.map(item=>totalAttributePrice += Number(item.optionPrice))
    
          var shipping = content.isShippable==='true'? getShippingCharge():0
          var bulkAmount = Number(getBulkQuantity(cartItem.quantity, 'price'))
          var qtyPrice  = bulkPrice.length!==0?bulkAmount: Number(content.price)
          var totalPrice = qtyPrice+ totalAttributePrice + shipping
          var subtotal = bulkPrice.length!==0?totalPrice : totalPrice * Number(cartItem.quantity)
    
    
    
      setCatItem({...cartItem, attributes : allAttribute, shipping:shipping,  totalAttributePrice:totalAttributePrice, subtotal:subtotal, price:totalPrice})
    
    }
    
    const handleSelectBulk =(quantity, price)=>{
     
      var shipping = content.isShippable==='true'? getShippingCharge():0
      var totalPrice = Number(price)+ Number(cartItem.totalAttributePrice) + shipping
      var subtotal = totalPrice
      setCatItem({...cartItem, quantity : quantity, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
      window.scrollTo({top:0, left:document.body.scrollHeight, behavior:'smooth'})
    }
    
    
    
          useEffect(()=>{
            fetchBrand()
            fetchContent()
            fetchProductAttribute()
            handleReview() 
           }, []);
    



        return (  
            <React.Fragment>
                 <Helmet>
          <title>{content&&content.metaTitle !==''?content.metaTitle:content.productName}</title>
        <meta name="description" content={content&&content.shortDescription} />
        <meta name="keywords" content={content&&content.metaKeywords} />

    
        <meta property="og:title" content={content&&content.metaTitle !==''?content.metaTitle:content.productName}/> 
        <meta property="og:site_name" content={schoolName} /> 
        <meta property="og:description" content={content.shortDescription} /> 
        <meta property="og:image" content={ImagesUrl+"/product/"+content.coverUrl} />  

        <meta property="og:url" content={ParentUrl+'/product/'+content.code}/> 
      
<meta property="og:type"          content="website" />

    
        <meta name="twitter:title" content={content.metaTitle !==''?content.metaTitle:content.productName} /> 
        <meta name="twitter:card" content={schoolName}/> 
        <meta name="twitter:description" content={content.shortDescription}/> 
        <meta name="twitter:image" content={ImagesUrl+"/product/"+content.coverUrl} />  
        <meta name="twitter:url" content={ParentUrl+'/product/'+content.code} /> 

    </Helmet>

    <OrderOption code={content&&content.code} />
    
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

                   <PageHeader title="Tuckshop">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-home"></i> </a>
                            </li>
                          
                            <li className="breadcrumb-item"><a href="/tuckshop/products">Products</a> </li>
                            <li className="breadcrumb-item"><a href="#!">{params.code.toUpperCase()}</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     {content&&content.length!==0?<div>
<div className="row">
<div className="col-md-12">

<div className="card product-detail-page">
<div className="card-block">
<div className="row">
<div className="col-lg-5  ">
<div className="port_details_all_img row">
<div className="col-lg-12 m-b-15">

<article className="gallery-wrap"> 


	<div className="img-big-wrap">
	  <div> <a href="#"><img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+image} className='hvr-shrink' /></a></div>
	</div> 


	<div className="thumbs-wrap">
    {images.slice(0,4).map((item, index)=>
	  <a href="#!" onClick={()=>handleToggleImage(item.imageUrl)} key={index} className="item-thumb"> <img  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+item.imageUrl} alt={index} /></a>)}
      
	</div> 
</article>

</div>


</div>
</div>


<div className="col-lg-7   product-detail" id="product-detail">
<div className="row">
<div className="col-lg-12 ">
<span className="txt-muted d-inline-block">Product
Code: <a href="#!"> {content.code.toUpperCase()} </a>
</span>

<span className="f-right">Availablity : <a href="#!">{content.isOutOfStock==='false'? 'In Stock':'Out of Stock'} </a> </span>
</div>


<div className="col-lg-12">
<h4 className="pro-desc">{content.productName}</h4>
</div>
{getBrandName(content.manufacturers, 'manufacturerName')!==''?
<div className="col-lg-12">
<span className="txt-muted"> Brand : {getBrandName(content.manufacturers, 'manufacturerName')}
</span>
</div>:''}


{content.isShippable==='true'?
	<small className="label-rating text-success"> <i className="fa fa-clipboard-check"></i> Required shipping Fee of {Currency+FormatNumber((getShippingCharge() * Number(cartItem.quantity)))} </small>
    :''} 

<div className="stars stars-example-css m-t-15 detail-stars col-lg-12">
<div className="br-wrapper br-theme-css-stars">
    

<div className="br-widget">
    
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18  m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 '></i>

    
    </div>
    
    </div>
</div>



<div className="col-lg-12">


{content&&content.isPrinting!=='false'?
<div>Total Price: <span className="price-colour" style={{fontSize:'20px',fontWeight: '600'}}>{Currency+FormatNumber(cartItem.subtotal)}</span></div>:''} 

<div className="form-row  mt-4" >

{productAttributes&&productAttributes.map((data, id)=>
<div className="form-group" key={id}>
  <div  className="d-flex justify-content-between align-items-center pb-1">
  {getAttributeValue(data.attribute).length!==0?  <label className="font-weight-medium f-20 bold" htmlFor={id}>{getAttribute(data.attribute, 'attributeName')}</label>:''}
    
  </div>
  {data.controlType==='select'?

  <select className="mr-2 form-control" 
  name={getAttribute(data.attribute, 'attributeName')}
  onChange={(e)=>handleChangeAttribute(e, data.attribute, getAttribute(data.attribute, 'attributeName'))} 

  value={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===data.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===data.attribute)[0].optionID:'' }>
    <option value="">Select {getAttribute(data.attribute, 'attributeName')}</option>

  {getAttributeValue(data.attribute).map(list =>
  <option  name={list.name}  value={list.ID} price={list.price} key={list.ID}>
    {list.name}
  </option>
)} 
  </select>:
  data.controlType==='radio'?
  <div className=" filter-content">
 <div className="form-radio"> 

{getAttributeValue(data.attribute).map(list => <div key={list.ID} className="radio radiofill radio-inline">
<label>
<input type="radio" 
checked={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===data.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===data.attribute)[0].optionID===list.ID?true:false:false} name={list.name} 
onChange={(e)=>handleChangeAttribute(e, data.attribute, getAttribute(data.attribute, 'attributeName'))} value={list.ID} price={list.price} id={list.ID} />
<i className="helper"></i>{list.name}
</label>
</div>)}
</div>
  </div>:
  
  ''}
</div>)}

</div>
 

<br/>



<span className="text-primary product-price">
    <i className="icofont "></i>{Currency+ FormatNumber(content.price)}</span>
    {Number(content.oldPrice)>0?<span className="done-task txt-muted">{Currency+ FormatNumber(content.oldPrice)}</span>:[]}


<hr />

<p dangerouslySetInnerHTML={{__html: content.shortDescription}}></p>
<hr />
<h6 className="f-16 f-w-600 m-t-10 m-b-10">
Quantity</h6>
</div>
<div className="col-xl-3 col-sm-12">
<div className="p-l-0 m-b-25">





{content.isOutOfStock==='false'? 

<div className="form-row  mt-6">

{bulkPrice.length!==0?
<select className="mr-2 form-control" value={cartItem.quantity} name="bulkQuantity" onChange={handleChangeBulk} style={{width: '15rem'}}>
    <option  value=''   >Select Quantity</option>
    {bulkPrice.map(price =>
    <option key={price.ID} value={price.quantity} price={price.price}>{price.quantity} </option>
    )}
  </select>:
  <div className="form-group col-md flex-grow-0">
  <div className="input-group mb-3 input-spinner">
    <div className="input-group-prepend">


    <button className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-minus" onClick={handleChangeSub}> − </button>
    </div>
    <input className="form-control w-50" type="text" onFocus={(e)=>e.target.select()} name="quantity" onChange={handleChangeCart} value={cartItem.quantity} min='1' />
    <div className="input-group-append">
        
      <button  className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-plus" onClick={handleChangeAdd}> + </button>
    </div>
  </div>
</div> 

}
 


</div>:<div className="price-wrap">
<span className="price text-price">Out of stock</span> 
</div>}


</div>
</div>






<div className="col-lg-12 col-sm-12 mob-product-btn">

{content.isPrinting==='true'?
<a className="btn btn-primary waves-effect col-md-5 waves-light m-r-20 " href="#orderOption" data-toggle="modal" > <i className="icofont icofont-cart-alt f-16"></i>Add to cart</a>:

<button className="btn btn-primary  col-md-5 waves-effect waves-light m-r-20 " disabled={notice.isLoading?true:false} onClick={handleSubmit} ><i className="icofont icofont-cart-alt f-16"></i>{notice.isLoading?'Please wait..':' Add to Cart'}</button>}

<button type="button" onClick={()=>handleWhislist(content.code)} className="btn btn-outline-primary waves-effect waves-light m-r-20" data-toggle="tooltip" title="" data-original-title="Add to wishlist">
<i className="icofont icofont-heart-alt f-16 m-0"></i>
</button>

</div>



<div className="col-lg-12 m-t-10">
             
              <h6 className="d-inline-block align-middle font-size-base my-2 mr-2">Share:</h6>
              
              <a className="share-btn sb-twitter mr-2 my-2" href={"http://www.twitter.com/share?url="+ParentUrl+"/tuckshop/product/details/"+content.code} target='_blank' ><i className="fa fa-twitter"></i> Twitter</a>
              
              <a className="share-btn sb-instagram mr-2 my-2" href="#!"><i className="fa fa-instagram"></i> Instagram</a>
              
              <a className="share-btn sb-instagram mr-2 my-2" href={"http://wa.me/?text="+ParentUrl+"/tuckshop/product/details/"+content.code}><i className="fa fa-whatsapp"></i> Whatsapp</a>

              <a className="share-btn sb-facebook my-2" href={"http://www.facebook.com/share.php?u="+ParentUrl+"/tuckshop/product/details/"+content.code} target='_blank' ><i className="fa fa-facebook-f"></i> Facebook</a>

            </div>



</div>
</div>



</div>
</div>
</div>

</div>
</div>

<div className="card product-detail-page">
<ul className="nav nav-tabs md-tabs tab-timeline" role="tablist">
<li className="nav-item">
<a className="nav-link active f-18 p-b-0" data-toggle="tab" href="#description" role="tab">Description</a>
<div className="slide"></div>
</li>
<li className="nav-item m-b-0">
<a className="nav-link f-18 p-b-0" data-toggle="tab" href="#bulk" role="tab">Bulk Purchase</a>
<div className="slide"></div>
</li>
<li className="nav-item m-b-0">
<a className="nav-link f-18 p-b-0" data-toggle="tab" href="#related" role="tab">Related Items</a>
<div className="slide"></div>
</li>
<li className="nav-item m-b-0">
<a className="nav-link f-18 p-b-0" data-toggle="tab" href="#review" role="tab">Reveiw</a>
<div className="slide"></div>
</li>
</ul>
</div>


<div className="card">
<div className="card-block">

<div className="tab-content bg-white">
<div className="tab-pane active" id="description" role="tabpanel">

{content&&content.fullDescription!==''?	
		<div dangerouslySetInnerHTML={{__html:content.fullDescription}} ></div>
 :''}




      {content&&content.youtubeUrl!==''?<div>  <h5 className="title-description">Product Video</h5>
      <br/>
      
    <article className="media m-b-3">
      <div className="media-body">
        <iframe  src={content&&content.youtubeUrl} height='300' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='product video'></iframe>
        
 <div>  
  
  </div>
      </div>
    </article>
    </div>:content.isPrinting!=='false'?<h4 dangerouslySetInnerHTML={{__html: content&&content.designNote}} ></h4>:''}



</div>
<div className="tab-pane" id="bulk" role="tabpanel">


    
{content&&bulkPrice.length!==0?
      <div className="row align-items-center ">
       
       <h5 className="title-description">Order {content&&content.productName} in Bulk Quantity</h5>
        <hr/>
          <div className="table-responsive ">
            <table className="table table-hover table-bordered">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {bulkPrice&&bulkPrice.map(price =>
                <tr key={price.ID}>
                  <td >{price.quantity}</td>
                  <td >{Currency+FormatNumber(price.price)}</td>
                  <td >{content.isPrinting==='true'?
          <a className="btn btn-primary btn-shadow btn-block" href="#orderOption" data-toggle="modal" >Add to Cart</a>:

                    <button className="btn btn-success btn-shadow btn-block" onClick={()=>handleSelectBulk(price.quantity, price.price)} >Select</button>}
                  
          </td>
                  </tr>
                )}
              </tbody>
            </table>
         

        </div>
      </div>:''}

</div>
<div className="tab-pane" id="related" role="tabpanel">

{content&&content.related!=='[]'?
<RelatedProducts title ={'Related Items'} products={getProductDetails(content.related)}  />:''}


</div>
<div className="tab-pane" id="review" role="tabpanel">


<Review code ={content.code} />



</div>
</div>
</div>

</div>
</div>:[]}






   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(ProductDetails) 