import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


   export const encrypt=data=>CryptoJS.AES.encrypt(data, 'kayus').toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, 'kayus')
  return byte.toString(CryptoJS.enc.Utf8)
  } 

  
export const userToken =Cookies.get('stkarfsh')?Cookies.get('stkarfsh'):[]