import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, config} from '../../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Header from '../component/header_no_login'

 const AccountVerify =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!email){
            errors.email ='Please enter valid email address';
        }
        
         if(!values.password){
            errors.password ='Please enter your password';
        } 
        
        return errors;
        }
       
	
        	
	function verifyApplicant(){  
		    
        
var query = new URLSearchParams(window.location.search)
var myemail = query.get("myemail")
var mytoken = query.get("mytoken")

if(myemail==='null'  || mytoken==='null' || mytoken==='' || myemail===''){
    window.open('/applicant_login', '_self')

}else{
    setNotice({...notice, isLoading: true}) 

      const fd = new FormData();
      fd.append('email', myemail)
      fd.append('applicationID', mytoken)

      let url = ServerUrl+'/save_controller.php?tablename=tbl_verify_applicant';
      axios.post(url, fd, config)
      .then(response =>{
      if(response.data.type === 'success'){
			
        setErrors({...errors, successMessage: response.data.message})

			}else if (response.data.type ==='error'){
              setErrors({...errors, errorMessage: response.data.message})
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setNotice({...notice, 
				isLoading: false
			}) 
        })    
    } 
}



useEffect(()=>{  
    verifyApplicant()
},[]);


        return (<>
         <Preloader />
          <div id="pcoded" className="pcoded load-height">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

        <Header />

        </div>

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section  className="with-header">
        <div className="container">

        <div style={{height:'60px'}}>
		</div>

            <div className="row">
                <div className="col-sm-12">
                    



	<div className="auth-box card">
		<div className="card-block">
		
			<div className="card-body">

            {errors.successMessage?
    <div className="alert alert-success mt-3">
	<p className="icontext"><i className="icon text-success fa fa-check fa-2x"></i>  {errors.successMessage}</p>
    <br/>
    <a href="/applicant_login"  className="btn btn-primary btn-md  waves-effect waves-light text-center m-b-20">Click Here To Login</a>
</div>:''}

{errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}


			</div>
			
		</div>
                            </div>

                            </div>
                
            </div>
        </div>
    </section>    
    </div>
</> 

 );
        
}

export default React.memo(AccountVerify)  