import React, {useEffect} from 'react';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import Login from './parent/login'
import ForgetPassword from './parent/forgotpassword'
import recover_password from './parent/recover_password';


import {  LocalUrl, PortalType } from './component/include';
import error403 from './settings/error403';
import error500 from './settings/error500';
import Error404 from './settings/error404';
import Template from './component/template';
import centralogin from './parent/centralogin';
import LogOut from './parent/logout';
import ApplicantTemplate from './application_portal/protected_view';
import StudentTemplate from './student_portal/component/template';
import registration from './application_portal/main/registration';
import AccountVerify from './application_portal/main/AccountVerify';
import ApplicantForgotPassword from './application_portal/main/ApplicantForgotPassword';
import ApplicationRecoverPassword from './application_portal/main/ApplicationRecoverPassword';

const App =()=>{

  return (<>
 
    <ReactNotification />
   <Router  >
    <Switch>
    <Route exact path="/" component={centralogin} />
  <Route exact path="/logout" component={LogOut} /> 
   {/*  <Route exact path="/forgot" component={ForgetPassword} /> */}
    <Route exact path="/recover_password" component={recover_password} />

    <Route exact path="/application_portal/registration" component={registration} />
    <Route exact path="/application_portal/account_verify" component={AccountVerify} />
    <Route exact path="/application_portal/forgot_password" component={ApplicantForgotPassword} />

    <Route exact path="/application_portal/recover_password" component={ApplicationRecoverPassword} />


    
    {PortalType==='Parent'?
    <Template />:
    PortalType==='Student'?
    <StudentTemplate />:
    <ApplicantTemplate />}

      <Route exact path="/error403" component={error403} />
      <Route exact path="/error500" component={error500} />







      <Route exact  component={Error404} />
      
    </Switch>
      </Router>
 </>
    );
  }
export default App;
