import React from 'react';


const Confirmation =({orderId})=>{

return (
    
    <div >
    
    <h3 id="basic-forms-h-3"  className="title"> Confirmation </h3>
    <hr/>
    <div className="confirmation">
    

    <div className="card py-3 mt-sm-3">
          <div className="card-body text-center">
          <div className="text-primary m-b-20">
    <div className="fa fa-check fa-2x">
    </div>
    </div>

            <h2 className="h4 pb-3">Thank you for your order!</h2>
            <p className="font-size-sm mb-2">Your order has been placed and will be processed as soon as possible.</p>
            <p className="font-size-sm mb-2">Make sure you make note of your order number, which is <b>{orderId}.</b></p>
            <p className="font-size-sm">You will be receiving an email shortly with confirmation of your order. <u>You can now:</u></p><a className="btn btn-secondary mt-3 mr-3" href="/tuckshop/products">Go back shopping</a><a className="btn btn-primary mt-3" href={"/order/tracking/"+orderId}><i className="czi-location"></i>&nbsp;Track order</a>
          </div>
        </div>


    <div className="confirmation-content text-center">
   
    <div className="row">
    <div className="col-md-12">


    {/* <p>If you are not happy with a product you have received, don’t worry. You can always return it back within 7 days without any deductions. If you wish to replace a damaged product, we will send you a new one. Send us an email preferably within 48 hours of receiving the product. Our team will reply with the instructions and return shipping address to which you need to send back the product. We can also refund the amount to your credit card/bank account or offer you a store wallet credit redeemable towards future purchases.</p> */}

    </div>
    </div>



    


    </div>
    </div>
  </div>
)
}

export default React.memo(Confirmation)