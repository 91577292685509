import React, {useState, useEffect} from 'react'
import Loader, {}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, ImagesUrl, config, Currency, FormatNumber, familyCode, API_URL} from '../component/include'
import { compare, globalId, shortText, ValidateIsNew } from '../component/globalFunction'
import QuickView from './quickView'

const Products =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
	let [limit, setLimit] = useState(18);

	const nextPage = (num)=>{
		setLimit(num)
		fetchContent(num)
	  }
      const  [cartItem, setCatItem]= useState({ })
const [content, setContent] = useState([]);
const [search, setSearch]= useState({
    productName:'',
})

const [images, setImages] = useState([]);
const [viewItem, setViewItem] = useState('');
const [product, setProduct] = useState([]);
const [review, setReview] = useState([])

const [image, setImage] = useState('')


  const fetchContent =(limit)=>{
    setNotice({...notice, 
        isDataFetching: true
    });
   
    var sql ="Select ID, code, oldPrice, isShippable, isNew, isNewStart, isNewEnd, rating, manufacturers, isOutOfStock, imageList,  productName, shortDescription, isNew, isNewStart, isNewEnd, price, coverUrl, code, attributes, bulkPrices, youtubeUrl, isPrinting, designFee, isFreeShipping, shippingDetails from tbl_products where isPublished ='true' and productName <> ''  " ;

    if(search.productName!==''){
        sql = sql + ' and productName Like "%'+search.productName+'%" or shortDescription Like "%'+search.productName+'%" or price Like "%'+search.productName+'%" or rating Like "%'+search.productName+'%"'
    }
   

    sql = sql + ' order by ID DESC LIMIT '+limit
       
    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){
        setContent(result.data.data)
        }else{
            setContent([])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        });
    }) 

}

const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
} 



const handleWhislist =(code)=>{
       
    setNotice({...notice, isDataFetching: true }); 
  const fd = new FormData();
  fd.append('code', Math.random().toString(36).substr(2,9))
  fd.append('productCode', code)
  fd.append('jwt', Token)
  fd.append('customerCode', familyCode)
  let url = ServerUrl+'/save_controller.php?tablename=tbl_whishlist';
    axios.post(url, fd, config)
    .then(response =>{
     if(response.data.type ==='success'){
        Alerts('Saved!', 'success', response.data.message)
        
            }else if(response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
                
                    } else{
        Alerts('Error!', 'danger', response.data)
            }   
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        });
    }) 
    
  }
  const handleReview =(code)=>{
    var sql ="Select productId, customerId from tbl_product_review where isApproved ='true' and productId ='"+code+"'"

    const fd = new FormData();
    fd.append("sql", sql);
    
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>{ 
        setReview(result.data.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
  }


  const handleQuickView =(item)=>{
    setViewItem(item.code);
    handleReview(item.code);
    
    setCatItem({
  catId:'cart-'+Math.random().toString(36).substr(2,9),
  productCode:item.code,
  customerCode:familyCode,
  quantity:0,
  status:'',
  discount:0,
  attributes:[],
  files:[],
  price:0,
  globalId:globalId(),
  orderId:globalId(),
  shipping:0,
  tax:0,
  displayOrder:0,
  subtotal:0,
  quantityPrice:0,
  totalAttributePrice:0,
  info:'',
  profit:0,
  graphicFee:0,
  mode:'notPrintingItem'
})
setProduct(item)
setImage(item.coverUrl)

setImages(JSON.parse(item.imageList).sort(compare))

        var close = document.getElementById("clickme");
            close.click() 
  } 





useEffect(()=>{
    fetchContent(limit)
       }, []);


        return (  
            <React.Fragment>
   <>{notice.isDataFetching ?<Loader /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
<a  href={"#product-"} id='clickme'  data-toggle="modal" > </a>

                <QuickView
                 data={product}
                 code={viewItem}
                 image={image}
                 review={review}
                 images={images}
                 cartItem={cartItem}
                 setCatItem={setCatItem}
                  setImage={setImage}
                 
                 />

                   {/* <!-- Page-header start --> */}

        <PageHeader title="Tuckshop">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-shopping-cart"></i> </a>
                            </li>
                          
                            <li className="breadcrumb-item"><a href="#!">Products</a> </li>
                    </PageHeader>
{/* The Page Body start here */}

                <div className="page-body">
              
                <div className="card">
<div className="card-block">
<div className="row">
<div className="col-lg-8 offset-lg-2">
<p className="txt-highlight text-center m-t-20">Use the box below to filter item based on there unique identifications such as product name, price, manufacturers, ratings and description. Type in and click on search.
</p>
</div>
</div>
<div className="row seacrh-header">
<div className=" offset-sm-2 col-sm-8 offset-sm-1  ">
<div className="input-group input-group-button input-group-primary">
<input type="text" className="form-control" name="productName" value={search.productName} onChange={handleChange} placeholder="Search here..." />
<button className="btn btn-primary input-group-addon" onClick={()=>nextPage(limit)} >Search</button>
</div>
</div>
</div>
</div>
</div>
              
<h4 className="m-b-20"><b>{content.length}</b> Search Results Found</h4>

<div className="row ">

    {content&&content.map((item, id)=>
<div className="col-xl-4 col-md-6 col-sm-6 " key={id}>
<div className="  card prod-view">
<div className="prod-item text-center">
<div className="prod-img">
<div className="option-hover">


<a href={'/tuckshop/product/details/'+item.code}  className="btn btn-success btn-icon waves-effect waves-light m-r-15 hvr-bounce-in option-icon">
<i className="icofont icofont-cart-alt f-20"></i>
</a>


<button type='button' onClick={()=>handleQuickView(item)} className="btn btn-primary btn-icon waves-effect waves-light m-r-15 hvr-bounce-in option-icon">
<i className="icofont icofont-eye-alt f-20"></i>
</button>


<button type="button" onClick={()=>handleWhislist(item.code)} className="btn btn-danger btn-icon waves-effect waves-light hvr-bounce-in option-icon">
<i className="icofont icofont-heart-alt f-20"></i>
</button>
</div>


<a href={'/tuckshop/product/details/'+item.code} className="hvr-shrinks">
<img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpeg"}} src={`${ImagesUrl}/product/${item.coverUrl}`} title={item.productName} className="img-fluid o-hidden product-img w-100"  />
</a>

{ValidateIsNew(item.isNew, item.isNewStart, item.isNewEnd)?
<div className="p-new"><a href={'/tuckshop/product/details/'+item.code}> New </a></div>:[]}


</div>
<div className="prod-info">
<a href={'/tuckshop/product/details/'+item.code} className="txt-muted">
<h4>{shortText(item.productName, 30)}</h4>
</a>
<div className="m-b-10">
<label className="label label-success">{3.5} <i className="fa fa-star"></i></label><a className="text-muted f-w-600">{item.rating} Ratings &amp; 3
Reviews</a>
</div>
<span className="prod-price"><i className="icofont "></i>{Currency+ FormatNumber(item.price)} 

{Number(item.oldPrice)>0?<small className="old-price"><i className="icofont "></i>{Currency+ FormatNumber(item.oldPrice)}</small>:[]}
</span>
</div>
</div>
</div>
</div>)}

</div>

{content.length!==0?
<div className="row">
    <div className="col-md-12 d-flex justify-content-center ">
<button className='btn btn-danger  ' type='button'  onClick={()=>nextPage(limit+6)}><i className='fa fa-search'></i> Load More Products</button>
</div>
</div>:[]}


{content.length===0?
<div className="card ">
    <div className="card-body text-center">
	<h4>Did you find what you were looking for？</h4>
	<a href="#" className="btn btn-success ">Yes</a>
	<a href="/ticket" className="btn btn-secondary m-l-5">No</a>
    </div>
</div>:''}



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(Products) 