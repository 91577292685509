import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, Currency, FormatNumber, ImagesUrl, familyCode} from '../component/include'

import Select from 'react-select'

const Hostel =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [room, setRoom]= useState([]) 
const [allHostel, setAllHostel]= useState([]) 
const [rooms, setRooms]= useState([]) 



const [CurrentSession, setCurrentSession] = useState('')
const [allocation, setAllocation] = useState({
  hostelCode:'',
  roomCode:'',
  hostelName:'',
  student:'',
  amountPaid:0,
  allocationDate:new Date().toISOString().slice(0,10),
  vacateDate:'0000-00-00'

})


const [student, setStudent] = useState([]);



const fetchStudent =()=>{
    var sql ="SELECT code, gender, admissionNumber, registerNumber, studentName, father_mobile, fatherName, email, mobile from tbl_students where  family_name ='"+familyCode+"'"
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	if(Array.isArray(result.data)){
		setStudent(result.data)
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}


    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(allocation));
    setIsSubmitting(true);
    } 

    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(!values.hostelCode){
          
    Alerts('Error!', 'danger', 'Please choose hostel')
            errors.hostelCode ='Please choose hostel';
        }

        if(values.roomCode.length===0){
          errors.roomCode ='Please select room';
      }

      if(values.student.length===0){
        errors.student ='Please select student';
    }

if(Number(room.totalStudent) >= Number(room.noBedSpace)){
  errors.roomCode ='The room selected is occupied';
}

        return errors;
        }
    



        const  submit=()=>{  
          setNotice({...notice,   isLoading: true})
      
      
             const fd = new FormData(); 
             fd.append("jwt", Token);
            
             fd.append("hostelName", allocation.hostelName)
             fd.append("roomName", allocation.roomCode.label)

            fd.append("studentCode", allocation.student.value)
            fd.append("hostelCode", allocation.hostelCode)
            fd.append("roomCode", allocation.roomCode.value)
            fd.append("amountPaid", allocation.amountPaid)
            fd.append("allocationDate", allocation.allocationDate)
            fd.append("vacateDate", allocation.vacateDate)
            fd.append("currentSession", CurrentSession)
            fd.append("studentName", allocation.student.studentName)
            fd.append("email", allocation.student.email)

            fd.append("status", 'Not Yet Occupied')
            fd.append("isApproved", 'false')

            fd.append("code", Math.random().toString(36).substr(2,9))
            
                   let url = ServerUrl+'/save_controller/tbl_hostel_allocation'
                  axios.post(url, fd, config)
                  .then(response =>{
                  window.scrollTo(0,0)
                  if (response.data.type ==='success'){
                      setErrors({...errors, successMessage: response.data.message})
                      
                  } else{
                      setErrors({...errors, errorMessage: JSON.stringify(response.data.message)})
                  
                      }  
                               
                       })
                       .catch((error)=>{
                          setErrors({...errors, errorMessage: error.message})
                    })
                    .finally(()=>{
                      setIsSubmitting(false);
                      setRoom([])
                      setRooms([])
                      fetchHostel()
                      setNotice({...notice,  isLoading: false}) 
                      setAllocation({...allocation,
                          hostelCode:'',
                          roomCode:'',
                          hostelName:'',
                          student:[]
                      })
                    })
          } 




 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    

          const fetchRooms =(code)=>{
            setNotice({...notice,   isLoading: true})
            let sql = "Select r.ID, r.code, r.roomName, r.roomRate, r.roomStatus, h.hostelName from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and r.roomStatus ='Active' and h.code = '"+code+"' order by r.ID ASC"
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                setRooms(result.data)
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isLoading: false})
            })
            }


          const fetchRoom =(code)=>{
            setNotice({...notice,   isLoading: true})
            
            let sql = "Select  r.ID, r.code, r.roomName, r.roomRate,  r.roomStatus, r.noBedSpace, h.hostelName, (select count(*) from tbl_hostel_allocation h where h.roomCode = '"+code+"' and h.currentSession = '"+CurrentSession+"') as totalStudent from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code  and r.code = '"+code+"'"
          
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(Array.isArray(result.data)){
                     setRoom(result.data[0])
                }else{
                    setRoom([])
                } 
               
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isLoading: false})
            })
            }

      const handleSelect = (option, action)=>{
        if(action.name==='roomCode'){
            fetchRoom(option.value)
        }
        
        setAllocation({...allocation, [action.name]: option});
        setErrors({...errors, [action.name]:''})
          }


  const handleChangeHostel =(code, hostelName)=>{
    fetchRooms(code)
    setAllocation({...allocation, hostelCode: code, roomCode:'', hostelName:hostelName}); 
    setRoom([])
  window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
   }


   const fetchHostel =()=>{
        
    let sql = "Select  ID, code, hostelName, location, hostelAdmin, imageUrl, status from tbl_hostel  where status='Active'"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setAllHostel(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    })
    }


    const  handleSession =()=>{

      var sql ="Select  startYear from tbl_school_session where status ='Active'"
      const fd = new FormData();
      fd.append("sql", sql);
      fd.append("jwt", Token);
      let url = ServerUrl+'/fetchBySql_controller';
      axios.post(url, fd, config)
      .then(result => {
          if(result.data.length!==0){
          setCurrentSession(result.data[0].startYear)
      }
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', JSON.stringify(error.message)) 
      })
      }

useEffect(()=>{
    fetchHostel()
    handleSession()
    fetchStudent()
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Hostel Allocation">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                    </PageHeader>
                    <hr />
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}



    <h4>Choose Hostel</h4>
    <div  className="row  " >

{allHostel&&allHostel.map((item,id)=>
    <div key={id} className="col-md-12 col-xl-4 "  >

    <div onClick={()=>handleChangeHostel(item.code, item.hostelName)} style={{cursor:'pointer'}} className={allocation.hostelCode===item.code?'card user-card  bg-success':'card user-card '}  >
            <div className="card-body ">
                <img className="img-fluid img-radius " onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={ImagesUrl+'/hostel/'+item.imageUrl} alt="card-img" />
                
                <h4>{item.hostelName}</h4>
                <h6>{item.location}</h6>
          
           
        </div></div>
    </div>)}
    
</div>



<div  className="row  ">
							
              <div className="col-sm-12">


            <div className="card z-depth-0">
<div className="card-header">
<h5><i className="fa fa-edit" ></i> Reserve Hostel Room</h5>
<div className="card-header-right">
<ul className="list-unstyled card-option">
<li><i className="feather icon-maximizes full-card"></i></li>
<li><i className="feather icon-minus minimize-card"></i></li>
<li><i className="feather icon-trash-2 close-card"></i></li>
</ul>
</div>
</div>
<div className="card-block">

<div  className="row">



<section className="col-md-6">
                    
<div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            student&&student.map((list, idx)=> {
                               return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, email:list.email, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName+' c/o '+option.fatherName+' '+option.mobile}

onChange={handleSelect}  name="student" value={allocation.student}  className={errors.student? 'form-control form-control-danger' : ''} /> 
	 <span style={{color:'red'}}>{errors.student&& errors.student}</span>

</div>

</section>


<section className="col-md-6">
                    
                    <div className="form-group">
                    <label >Select Room<span style={{color:'red'}}>*</span></label>
<Select options={
               rooms&&rooms.map((list, idx)=> {
                   return {key:idx, value: list.code, label: list.hostelName, roomName:list.roomName  }
                 })} 
            getOptionLabel={option =>`${option.label} - ${option.roomName}`}
onChange={handleSelect} className={errors.roomCode? 'form-control form-control-danger' : ''} name="roomCode" value={allocation.roomCode}  /> <span style={{color:'red'}}>{errors.roomCode && errors.roomCode}</span>

</div>

</section>

<div style={{display:room.length===0?'none':'block'}} className="col-md-12">
<div className="alert alert-info alert-block background-info" >
<h4 className="alert-heading " style={{textAlign:'center'}}> <i className="fa-fw fa fa-info"></i> Room Details</h4>
   
    <div className="table-responsive">
<table className="table">
<tbody>

<tr>
<td>Hostel Name:</td>
<th>{room&&room.hostelName}</th>
<td>Room Name:</td>
<th>{room&&room.roomName}</th>
</tr>

<tr>
<td>Cost Per Bed</td>
<th>{room&&Currency+FormatNumber(room.roomRate)}</th>
<td>Room Status</td>
<th>{room&&room.roomStatus}</th>
</tr>


<tr>
<td>No of Bed Space</td>
<th>{room&&room.noBedSpace}</th>
<td>Occupied Space</td>
<th>{room&&room.totalStudent}</th>
</tr>

</tbody>
</table>
</div>



    </div>
</div>
</div>
				
                          
<div className="row">
<footer className="col-sm-12">

<button type="button" id="submit" onClick={handleSubmit} className="btn btn-dark"><i className="fa fa-bed"></i> Reserve Room</button>
</footer>		
</div>	


</div>
</div>


            </div>
            
            </div>
                           

                         

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(Hostel) 