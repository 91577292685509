import React from 'react';
import { ImagesUrl } from '../component/include';


const OrderOption =(props)=>{

return (
    
    <div className="modal fade" id="orderOption" tabIndex="-1" role="dialog">
    <div className="modal-dialog " role="document">
      <div className="modal-content">
       
        <div className="modal-header">
        <h4>How would you love to order?</h4>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

        </div>
        <div className="modal-body tab-content py-4">

          
        <div className="row">
          
          <div className="col-md-6 col-sm-12">
          <div className="sales-pitch">
          <a href={"/tuckshop/order/customDesign/"+props.code} style={{color:'blue'}}>
          <img src={ImagesUrl+"/icon2.png"} alt="" className="round" width="100" /> <p>Upload your complete design<br />(AI, PDF, PSD, CDR, JPEG, PNG)</p>
          </a>
          </div>
          </div>
          <div className="col-md-6 col-sm-12">
          <div className="sales-pitch">
          <a href={"/tuckshop/order/requestExpertDesign/"+props.code} style={{color:'blue'}}>
          <img src={ImagesUrl+"/icon3.png"} alt="" className="round" width="100" /> <p>Let our experts help<br />you with your design</p>
          </a>
          </div>

          </div>
          </div>

      </div>
      </div>
    </div>
  </div>
)
}

export default React.memo(OrderOption)