import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl,  Token, Currency, familyCode, config, ImagesUrl, API_URL} from '../../component/include'
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useSelector } from 'react-redux'

const FamilyFees=()=>{

	const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [feecharge, setFeecharge] = useState([]);
    const [studentFee, setStudentFee]=useState({
        familyName:[],
        feecharge:'',
        sumTotal:'',
        sumPaid:'',
        sumBalance:''
    });
    


    const [classResult, setClassResult]=useState([]);
    const [payment, setPayment] =  useState([]);

    
    const fetchFeecharge =()=>{
        let url = API_URL + '/fetch/data/tbl_fees_charge'
axios.get(url, config).then(result=>{
           
        setFeecharge(result.data.data.filter(item=>item.status=='true'))
        })
    }


const fetchPayment =()=>{
    setNotice({...notice, isLoading: true}) 
    
    var sql =" Select  sum((select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code)) as paid, fm.familyName, fm.code, sum(s.total) as total from  tbl_student_fees s, tbl_fees_charge f, tbl_family fm, tbl_students st  where f.code = s.feeCharge  and fm.code = st.family_name and st.code = s.studentCode and st.family_name ='"+familyCode+"' group by fm.code "
    
    
    const fd = new FormData();
fd.append("sql", sql);

    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
	
setPayment(result.data.data)


var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
            var option = result.data.data
    for(var i = 0; i < option.length; i++)
    {
        sumTotal = sumTotal + Number(option[i].total);
        sumPaid = sumPaid + Number(option[i].paid);
        sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
    }
    setStudentFee({...studentFee,
        sumTotal:FormatNumber(sumTotal),
        sumPaid:FormatNumber(sumPaid),
        sumBalance:FormatNumber(sumBalance)
    })

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchResult =(charge ='')=>{
	setNotice({...notice, isLoading: true}) 
	
    var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code) as paid, st.studentName, st.mobile, st.admissionNumber, fm.familyName,  s.studentCode, fm.code, s.total from  tbl_student_fees s, tbl_fees_charge f, tbl_family fm, tbl_students st  where f.code = s.feeCharge  and fm.code = st.family_name and st.code = s.studentCode and fm.code ='"+familyCode+"'" ;
    
 if(charge!==''){
        sql = sql + '  and s.feeCharge =   "'+charge+'"'
    }
    
    const fd = new FormData();
fd.append("sql", sql);

    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
if(result.data.data.length!==0){
setClassResult(result.data.data)
}else{
    Alerts('Message!', 'info', 'Is either there is no family define for this account or no  family due found')
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}

const handleSelect = (option, action)=>{
    setStudentFee({...studentFee, [action.name]: option});
    fetchResult(option.value)
 }



  const getTotal =(code)=>{

    var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
    var option = classResult.filter(item=>item.code===code)

for(var i = 0; i < option.length; i++)
{
sumTotal = sumTotal + Number(option[i].total);
sumPaid = sumPaid + Number(option[i].paid);
sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
}

return {sumTotal:FormatNumber(sumTotal), sumPaid:FormatNumber(sumPaid), sumBalance:FormatNumber(sumBalance)}
  }

    
  
  
  useEffect(()=>{ 
    fetchFeecharge()
    fetchPayment()
},[]);



   return ( <>
			
			{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Family Fees">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Family Fees</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Family Fees</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Fee Charge </label>
        <Select  options={feecharge&&feecharge.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.feesCharge, session:list.sessionCode }
                                     })
                                } 
                                getOptionLabel={option =>`${option.session}  ${option.label}`}       
        onChange={handleSelect} name="feecharge" value={studentFee.feecharge}  /> 
                    </div>
			</section>


           
									</div>
										
							</div>
							</div>

							</div>
                </div>



{classResult.length!==0?
<div className="card z-depth-0" >		<div  className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={'schoolName'}  />
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Payment Details</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>Family Name: <b>{payment&&payment.map(data=>data.familyName)}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>Fees</th>
                <th>Admission Number</th>
                <th>Student Name</th>
                <th> Mobile</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                
                </tr>
            </thead>
            <tbody >

                    {classResult&&classResult.map((dat, id)=><tr key={id}>
                    <td>{dat.fees}</td>
                       
                        <td>{dat.admissionNumber}</td>
					<td>{dat.studentName}</td>
                    <td>{dat.mobile}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>)}
				</tbody>
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="4"><b>Total</b> </td>
                    <td ><b > {getTotal(familyCode).sumTotal}</b></td>
                    <td ><b >{getTotal(familyCode).sumPaid}</b></td>
                    <td ><b >{getTotal(familyCode).sumBalance}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/family_fees.php?token='+userToken} target='_blank'>
                <input type='hidden' name='classResult' value ={JSON.stringify(classResult, null, 2)} />

                <input type='hidden' name='familyName' value ={payment.map(data=>data.familyName)} />
                <input type='hidden' name='familyCode' value ={familyCode} />
                <input type='hidden' name='jwtToken' value ={Token} />

                <input type='hidden' name='sumTotal' value ={getTotal(familyCode).sumTotal} />
                <input type='hidden' name='sumPaid' value ={getTotal(familyCode).sumPaid} />
                <input type='hidden' name='sumBalance' value ={getTotal(familyCode).sumBalance} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




  
{classResult.length!==0?
<div className="card z-depth-0">
	{payment.length!==0?	<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={'schoolName'}  />
 </td>     </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Fees Summary</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">
<table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>Family Name</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                </tr>
            </thead>
            <tbody >
					{payment.length!==0?payment.map((dat, id)=>{
					return	<tr key={id}>
					<td>{dat.familyName}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>
					}):[]}
				</tbody>
                <tfoot>
                <tr>
                    <td className="text-right"><b>Total</b> </td>
                    <td ><b >{studentFee.sumTotal}</b></td>
                    <td ><b >{studentFee.sumPaid}</b></td>
                    <td ><b >{studentFee.sumBalance}</b></td>
                </tr>
                </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/family_fees_summary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(payment, null, 2)} />
                <input type='hidden' name='sumTotal' value ={studentFee.sumTotal} />
                <input type='hidden' name='sumPaid' value ={studentFee.sumPaid} />
                <input type='hidden' name='sumBalance' value ={studentFee.sumBalance} />
                <input type='hidden' name='jwtToken' value ={Token} />

                
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>:''}
        </div>:''}

          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</> 
 );
        
}

export default React.memo(FamilyFees) 