import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {config, ImagesUrl, Currency, FormatNumber, userCode, API_URL, PSKLIVE} from '../../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useFlutterwave } from 'flutterwave-react-v3';
import { longDate } from '../component/globalFunction'

import { usePaystackPayment } from 'react-paystack';
const RoomOccupancy =()=> {
    
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [rooms, setRooms]= useState([]) 
const [amountTopay, setAmountTopay]= useState({
    toPay:0,
    paid:0,
    amount:0
}) 
const [student, setStudent] = useState([]);
const [bankPay, setBankPay] = useState(false)

const handlePaystackFees =(toPay)=>{
	var amount = 0
	var amountToCharge = (0.015 * Number(toPay))
if(Number(toPay) <2500){

	amount =  (Number(toPay)+ (amountToCharge)) * 100

}else if(amountToCharge>2000){
		amount = (Number(toPay)+ (2000)) * 100
	}else{
		
	amount =  (Number(toPay)+ (amountToCharge+100)) * 100
	}
	return amount;
}





const fetchStudent =()=>{
    var sql ="SELECT code, gender, admissionNumber, registerNumber, studentName, email, mobile from tbl_students where  code ='"+userCode+"'"
    const fd = new FormData();
fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
axios.post(url, fd, config).then(result=>{
	if(Array.isArray(result.data.data)){

		setStudent(result.data.data[0])
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const fetchRoom =()=>{
    setNotice({...notice,  isDataFetching: true })
    let sql = "Select a.id, a.code, h.hostelName, r.roomName,  r.roomRate, s.studentName, s.admissionNumber, a.currentSession, a.amountPaid, a.allocationDate, a.vacateDate, a.status, a.isApproved from tbl_hostel_allocation a, tbl_students s, tbl_hostel_rooms r, tbl_hostel h where h.code = a.hostelCode and  a.roomCode = r.code and  s.code = a.studentCode and   s.code =  '"+userCode+"' order by a.code"

    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    axios.post(url, fd, config).then(result=>setRooms(result.data.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isDataFetching: false })
    }) 
    }







    const TableRoomRecord=(props)=>{

  
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='id' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }

    
    const tableRoomHeader = [
      

                {dataField: 'id', text: 'Action', editable:false, formatter:(cell, row)=>row.roomRate>row.amountPaid?<button className="btn btn-success btn-sm"  onClick={()=>handlePayment(row)}>
                    <i className="fa fa-credit-card"></i> Pay Fees
                </button>
                :
                <button className="btn btn-info btn-sm">
                    <i className="fa fa-print"></i> Print
                </button>},
                  {dataField: 'isApproved', text: 'status', editable:false,  formatter: (cell, row)=>row.isApproved==='true'? <span className="pcoded-badge label label-success">Approved</span>: <span className="pcoded-badge label label-danger">Pending</span>},
        {dataField: 'currentSession', text: 'Session', editable:false},
                {dataField: 'roomName', text: 'Room', editable:false, formatter:(cell,row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.hostelName+" =>"+row.roomName}</div> },   
                {dataField: 'studentName', text: 'Student', editable:false, formatter:(cell,row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.admissionNumber+" -"+row.studentName}</div>}, 
            {dataField: 'allocationDate', text: 'Allocation Date', editable:false, formatter:(cell)=>longDate(cell)},  
                {dataField: 'vacateDate', text: 'Vacation Date', editable:false, formatter:(cell)=>longDate(cell)},
                {dataField: 'roomRate', text: 'Room Rate', editable:false, formatter:(cell)=>Currency+FormatNumber(cell)},
                {dataField: 'amountPaid', text: 'Amount Paid', editable:false, formatter:(cell)=>Currency+FormatNumber(cell)}
                
         ]


         var receiptNo = Math.random().toString(36).substring(2,9).toUpperCase();

         
         const handlePaymentReport=(result)=>{
			
                    const fd = new FormData();
                   
                    var totalPaid = Number(amountTopay.paid) + Number(amountTopay.amount)
                    fd.append('tx_ref', result.tx_ref);
                    fd.append('platform', 'Paystack');
			fd.append('allocationCode', amountTopay.allocationCode);
			fd.append('amountPaid', totalPaid);
            fd.append('amount', result.amount);
                    fd.append('remark', 'Hostel Fees Payment');
                    fd.append('customer', JSON.stringify(result.customer, null,2));
                         let url = API_URL+'/hostel/allocation/payment'
                        axios.post(url, fd, config)
                        .then(response =>{
                             if (response.data.status ===200) {
                                Alerts('Saved!', 'success', response.data.message)
                            }  else{
                                Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                                
                        })
                        .catch((error)=>{
                            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
                        }).finally(()=>{
                            fetchRoom();
                        })
        }


        

const proceedPayment =()=>{

      /*   handleFlutterPayment({
            callback: (response) => {
              var transaction_id = response.transaction_id; 
              var txref = response.tx_ref; 
             var amount = response.amount
             handlePaymentReport(response)
             if (response.status == "successful" ){
             submitPayment(amount)
            } 
              
            },
            onClose: () => {
    
              closePaymentModal(); 
            },
    
          }) */

        }

const handlePayment =(row)=>{
    var toPay =  Number(row.roomRate) - Number(row.amountPaid) 
    var payamount = handlePaystackFees(toPay);
    setAmountTopay({toPay:payamount, paid:row.amountPaid, allocationCode:row.code, amount:toPay})
document.getElementById('openpaymentModal').click()

}

//redirect_url: window.location.href,
//LIVE API FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X
//TEST API  FLWPUBK_TEST-ba1f52064fac16ac5f429566759815b2-X
const flutterConfig = {
    public_key: 'FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X',
    tx_ref: receiptNo,
    amount: Number(amountTopay.toPay),
    currency: "NGN",
    country: "NG",
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: student.email,
      phonenumber: student.mobile,
      name: student.studentName,
    },
	
    customizations: {
      title: 'schoolName',
      description: 'Hostel Room Payment',
      logo: ImagesUrl+'/logo.png',
    },
  };


  const handleFlutterPayment = useFlutterwave(flutterConfig);
    
const paystackConfig = {
    reference: receiptNo,
    email: student.fatherEmail!==''?student.fatherEmai:student.email,
    amount: Number(amountTopay.toPay),
    publicKey: PSKLIVE 
    };
    

    const onSuccess = (ref) => {
        handlePaymentReport(ref)
    };
    
    const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    //console.log('closed')
    }
    
    const handlePaystackPayment = usePaystackPayment(paystackConfig);
useEffect(()=>{
    fetchRoom()
    fetchStudent()
},[]);


        return (  
            <React.Fragment>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Room Occupancy Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Rooms Occupancy</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              


                <a href="#!" data-toggle="modal" id='openpaymentModal' data-target='#paymentModal' ></a>
                <div className="modal fade " id='paymentModal' data-keyboard="false" tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">Pay Hostel Fees</h4>        
                    
        </div> 

              <div className="modal-body">
                  <div className="row">
                  <div className="col-md-12">
                  
{bankPay?
<div>Please pay to the following Account Details:<br/>
<b>Bank Name:</b>ABC D<br/>
<b>Account Number:</b>123456<br/>

 </div>:''}
 <br/>
               <button className="btn btn-success " onClick={() =>  handlePaystackPayment(onSuccess, onClose)
        }> <i className="fa fa-credit-card"></i> Pay Online</button> &nbsp;&nbsp;
               <button className="btn btn-dark " onClick={()=>setBankPay(!bankPay)} > <i className="fa fa-bank"></i> Pay in Bank</button><br/>
              
 </div></div>
              </div>
          </div>
      </div>
  </div>





                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


                           



                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Rooms History</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRoomRecord data={rooms}  columns={tableRoomHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</React.Fragment>
 );
        
}

export default React.memo(RoomOccupancy) 