import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {API_URL, config} from '../../component/include'
import { getTime} from '../component/globalFunction'



import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

const HolidaysList =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    const [allHolidays, setAllHolidays]= useState([])
    
    const fetchAllHolidays =()=>{

      const fd = new FormData();
        let sql = "Select startDate as date, eventType as event, color as colors, title as comment,  startTime as begin, endTime as stop from tbl_events_holidays "

    let url = API_URL+'/exam/result/fetch_raw'
          
            fd.append("sql", sql)
           
            axios.post(url, fd, config).then(result =>setAllHolidays(result.data.data)) 
    }

    

      function renderEventContent(eventInfo){ 
        var data = eventInfo.event.extendedProps 
        return  <div style={{backgroundColor:data.colors}} className="attachment-block text-center">  
        <b className="text-green">{data.event}</b><br/>
        <b className="text-green" style={{display:'block',  width:150, whiteSpace:'normal', wordWrap:'break-word' }}>{data.comment}</b>
    <br/>
    <strong className="text-green">{getTime(data.begin)+ ' ~ '+getTime(data.stop)} </strong>
    <br/>
        
          
            
            <br/>
        </div>
    }


useEffect(()=>{
    fetchAllHolidays();
},[]);




        return (  
            <React.Fragment>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="School Holiday">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Holidays List</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

<div className="row">
        <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
             <div className="card-body">
             <FullCalendar
             events={allHolidays}          
             eventContent={renderEventContent}
            headerToolbar = { {
            left: "prev, today",
            center: "title",
            right: "next"
          }}
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
             </div>
          </div>
        </div>
      </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</React.Fragment>
 );
        
}

export default React.memo(HolidaysList) 