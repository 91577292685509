import React, {useState, useEffect} from 'react'

import {API_URL, ImagesUrl, ServerUrl,  Token, config, familyCode} from '../component/include'
import axios from 'axios'
import { Alerts } from '../component/notify'
import { getBritishDate } from '../component/globalFunction'
import { TableLoader } from '../component/loader'

const Review=(props)=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    })

	let [limit, setLimit] = useState(16);
    const [content, setContent]= useState([])   
    const [message, setMessage]= useState({
        fullname:'',
        email:'',
        rating:'',
        pros:'',
        con:'',
        reviewText:''
       
    })

    const [statistics, setStatistics]= useState({
        five:0,
        four:0,
        three:0,
        two:0,
        one:0,
        fivep:0,
        fourp:0,
        threep:0,
        twop:0,
        onep:0,
        overal:0
       
    })

    const [errors, setErrors] = useState({
        fullname:'',
        email:'',
        rating:'',
        reviewText:''
     })


     const nextPage = (num)=>{
		setLimit(num)
		fetchReview(num)
	  }

    const handleChange =(event)=>{
        let {name, value} = event.target;
        setMessage({...message, [name] : value });
       setErrors({...errors, [name]:''});
    } 



    const handleSubmit = event =>{
        event.preventDefault(); 
        
        let errors = {};   
        let email = message.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        

        if(message.reviewText.length===0){
            errors.reviewText ='Please enter a review';
        } 
    
        if(message.reviewText.length<50){
            errors.reviewText ='Please enter minimum of 50 character review';
        }

        if(message.fullname.length===0){
            errors.fullname ='Please enter your name';
        } 
    
        if(!email){
            errors.email ='Please enter valid email address';
        }


    
        if(message.rating.length===0){
            errors.rating='Please select rating';
        }
    
    
    
        setErrors(errors)
    

        if(Object.keys(errors).length === 0){
           
       setNotice({...notice,  isLoading: true}) 

       let code =Math.random().toString(36).substring(2,9);

            const fd = new FormData(); 
            fd.append('code', code)
        fd.append('customerId', familyCode)
          fd.append('fullname', message.fullname)
          fd.append('email', message.email)
          fd.append('rating', message.rating)
          fd.append('pros', message.pros)
          fd.append('con', message.con)
          fd.append('productCode', props.code)
          fd.append('reviewText', message.reviewText)
          fd.append('vendor', 'vendor')
          fd.append('jwt', Token)
    
             let url = ServerUrl+'/save_controller/tbl_product_review';
            axios.post(url, fd, config)
            .then(response =>{
              if (response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                  setErrors({...errors, successMessage: 'Your review successfully submitted '})
    
                  setMessage({  
                    fullname:'',
                  email:'',
                  rating:'',
                  pros:'',
                  con:'',
                  reviewText:''
                 })
              } else{
                  setErrors({...errors, errorMessage: JSON.stringify(response.data)})
          }  
          })
          .catch((error)=>{
              setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
          })
          .finally(()=>{
            setNotice({...notice, isLoading: false}) 
            window.scrollTo(0,0)
            fetchReview(limit)
          })  
    
            }
    
    } 


    const  fetchContent =()=>{

        var sql ="SELECT  sum(case when rating ='5'  then 1 else 0 end) as five, sum(case when rating ='4'  then 1 else 0 end) as four, sum(case when rating ='3'  then 1 else 0 end) as three, sum(case when rating ='2'  then 1 else 0 end) as two, sum(case when rating ='1'  then 1 else 0 end) as one from tbl_product_review where productId  ='"+props.code+"' "
         
        const fd = new FormData();
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{ 

            if(result.data.data.length!==0){
               
                let one = result.data.data[0].one
                let two = result.data.data[0].two
                let three = result.data.data[0].three
                let four = result.data.data[0].four
                let five = result.data.data[0].five
                let total = Number(five) + Number(four) + Number(three) + Number(two) + Number(one)
               setStatistics({
                five:five,
                four:four,
                three:three,
                two:two,
                one:one,

                fivep:parseFloat((five/total)*100),
                fourp:parseFloat((four/total)*100),
                threep:parseFloat((three/total)*100),
                twop:parseFloat((two/total)*100),
                onep:parseFloat((one/total)*100),

                overal:(total/5)
            })
            }else{
                 setStatistics({
                    five:0,
                    four:0,
                    three:0,
                    two:0,
                    one:0,
                    overal:0
            })
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }



    const  fetchReview =(limit)=>{

        setNotice({...notice, isDataFetching:true})
        var sql ="Select  code, productId, vendor, customerId, title, reviewText, replyText, rating, isApproved, staffApprove, dateCreate, status, pros, cons, upvote, downvote,fullname, email	 from tbl_product_review where productId  ='"+props.code+"' order by ID DESC LIMIT "+limit
         
        const fd = new FormData();
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{ 

            if(result.data.data.length!==0){
               setContent(result.data.data)
            }else{
                setContent([])
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isDataFetching:false})
        })
    }



    
useEffect(()=>{
    fetchContent()
    fetchReview(limit)
},[])

  return (

<div className="border-top border-bottom my-lg-3 py-5">
      <div className="container pt-md-2" id="reviews">

      {errors.errorMessage?
<div className="alert alert-danger background-danger">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.errorMessage}</strong> 
  </div>:''}


  {errors.successMessage?
<div className="alert alert-success background-success">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.successMessage}</strong> 
  </div>:''}


        <div className="row pb-3">
          <div className="col-lg-4 col-md-5">

            <h2 className="h3 mb-4">{content.length} Reviews</h2>
            <div className="star-rating mr-2"><i className="czi-star-filled font-size-sm text-accent mr-1"></i><i className="czi-star-filled font-size-sm text-accent mr-1"></i><i className="czi-star-filled font-size-sm text-accent mr-1"></i><i className="czi-star-filled font-size-sm text-accent mr-1"></i><i className="czi-star font-size-sm text-muted mr-1"></i></div>
            
            <span className="d-inline-block align-middle">{statistics.overal} Overall rating</span>
            <p className="pt-3 font-size-sm text-muted">58 out of 74 (77%)<br />Customers recommended this product</p>
          </div>


          <div className="col-lg-8 col-md-7">
            
            <div className="d-flex align-items-center mb-2">
              <div className="text-nowrap mr-3"><span className="d-inline-block align-middle text-muted">5</span><i className="czi-star-filled font-size-xs ml-1"></i></div>
              <div className="w-100">
                <div className="progress" style={{height: '4px'}}>
                  <div className="progress-bar bg-success" role="progressbar"  aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: `${statistics.fivep+'%'}`, backgroundColor:'#a7e453'}}></div>
                </div>
              </div><span className="text-muted ml-3">{statistics.five}</span>
            </div>


            <div className="d-flex align-items-center mb-2">
              <div className="text-nowrap mr-3"><span className="d-inline-block align-middle text-muted">4</span><i className="czi-star-filled font-size-xs ml-1"></i></div>
              <div className="w-100">
                <div className="progress" style={{height: '4px'}}>
                  <div className="progress-bar" role="progressbar" style={{width: `${statistics.fourp+'%'}`, backgroundColor:'#a7e453'}} aria-valuenow="27" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span className="text-muted ml-3">{statistics.four}</span>
            </div>


            <div className="d-flex align-items-center mb-2">
              <div className="text-nowrap mr-3">
                <span className="d-inline-block align-middle text-muted">3</span>
                <i className="czi-star-filled font-size-xs ml-1"></i>
                </div>
              <div className="w-100">
                <div className="progress" style={{height: '4px'}}>
                  <div className="progress-bar" role="progressbar" style={{width: `${statistics.threep+'%'}`, backgroundColor:'#ffda75'}} aria-valuenow="17" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span className="text-muted ml-3">{statistics.three}</span>
            </div>


            <div className="d-flex align-items-center mb-2">
              <div className="text-nowrap mr-3">
                <span className="d-inline-block align-middle text-muted">2</span>
                <i className="czi-star-filled font-size-xs ml-1"></i>
                </div>
              <div className="w-100">
                <div className="progress" style={{height: '4px'}}>
                  <div className="progress-bar" role="progressbar"  style={{width: `${statistics.twop+'%'}`, backgroundColor:'#fea569'}} aria-valuenow="9" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span className="text-muted ml-3">{statistics.two}</span>
            </div>


            <div className="d-flex align-items-center">
              <div className="text-nowrap mr-3"><span className="d-inline-block align-middle text-muted">1</span><i className="czi-star-filled font-size-xs ml-1"></i></div>
              <div className="w-100">
                <div className="progress" style={{height: '4px'}}>
                  <div className="progress-bar bg-danger" role="progressbar"  style={{width: `${statistics.onep+'%'}`}} aria-valuenow="4" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div><span className="text-muted ml-3">{statistics.one}</span>
            </div>


          </div>




        </div>
        <hr className="mt-4 pb-4 mb-3" />
        <div className="row">
          <div className="col-md-7">
            <div className="d-flex justify-content-end pb-4">
              <div className="form-inline flex-nowrap">
                <label className="text-muted text-nowrap mr-2 d-none d-sm-block" htmlFor="sort-reviews">Sort by:</label>
                <select className="custom-select custom-select-sm" id="sort-reviews">
                  <option>Newest</option>
                  <option>Oldest</option>
                  <option>Popular</option>
                  <option>High rating</option>
                  <option>Low rating</option>
                </select>
              </div>
            </div>




           {notice.isDataFetching ?<TableLoader /> : "" }


{content&&content.map((item, index)=>
            <div className="product-review pb-4 mb-4 border-bottom" key={index}>
              <div className="d-flex mb-3">
                <div className="media media-ie-fix align-items-center mr-4 pr-2">

                  <img className="rounded-circle" width="50" src={ImagesUrl+'/logo.png'} alt="Rafael Marquez" />

                  <div className="media-body pl-3">
                    <h6 className="font-size-sm mb-0">{item.fullname}</h6><span className="font-size-ms text-muted">{getBritishDate(item.dateCreate)}</span>
                  </div>
                </div>
                <div>
                  <div className="star-rating">
                    <i className="sr-star czi-star-filled active"></i><i className="sr-star czi-star-filled active"></i><i className="sr-star czi-star-filled active"></i><i className="sr-star czi-star-filled active"></i><i className="sr-star czi-star"></i>
                  </div>
                  <div className="font-size-ms text-muted">83% of users found this review helpful</div>
                </div>
              </div>
              <p className="font-size-md mb-2">{item.reviewText}</p>

              <ul className="list-unstyled font-size-ms pt-1">
                <li className="mb-1"><span className="font-weight-medium">Pros:&nbsp;</span>{item.pros}</li>
                <li className="mb-1"><span className="font-weight-medium">Cons:&nbsp;</span>{item.cons}</li>
              </ul>
              <div className="text-nowrap">
                <button className="btn btn-sm btn-success" type="button"><i className="fa fa-thumbs-o-up"></i>  {item.upvote}</button>
                <button className="btn btn-sm btn-danger m-l-10" type="button"><i className="fa fa-thumbs-o-down"></i> {item.upvote}</button>
              </div>
            </div>)}







            <div className="text-center">
              <button className='btn btn-inverse  ' onClick={()=>nextPage(limit)} type="button"><i className="fa fa-load mr-2"></i>Load more reviews</button>
            </div>
          </div>









{/* write a review here 
 */}




          <div className="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
            <div className="border-inverse py-grid-gutter px-grid-gutter rounded-lg p-10">
              <h3 className="h4 pb-2">Write a review</h3>

              <form >

                <div className="form-group">
                  <label htmlFor="review-name">Your name<span className="text-danger">*</span></label>


                  <input type="text" className={errors.fullname ? 'form-control form-control-danger' : 'form-control'}  name="fullname" value={message.fullname} onChange={handleChange} />
                          <span style={{color:'red'}} className="invalid-feedback">{errors.fullname}</span>

                          <small className="form-text text-muted">Will be displayed on the comment.</small>
                </div>


                <div className="form-group">
                  <label htmlFor="review-email">Your email<span className="text-danger">*</span></label>

                  <input type="email" className={errors.email ? 'form-control form-control-danger' : 'form-control'} name="email"  value={message.email}  onChange={handleChange} />
                                  <span style={{color:'red'}}>{errors.email}</span>
                  
                  <small className="form-text text-muted">Authentication only - we won't spam you.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="review-rating">Rating<span className="text-danger">*</span></label>
                 
                  <select  name="rating" value={message.rating} className={errors.rating ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange}>
                    <option value="">Choose rating</option>
                    <option value="5">5 stars</option>
                    <option value="4">4 stars</option>
                    <option value="3">3 stars</option>
                    <option value="2">2 stars</option>
                    <option value="1">1 star</option>
                  </select>
                  <span style={{color:'red'}}>{errors.rating}</span>
                </div>



                <div className="form-group">
                  <label htmlFor="review-text">Review<span className="text-danger">*</span></label>


                  <textarea className={errors.reviewText ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange} rows="6" name="reviewText" value={message.reviewText}>

                  </textarea>


                  
                  <span style={{color:'red'}}>{errors.message}</span>
                  
                  <small className="form-text text-muted">Your review must be at least 50 characters.</small>
                </div>
                <div className="form-group">
                  <label htmlFor="review-pros">Pros</label>
                  <textarea className="form-control" rows="2" value={message.pros}  onChange={handleChange} placeholder="Separated by commas" id="review-pros" name='pros'></textarea>
                </div>


                <div className="form-group mb-4">
                  <label htmlFor="review-cons">Cons</label>
                  <textarea className="form-control" rows="2" placeholder="Separated by commas" id="review-cons" value={message.con} name='con'  onChange={handleChange}></textarea>
                </div>


                {notice.isLoading?
          
          <button disabled className="btn btn-primary btn-shadow btn-block " type="button"><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Please wait...</button>:
					<button type="button" onClick={handleSubmit} className="btn btn-primary btn-shadow btn-block">Submit a Review</button>}


              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Review