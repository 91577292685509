
import React, {useEffect, useState} from 'react';
import { config,ImagesUrl, Token,   ServerUrl, Currency, FormatNumber, API_URL } from '../component/include';

import axios from 'axios';
import { Alerts} from '../component/notify'

const DetailsOrder =(props)=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });

    const [product, setProduct] = useState([]);
    
      const fetchProduct =()=>{
           
        var sql ="Select o.ID, p.productName, p.coverUrl, o.attributes, o.files, o.quantityPrice, o.productCode, p.isPrinting, p.bulkPrices, o.quantity, o.total, o.attributes, p.price, o.totalAttributePrice, o.graphicFee, o.shippingFee, o.displayOrder from tbl_product_orders o, tbl_products p where o.productCode = p.code and o.orderId = '"+ props.orderId+"' order by o.ID DESC" ;
            
        const fd = new FormData();
        fd.append("sql", sql);
        
    let url = API_URL+'/exam/result/fetch_raw'
        axios.post(url, fd, config).then(result=>{
            if(result.data.data.length!==0){
            setProduct(result.data.data)
            }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

    useEffect(()=>{
        fetchProduct()
       }, []);
    return (
        <div className="table-responsive">
        <table className="table table-hover">
            <tbody>
            {product.length!==0? product.map((item, index)=>
                <tr key={index}>
                <td width="65">
                    <img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+item.coverUrl} alt={item.productName} className="img-fluid img-100"  />
                </td>
                <td> 
                    <p className="title mb-0">{item.productName} </p>
                    <var className="price text-muted">{Currency+FormatNumber(item.total)}</var>
                </td>

                <td> {item.attributes.length!==0?JSON.parse(item.attributes).map(data=>
                <div key={data.attributeCode} className="font-size-sm"><span className="text-muted mr-2">{data.attributeName}:</span>{data.optionName}</div>
            ):[]}
            
            </td>
            <td> 
                    <div className="title mb-0">Quantity: &nbsp; 
                    <span className=" text-muted">{item.quantity}</span></div>
                </td>

                <td width="250"> 
                <a href={"/tuckshop/order/tracking/"+props.orderId} className="btn btn-outline-primary">Track Item</a> &nbsp;

                    <div className="dropdown d-inline-block">
                         <a href="#" data-toggle="dropdown" className="dropdown-toggle btn btn-outline-secondary" aria-expanded="false">More</a>

                         <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(76px, 38px, 0px)'}}>

                             <a href="#" className="dropdown-item">Return</a>
                             <a href="#" className="dropdown-item">Cancel order</a>

                         </div>
                    </div> 
                </td>
            </tr>):[]}

        </tbody></table>
        </div> )
}


export default React.memo(DetailsOrder) 
