import React, {useEffect, useState} from 'react';
import { config,  ServerUrl, Currency, FormatNumber, familyCode, ImagesUrl, Token, API_URL } from '../component/include';


import PageHeader from '../component/pageheader'
import axios from 'axios';
import { Alerts} from '../component/notify'
import UsePagination from '../component/pageNumber';
import { LoadEffect } from '../component/loader';

const Wishlist =()=>{
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });

    const [content, setContent] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    //const content = props.data;
    let [page, setPage] = useState(1);
    const PER_PAGE = 10;
    
    const count = Math.ceil(content.length / PER_PAGE);


    const newList =	content.map(e=>e['productCode'])
	.map((e,i,final)=>final.indexOf(e)===i&&i)
	.filter(e=>content[e])
	.map(e=>content[e])  


    const dataList = UsePagination(newList, PER_PAGE);
    

    const handleChange = (e, num) => {
      setPage(num);
      dataList.jump(num);
    };
    
    var pages = [...Array(dataList.maxPage).keys()].map(i =>  i+1);
    
    const [product, setProduct] = useState([]);
   
    
      const fetchProduct =()=>{
        var sql ="Select ID, productName, coverUrl, attributes, code, price from  tbl_products where isPublished ='true'" ;
        const fd = new FormData();
        fd.append("sql", sql);
        
    let url = API_URL+'/exam/result/fetch_raw'
        axios.post(url, fd, config).then(result=>setProduct(result.data.data))
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }
    


    const handleRemoveItem =(code)=>{

       
      setNotice({...notice,   isLoading: true})  


const fd = new FormData();
fd.append('ID', 'code')
      fd.append('data', code)
      fd.append('jwt', Token)
  let url = ServerUrl+'/delete_controller.php?tablename=tbl_whishlist';
        axios.post(url, fd, config)
        .then(response =>{
         if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', response.data)
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isLoading: false
            })

  const newContent = content.filter(item=>item.code!==code)
  setContent(newContent)
        }) 

}



    
    const fetchContent =()=>{      
      var sql ="Select ID, code, productCode from  tbl_whishlist where customerCode ='"+familyCode+"'" ;
      const fd = new FormData();
      fd.append("sql", sql);
      
    let url = API_URL+'/exam/result/fetch_raw'
      axios.post(url, fd, config).then(result=>{  
        if(result.data.data.length!==0){
        setContent(result.data.data)
        }
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      })
    }
    
    const fetchProductByField =(code,  field)=>{
      const result = product.filter(list=>list.code===code);
    const answer = result.map(ans=>ans[field])
      return String(answer)
    }
    useEffect(()=>{
        fetchProduct()
         fetchContent()
           }, []);

           
    return (<>
    
  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Tuckshop - Wishlist">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bar-chart"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Tuckshop</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Wishlist</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
              
<div className="row  ">   <div className="col-sm-12">

<article className="card">
    <div className="card-body">

<div className="row">


{content.length!==0? dataList.currentData().map((item, index)=>  <div key={index} className="col-xl-4 col-md-6 col-sm-6">
            <figure className="itemside mb-4 z-depth-0 p-10 text-center">
                <div className="hvr-shrink"><img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.png"}}  src={ImagesUrl+"/product/"+fetchProductByField(item.productCode, 'coverUrl')} alt={fetchProductByField(item.productCode, 'productName')}  className="img-fluid o-hidden product-img" /></div>
                <figcaption className="info">
                    <a href={'/tuckshop/product/details/'+item.productCode} className="title text-bold mt-10">{fetchProductByField(item.productCode, 'productName')}</a>
                    <p className="price mb-2 text-bold">{Currency+FormatNumber(fetchProductByField(item.productCode, 'price'))}</p>
                    <a href={'/tuckshop/product/details/'+item.productCode} className="btn btn-secondary btn-sm"> Add to cart </a>&nbsp;

                    <a href="#" className="btn btn-danger btn-sm" data-toggle="tooltip" title="" data-original-title="Remove from wishlist" onClick={()=>handleRemoveItem(item.code)}> <i className="fa fa-times"></i> </a>
                </figcaption>
            </figure>
        </div> ):" There are no item on your wishlist "}




    </div>  </div>
    </article>

    {content && content.length!==0?

<nav className="mb-4" aria-label="Page navigation sample">
<ul className="pagination">
<li className={page === 1 ? ' page-item disabled' : 'page-item'}> <a className="page-link" href="#!" onClick={(e) =>handleChange(e, page - 1)}>Previous</a></li>

{pages.map((item, index) =>
    <li key={index} className={page===item?' page-item  active':'page-item '}><a className="page-link" href="#!" onClick={(e) =>handleChange(e, item)}>{item}</a></li>    
         )}

<li className={page === dataList.maxPage  ? ' page-item disabled' : 'page-item'}  ><a className="page-link" href="#!" onClick={(e) =>handleChange(e, page + 1)} aria-label="Next">Next</a></li>  
</ul>

</nav> :''}
    


</div>
</div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>


    </>)
}

export default React.memo(Wishlist) 