import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import ProfileCard from './profileCard'

import axios from 'axios'
import { Alerts, } from '../component/notify'
import {ServerUrl,   Token,  config,  userCode} from '../../component/include'
const AdmissionStatus =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
   
const [content, setContent] = useState([])
const fetchContent =()=>{

    var sql ="select admissionStatus from tbl_admission_request where applicationID ='"+userCode+"'";

    const fd = new FormData();
    fd.append("sql", sql);

    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{ 
        
        if(result.data.length!==0){
        setContent(result.data[0])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    
}

useEffect(()=>{
    fetchContent()
},[]);
    return (  <Template menu='status' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
        <div  className="main-body">
                     <div className="page-wrapper">
                      
                                         <div className="page-body m-t-50">
                                    
                                         <div className="row">

                                        
<div className="col-xl-9 col-md-12">
        <div className="card ">
            <div className="card-block">
                   
                   <div className="row">

                   
                <section className="col-md-12 text-center">
<i className="fa fa-exclamation-circle fa-3x" style={{color:'blueviolet'}}></i>
						<h4 >Hi, <br/> <br/>Your admission status is <strong>{content.admissionStatus}.</strong> </h4>

										</section>
                                       

                                        </div>
            </div>
        </div>
        </div>

  <ProfileCard />


                                    </div>


                                   

                    <br/>
                                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(AdmissionStatus) 