import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, familyCode} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { longDate } from '../component/globalFunction'

import { useSelector } from 'react-redux'
const RoomOccupancy =()=> {
    
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})



const [paymentGateway, setPaymentGateway] = useState([]);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
const [rooms, setRooms]= useState([]) 
const [amountTopay, setAmountTopay]= useState({
    toPay:0,
    paid:0
}) 
const [student, setStudent] = useState([]);
const [bankPay, setBankPay] = useState(false)


const fetchPaymentGateway =()=>{
    var sql ="Select ID, title, details from tbl_payment_gateway where status ='true'" ;
    const fd = new FormData();
    fd.append("sql", sql);
    
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller.php';
    axios.post(url, fd, config).then(result=>{  
      setPaymentGateway(result.data)
    })
    
    }


const fetchStudent =()=>{
    var sql ="SELECT code, gender, admissionNumber, registerNumber, studentName, email, mobile from tbl_students where  family_name ='"+familyCode+"'"
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	if(Array.isArray(result.data)){

		setStudent(result.data[0])
	}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const fetchRoom =()=>{
    setNotice({...notice,  isDataFetching: true })
    let sql = "Select a.ID, a.code, h.hostelName, r.roomName,  r.roomRate, s.studentName, s.admissionNumber, a.currentSession, a.amountPaid, a.allocationDate, a.vacateDate, a.status, a.isApproved from tbl_hostel_allocation a, tbl_students s, tbl_hostel_rooms r, tbl_hostel h where h.code = a.hostelCode and  a.roomCode = r.code and  s.code = a.studentCode and   s.family_name =  '"+familyCode+"' order by a.code"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setRooms(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isDataFetching: false })
    }) 
    }







    const TableRoomRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_allocation'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
    
     {/*  <div className="dt-buttons btn-group pull-right"> 
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div>  */}


        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }

    
    const tableRoomHeader = [
      

                {dataField: 'ID', text: 'Action', editable:false, formatter:(cell, row)=>row.roomRate>row.amountPaid?<button className="btn btn-success btn-sm"  onClick={()=>handlePayment(row)}>
                    <i className="fa fa-credit-card"></i> Pay Fees
                </button>
                :
                <button className="btn btn-info btn-sm">
                    <i className="fa fa-print"></i> Print
                </button>},
                  {dataField: 'isApproved', text: 'status', editable:false,  formatter: (cell, row)=>row.isApproved==='true'? <span className="pcoded-badge label label-success">Approved</span>: <span className="pcoded-badge label label-danger">Pending</span>},
        {dataField: 'currentSession', text: 'Session', editable:false},
                {dataField: 'roomName', text: 'Room', editable:false, formatter:(cell,row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.hostelName+" =>"+row.roomName}</div> },   
                {dataField: 'studentName', text: 'Student', editable:false, formatter:(cell,row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.admissionNumber+" -"+row.studentName}</div>}, 
            {dataField: 'allocationDate', text: 'Allocation Date', editable:false, formatter:(cell)=>longDate(cell)},  
                {dataField: 'vacateDate', text: 'Vacation Date', editable:false, formatter:(cell)=>longDate(cell)},
                {dataField: 'roomRate', text: 'Room Rate', editable:false, formatter:(cell)=>Currency+FormatNumber(cell)},
                {dataField: 'amountPaid', text: 'Amount Paid', editable:false, formatter:(cell)=>Currency+FormatNumber(cell)}
                
         ]


         var receiptNo = Math.random().toString(36).substr(2,9).toUpperCase();

         const handlePaymentReport=(result)=>{
			
            let formData = result; 
                    const fd = new FormData();
                    fd.append('jwt', Token);
                    for(let k in formData){
                        fd.append(k,formData[k]);
                    };  
        
                    
                    fd.append('remark', 'Hostel Fees Payment');
                    fd.append('customers', JSON.stringify(result.customer, null,2));
                    fd.append('code', Math.random().toString(36).substr(2,9));
                         let url = ServerUrl+'/save_controller/tbl_online_payment_report'
                        axios.post(url, fd, config)
                        .then(response =>{
                             if (response.data.type ==='success') {
                                //Alerts('Saved!', 'success', response.data.message)
                            }  else{
                                Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                                
                        })
                        .catch((error)=>{
                            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
                        })
        }


const proceedPayment =()=>{

        handleFlutterPayment({
            callback: (response) => {
              var transaction_id = response.transaction_id; 
              var txref = response.tx_ref; 
             var amount = response.amount
             handlePaymentReport(response)
             if (response.status == "successful" ){
             submitPayment(amount)
            } 
              
            },
            onClose: () => {
    
              closePaymentModal(); 
            },
    
          })

        }

const handlePayment =(row)=>{
    var toPay =  Number(row.roomRate) - Number(row.amountPaid) 
    setAmountTopay({toPay:toPay, paid:row.amountPaid, allocationCode:row.code})
document.getElementById('openpaymentModal').click()

}

        function submitPayment(amount){  
            var totalPaid = Number(amountTopay.paid) + Number(amount)
			const fd = new FormData();
			fd.append("jwt", Token);
			
			fd.append('allocationCode', amountTopay.allocationCode);
			fd.append('amountPaid', totalPaid);

			setNotice({...notice,  isLoading: true}) 
		
				 let url = ServerUrl+'/update_controller/tbl_hostel_allocation_payment'
				axios.post(url, fd, config)
				.then(response =>{
		 			if (response.data.type ==='success') {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}   
						
				})
				.catch((error)=>{
					Alerts('Error!', 'danger', JSON.stringify(error.message)) 
				})
				.finally(()=>{
					fetchRoom()
				  setNotice({...notice, isLoading: false})  
				})   
				  
		}
//redirect_url: window.location.href,
//LIVE API FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X
//TEST API  FLWPUBK_TEST-ba1f52064fac16ac5f429566759815b2-X
const flutterConfig = {
    public_key: 'FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X',
    tx_ref: receiptNo,
    amount: Number(amountTopay.toPay),
    currency: "NGN",
    country: "NG",
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: student.email,
      phonenumber: student.mobile,
      name: student.studentName,
    },
	
    customizations: {
      title: schoolInfo.schoolName,
      description: 'Hostel Room Payment',
      logo: ImagesUrl+'/logo.png',
    },
  };


  const handleFlutterPayment = useFlutterwave(flutterConfig);
    
useEffect(()=>{
    fetchRoom()
    fetchPaymentGateway()
    fetchStudent()
},[]);


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Room Occupancy Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Rooms Occupancy</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              


                <a href="#!" data-toggle="modal" id='openpaymentModal' data-target='#paymentModal' ></a>
                <div className="modal fade " id='paymentModal' data-keyboard="false" tabIndex="-1" role="dialog">
      <div className="modal-dialog " role="document">
          <div className="modal-content ">
              
        <div className="modal-header">                                       
          <h4 className="modal-title">Pay Hostel Fees</h4>        
                    
        </div> 

              <div className="modal-body">
                  <div className="row">
                  <div className="col-md-12">
                  
{bankPay?

paymentGateway.filter(item=>item.title ==='Bank').map(data=><div key={data.ID}>

<p className="font-weight-medium">Kindly pay into the following account details:  </p>

    <div dangerouslySetInnerHTML={{__html: data.details}} ></div>
   </div>)
    :''}
 <br/>
               <button className="btn btn-success " onClick={proceedPayment}> <i className="fa fa-credit-card"></i> Pay Online</button> &nbsp;&nbsp;
               <button className="btn btn-dark " onClick={()=>setBankPay(!bankPay)} > <i className="fa fa-bank"></i> Pay in Bank</button><br/>
              
 </div></div>
              </div>
          </div>
      </div>
  </div>





                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


                           



                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Rooms History</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRoomRecord data={rooms}  columns={tableRoomHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(RoomOccupancy) 