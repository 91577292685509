import React, {useEffect, useState} from 'react';
import {Alerts} from './component/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ViewTimetable from './timetable/view_timetable'
import LessonPlan from './timetable/lesson_plan'
import Holidays from './timetable/holidays_list'


import eLibrary from './library/e-library'
import BookReturn from './library/issues_return'
import Books from './library/books'



import StudentAttendance from './attendance/display_student_attendance'
import StudentArrival from './attendance/student_arrival_departure_late'

import MockResult from './exam/mock_result'
import ExamSchedule from './exam/exam_schedule'
import ExamResult from './exam/exam_result'
import Transcript from './exam/student_transcript'


import FamilyFees from './fees/family_fees'
import FeesPayment from './fees/fees_payment'
import Dashboard from './dashboard/dashboard'

import Profile from './student/profile'
import Noticeboard from './student/noticeboard'
import Review from './student/review'
import ChangePassword from './student/change_password'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config,  familyCode, classID, sectionID, studentName, Token,  ServerUrl, studentCode} from './component/include'
import {encrypt, decrypt} from './component/authentication'
import hostel from './hostel/hostel';
import room_occupant from './hostel/room_occupant';
import midTerm_result from './exam/mid-term_result';
import tickets from './tickets/tickets';
import submitTicket from './tickets/submitTicket';
import readTicket from './tickets/readTicket';
import Documents from './exam/documents';


const ProtectedView =()=>{



const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

  let closeWarning =   document.getElementById('btnLogoutsession')
  closeWarning.click();
           
const fd = new FormData();

fd.append('code', studentCode)
  fd.append('studentName', studentName)
  fd.append('classID', classID)
  fd.append('sectionID', sectionID)
  fd.append('familyCode', familyCode)
fd.append('jwt', Token)


    let url = ServerUrl+'/login_refresh_student_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
         
		var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
			  
		Cookies.set('_stexpt', inSixtyMinutes)
   Cookies.set('_stbexpt', inOneMinutes)

  Cookies.set('stkauscd', response.data.code, {expires: inOneHours })
  Cookies.set('stkamtk', response.data.jwt,  {expires: inOneHours })   
  Cookies.set('stkarfsh', response.data.userToken,  {expires: inOneHours })
  Cookies.set('stkstnm', encrypt(response.data.studentName),  {expires: inOneHours })
  Cookies.set('fmcdk', response.data.familyCode,  {expires: inOneHours })
  
  Cookies.set('sclkysd', response.data.classID,  {expires: inOneHours })
  Cookies.set('ssckayd', response.data.sectionID,  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
window.location.href='/?refferer='+window.location.href
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_stbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     
useEffect(()=>{
  if (localStorage.getItem("stlcksr") !== null) {
    document.getElementById('openlockscreenModals').click()
 }
})


useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_stexpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
      <Switch>

      
      <Route exact path="/student_portal/dashboard" component={Dashboard} />
    
    <Route exact path="/student_portal/timetable/class_timetable" component={ViewTimetable} /> 
    <Route exact path="/student_portal/timetable/class_lesson_plan" component={LessonPlan} /> 
    <Route exact path="/student_portal/timetable/holidays" component={Holidays} /> 
    
    
    <Route exact path="/student_portal/library/elibrary" component={eLibrary} /> 
    <Route exact path="/student_portal/library/books_report" component={BookReturn} /> 
    <Route exact path="/student_portal/library/books" component={Books} />
    
    <Route exact path="/student_portal/attendance/student_attendance" component={StudentAttendance} /> 
    <Route exact path="/student_portal/attendance/student_arrival_departure" component={StudentArrival} /> 
    
    <Route exact path="/student_portal/exam/exam_schedule" component={ExamSchedule} /> 
    <Route exact path="/student_portal/exam/myresult" component={ExamResult} /> {/* 
    <Route exact path="/student_portal/exam/mytranscript" component={Transcript} />  */}
    <Route exact path="/student_portal/exam/mid_term" component={midTerm_result} /> 
    
    <Route exact path="/student_portal/exam/mock_result" component={MockResult} /> 

    <Route exact path="/student_portal/fees/family_fees" component={FamilyFees} /> 
    <Route exact path="/student_portal/fees/fees_payment" component={FeesPayment} /> 
    
    
    <Route exact path="/student_portal/student/profile" component={Profile} /> 
    <Route exact path="/student_portal/student/review" component={Review} /> 
    <Route exact path="/student_portal/student/noticeboard" component={Noticeboard} /> 
    <Route exact path="/student_portal/student/change_password" component={ChangePassword} /> 
    <Route exact path="/student_portal/academics/documents" component={Documents} /> 


    <Route exact path="/student_portal/tickets" component={tickets} /> 
        <Route exact path="/student_portal/tickets/read/:code" component={readTicket} /> 
    <Route exact path="/student_portal/tickets/create" component={submitTicket} /> 

    <Route exact path="/student_portal/hostel/allocation" component={hostel} /> 
    <Route exact path="/student_portal/hostel/history" component={room_occupant} /> 
        <Route exact  component={Error404} />
      </Switch>
      </>
    );
  
}
export default ProtectedView;
