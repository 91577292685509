import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import { config,  ServerUrl,  Currency, FormatNumber, ImagesUrl, Token, API_URL  } from '../component/include';
import axios from 'axios';
import { Alerts} from '../component/notify'
import { globalId } from '../component/globalFunction';
import { useParams } from 'react-router';
import { LoadEffect, TableLoader } from '../component/loader';
import Pageheader from '../component/pageheader';


const ExpertDesign =()=>{
    
    let params = useParams()
    const [notice, setNotice] = useState({
        isLoading : false,
        isQuick:false,
        isDataFetching: false
    });
    const [content, setContent] = useState([]);
    const [attributes, setAttributes] = useState([])
    const [bulkPrice, setBulkPrice] = useState([])
    const [productAttributes, setProductAttributes] = useState([])
    
    const  [cartItem, setCatItem]= useState({
        catId:'cart-'+Math.random().toString(36).substr(2,9),
        productCode:params.code,
        customerCode:'',
        quantity:0,
        status:'',
        discount:0,
        attributes:[],
        files:[],
        price:0,
        globalId:globalId(),
        orderId:globalId(),
        shipping:0,
        tax:0,
        displayOrder:0,
        subtotal:0,
        quantityPrice:0,
        totalAttributePrice:0,
        info:'',
        profit:0,
        graphicFee:0,
        mode:'requestDesign'
      })


      const openFile=()=>{
        document.getElementById('photo').click();
    }
    const dragOver=(e)=>{        
        e.preventDefault();
        e.stopPropagation();

    }
    const dragEnter=(e)=>{        
        e.preventDefault();
        e.stopPropagation();
    }

    const uploadArea = {
        textAlign: 'center',
        overflow: 'auto'
        
        }
    const dropFile=(e)=>{        
       e.preventDefault();
e.stopPropagation();
var file = e.dataTransfer.files;


handleDropFile(file[0])
   e.dataTransfer.clearData(); 
    }

      const fetchProductAttribute =()=>{
    
        var sql ="Select code, attributeName, predefineValues from tbl_products_attributes " ;
            
        const fd = new FormData();
        
        fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
        
        axios.post(url, fd, config).then(result=>{  
          setAttributes(result.data.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    
    }
    const fetchContent =()=>{ 
        //  shortDescription, fullDescription
   
        setNotice({...notice, isLoading:true})
         var sql ="SELECT code, productName,  youtubeUrl, shortDescription, fullDescription, isPrinting, designNote, designFee, crossSell, related, attributes, bulkPrices, metaTitle, metaKeywords, isNew, price, oldPrice, manufacturers, imageList, rating, coverUrl, isNewStart, isNewEnd, isShippable, isOutOfStock, ID FROM  tbl_products where isPublished ='true' and code = '"+params.code+"'"
             
           const fd = new FormData();
           fd.append("sql", sql);
           
      fd.append('jwt', Token)
           let url = ServerUrl+'/fetchBySql_controller.php';
           axios.post(url, fd, config).then(result=>{  
            if(result.data.length!==0){
             let current = result.data[0]
               setContent(current)
               
          setBulkPrice(JSON.parse(current.bulkPrices)) 
          setProductAttributes(JSON.parse(current.attributes))
            }
           })
           .catch((error)=>{
               Alerts('Error!', 'danger', error.message)
           }).finally(()=>{

   setNotice({...notice, isLoading:false})
           })
         }

         const getAttribute =(code, field)=>{
            const result = attributes&&attributes.filter(item=>item.code===code)
            const answer = result.length!==0?result[0][field]:[]
            return answer
          }


         const getBulkQuantity=(ID, field)=>{
             let answer = bulkPrice.filter(item=>item.quantity===ID);
            let result = answer.length!==0?answer[0][field]:0
            return result
          }
         
          const handleChange =(event)=>{
            const {name, value} = event.target;

            setCatItem({...cartItem, [name] : value });
          }

          const handleChangeSub =()=>{
    
            var value = Number(cartItem.quantity)
        
                if(Number(cartItem.quantity) >1){
              value = Number(cartItem.quantity) - 1
            }
        
                
  
              var price  =  Number(content.price)
              var totalPrice = price+ Number(cartItem.totalAttributePrice)
              var subtotal = price+ Number(content.designFee)+ Number(cartItem.totalAttributePrice)
              
                setCatItem({...cartItem, quantity : value,  graphicFee:content.designFee,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
              }



          const handleChangeCart =(event)=>{
            let {name, value} = event.target;
            let qtyPrice = 0
            if(name==='bulkQuantity'){
                qtyPrice = value!==''? Number(getBulkQuantity(value, 'price')):0
            }

            var price  = bulkPrice.length!==0?qtyPrice: Number(content.price)
            var totalPrice = price+ Number(cartItem.totalAttributePrice)
            var subtotal = price+ Number(content.designFee)+ Number(cartItem.totalAttributePrice)

            setCatItem({...cartItem, quantity : value, graphicFee:content.designFee, quantityPrice:price, price:totalPrice, subtotal:subtotal });
          }
          


          const handleChangeAdd =()=>{

            var value = Number(cartItem.quantity) + 1
                    
                   
            
            var price  =  Number(content.price)
            var totalPrice = price+ Number(cartItem.totalAttributePrice)
            var subtotal = price+ Number(content.designFee)+ Number(cartItem.totalAttributePrice)
                  
                    setCatItem({...cartItem, quantity : value, graphicFee:content.designFee, quantityPrice:price, price:totalPrice, subtotal:subtotal });
                    }


          const getAttributeValue =(code)=>{
            const result = attributes&&attributes.filter(item=>item.code===code)
            const answer = result.length!==0?result[0].predefineValues:[]
            return answer.length!==0?JSON.parse(answer):[]
          } 

          const getOptionItem=(attributeCode, optionID, field)=>{
          let predefine = getAttributeValue(attributeCode)
          const answer = predefine.length!==0&&optionID!==''?predefine.filter(item=>item.ID===optionID)[0][field]:0
          return answer
          }
          
          
          const handleChangeAttribute =(event, code, attName)=>{
          
            let {name, value} = event.target;
            const newAttribute = cartItem.attributes;
          let items =[]
              items.push({attributeCode:code, attributeName:attName, optionID:value, optionName:String(getOptionItem(code, value, 'name')), optionPrice:(Number(getOptionItem(code, value, 'price')) * Number(cartItem.quantity))})
          
              const attributeList = items.concat(newAttribute)
              const allAttribute =	attributeList.map(e=>e['attributeCode'])
                          .map((e,i,final)=>final.indexOf(e)===i&&i)
                          .filter(e=>attributeList[e])
                  .map(e=>attributeList[e])

                var totalAttributePrice = 0;
                allAttribute.map(item=>totalAttributePrice += Number(item.optionPrice))

                var bulkAmount = Number(getBulkQuantity(cartItem.quantity, 'price'))
                var qtyPrice  = bulkPrice.length!==0?bulkAmount: Number(content.price)
                var totalPrice = qtyPrice+ totalAttributePrice
                var subtotal = qtyPrice+ Number(content.designFee)+ totalAttributePrice

            setCatItem({...cartItem, attributes : allAttribute, graphicFee:content.designFee, totalAttributePrice:totalAttributePrice, subtotal:subtotal, price:totalPrice})
          
          }

          const handleSubmitFile = (file, fileUrl, allFiles) =>{
            const fd = new FormData(); 
            fd.append("fileUrl", fileUrl);
            fd.append('fileDoc', file);   
            
      fd.append('jwt', Token)
            setNotice({...notice, 
                isLoading: true}) 
                let url = ServerUrl+'/save_controller.php?tablename=tbl_uploadDesign';
                axios.post(url, fd, config)
                .then(response =>{
               if (response.data.type ==='success'){
                setCatItem({...cartItem, files:allFiles})


                      Alerts('Success!', 'success', response.data.message)
                      }  
                  else{
                   Alerts('Error!', 'danger', response.data)
                  }
                  
               })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                        
                  }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false
                      })
                  })  
        } 
    

          const handleAddToCart = () =>{
              if(cartItem.price ===0){
                Alerts('Error!', 'danger', 'Design could not be added to cart')
              }else{
            setNotice({...notice, 
                isLoading: true})     
                  
                 
                let formData = cartItem;
                const fd = new FormData();
                for(let k in formData){
                  fd.append(k, formData[k])
                }
                
      fd.append('jwt', Token)
                  fd.append('fileItems',  JSON.stringify(cartItem.files, null, 2))
                  fd.append('attribute',  JSON.stringify(cartItem.attributes, null, 2))

                   let url = ServerUrl+'/save_controller.php?tablename=tbl_product_orders';
                  axios.post(url, fd, config)
                  .then(response =>{
                   if(response.data.type ==='success'){
                      Alerts('Saved!', 'success', response.data.message)
                      window.open('/tuckshop/checkout', '_self')
                          } else{
                      Alerts('Error!', 'danger', response.data)
                          }   
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                      setNotice({...notice, 
                          isLoading: false
                      })
                   //   setIsSubmitting(false);
                  }) 
        } 
          }
          const handleDropFile=(file)=>{
   
			let displayMessage = '';
			var fileName =  file.name;
		 
		 // var idxDot = fileName.lastIndexOf(".") + 1;
		//  var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
		//  if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
			  //TO DO    
			  
			   var FileSize = file.size / 1024 / 1024;  // in MB
		  if (FileSize > 5) {
			  displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 5 Mb';
		  } else {
			  if (file) { //Check if input has files.
				 let Id = Math.random().toString(36).substr(2, 9)
				  let fileUrl = Id+'_'+file.name
                let items = {ID:Id, fileUrl:fileUrl, order:cartItem.files.length+1, productCode:params.code, globalId:globalId()}
                let oldFiles = cartItem.files
               const allFiles =  oldFiles.concat(items)
                 
                    handleSubmitFile(file, fileUrl, allFiles)
			  }
		  }
		   
		  
		  if(displayMessage.length > 0){
			  Alerts('Error!', 'danger', displayMessage)
			}
		 
		}
	
	const  handleFile = function(fieldName){
				 return function(newValue){     
				readURL(newValue);
		}			
	 } 
		 
	 const readURL = (input)=>{
				  let displayMessage = '';
				  if(input.target.files[0].size){
                   var FileSize = input.target.files[0].size / 1024 / 1024;  // in MB
                   if (FileSize > 5) {
                       displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 5 Mb';
                   } else {
                       if (input.target.files && input.target.files[0]) { //Check if input has files.
                          let Id = Math.random().toString(36).substr(2, 9)
                           let fileUrl = Id+'_'+input.target.files[0].name
                         let items = {ID:Id, fileUrl:fileUrl, order:cartItem.files.length+1, productCode:params.code, globalId:globalId() }
                         let oldFiles = cartItem.files
                        const allFiles =  oldFiles.concat(items)
                        
                             handleSubmitFile(input.target.files[0], fileUrl, allFiles )
                       }
                   }
                    
                   if(displayMessage.length > 0){
                       Alerts('Error!', 'danger', displayMessage)
                     }
             } 
            }

             
             const handleRemove =(ID, fileUrl)=>{
                var oldFiles = cartItem.files
                var newFiles = oldFiles.filter(item=>item.ID!==ID)
                

                setNotice({...notice, 
                    isLoading: true}) 
        
                const fd = new FormData();
                
      fd.append('jwt', Token)
                fd.append('imageLink', '../images/design/'+fileUrl)
            let url = ServerUrl+'/deleteImage_controller.php?tablename=tbl_removeDesign';
                  axios.post(url, fd, config)
                  .then(response =>{
                   if(response.data.type ==='success'){
                    setCatItem({...cartItem, files:newFiles})
                          } else{
                      Alerts('Error!', 'danger', response.data)
                          }   
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{
                      setNotice({...notice, 
                          isLoading: false
                      })
                  }) 
            
             }

            
            
       const uploadAreaH2 ={
                textAlign: 'center',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                lineHeight: '50px',
                color: 'darkslategray'
            }
            
     
   
         

         useEffect(()=>{
            fetchContent()
            fetchProductAttribute()
           }, []);

return (<>
    
    <Helmet>
          <title>{content&&content.productName}</title>
        <meta name="description" content={content&&content.metaDescription} />
        <meta name="keywords" content={content&&content.metaKeywords} />
    </Helmet>


    <Pageheader title="Tuckshop">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-home"></i> </a> 
         </li>
                          
         <li className="breadcrumb-item"><a href="/tuckshop/products">Products</a> </li>
           <li className="breadcrumb-item"><a href="#!">{params.code.toUpperCase()}</a> </li>

  </Pageheader>

    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

    <div className="modal fade" id="designTerms" tabIndex="-1" role="dialog">
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content">
       
        <div className="modal-header">
        <h4>Design Terms</h4>
          <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>

        </div>
        <div className="modal-body tab-content py-4">
                <ul className="term-list">
                <li>You will receive your first design options within 72 working hours of
                placing your order
                </li>
                <li>The design service is an extra service which adds additional time to your
                order. Our delivery service level agreement (5 working days for Abuja and
                7 working days for other Nigerian cities) will start counting when you approve
                your design.
                </li>
                <li>You will receive your artwork in two design options with the ability to request
                three changes on your selected option. Extra changes may attract extra charges.
                </li>
                <li>Payments made for designer services are non-refundable once we have sent you the designs.
                </li>
                </ul>
        
        <div>
                <h3 className="term-heading">This service does NOT include:</h3>
            <ul className="term-list">
            <li>Creating, editing or vectoring logos. If you would like to order a new logo
            design, please <a  href="#!">click here.</a>
            </li>
            <li>Creation and revision of texts and sourcing of stock. Customers provide
            their own contents.
            </li>
            </ul>
        </div>

        </div>
        
        <div className="modal-footer">
    
        <div className="submit"><button className="btn btn-primary " type="button" onClick={handleAddToCart} >Add to Cart</button></div> 
        
        </div>
        </div>
        </div>
        </div>


        <div  className="main-body">
                <div className="page-wrapper">
        <div className="page-body">

{content.isPrinting==='true'?
<div className="container  mb-lg-3">
    <h5 >SUBMIT A REQUEST FOR DESIGN</h5><hr style={{fontWeight: '1px solid #444 '}}/><br/>
    <div className="row" >
       
        <div className="col-lg-6 col-md-6" style={{border: '1px solid #ccc', paddingTop:'20px'}}>
          
          <div className="col-md-12">
          <label className="form-control" ><strong>{content.productName}</strong></label>
   
    </div>
    <br/>
    
    <div className="col-md-12">
    <label className="font-weight-medium" >Quantity</label>
    {bulkPrice.length!==0?
                 <select className="form-control" value={cartItem.quantity} name="bulkQuantity" onChange={handleChangeCart} >
                      <option  value=''   >Select </option>
                      {bulkPrice.map(price =>
                      <option key={price.ID} value={price.quantity} price={price.price}>{price.quantity} </option>
                      )}
                    </select>:
                   <div className="form-group col-md flex-grow-0">
                   <div className="input-group mb-3 input-spinner">
                     <div className="input-group-prepend">
                   
                   
                     <button className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-minus" onClick={handleChangeSub}> − </button>
                     </div>
                     <input className="form-control" type="text" onFocus={(e)=>e.target.select()} name="quantity" onChange={handleChangeCart} value={cartItem.quantity} min='1' />
                     <div className="input-group-append">
                         
                       <button  className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-plus" onClick={handleChangeAdd}> + </button>
                     </div>
                   </div>
                   </div> }
    

    </div>
    <div className="col-md-12 " style={{textAlign:'right'}}>
         Price: <span className="price text-price h3 font-weight-normal ">{Currency+FormatNumber(cartItem.price)}</span>
    </div>
    
 {productAttributes&&productAttributes.map((data, id)=>
 data.controlType==='select'?
        <div className="col-md-12" key={id}><br/>
                 {getAttributeValue(data.attribute).length!==0?  <label className="font-weight-medium" htmlFor={id}>{getAttribute(data.attribute, 'attributeName')}</label>:''}
                 
                     <select className="mr-2 form-control" 
                    name={getAttribute(data.attribute, 'attributeName')}
                    onChange={(e)=>handleChangeAttribute(e, data.attribute, getAttribute(data.attribute, 'attributeName'))} 

                    value={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===data.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===data.attribute)[0].optionID:'' }>
                     <option  value=''   >Select </option>

                    {getAttributeValue(data.attribute).map(list =>
                    <option  name={list.name}  value={list.ID} price={list.price} key={list.ID}>
                      {list.name}
                    </option>
                  )} 
                    </select></div>:
                     <div className="col-md-12" key={id}><br/>


                        {getAttributeValue(data.attribute).length!==0?  <label className="font-weight-medium" htmlFor={id}>{getAttribute(data.attribute, 'attributeName')}</label>:''}
                    <div className=" pb-2">


                    <div className=" filter-content">
 <div className="form-radio"> 

{getAttributeValue(data.attribute).map(list => <div key={list.ID} className="radio radiofill radio-inline">
<label>
<input type="radio" 
checked={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===data.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===data.attribute)[0].optionID===list.ID?true:false:false} name={list.name} 
onChange={(e)=>handleChangeAttribute(e, data.attribute, getAttribute(data.attribute, 'attributeName'))} value={list.ID} price={list.price} id={list.ID} />
<i className="helper"></i>{list.name}
</label>

{list.isLogo? <img className="d-inline-block rounded mb-3" width="150"  src={ImagesUrl+"/attribute/"+list.imageUrl} alt="" />:''}
</div>)}
</div>
  </div>
                    </div>
                  </div>)}
                  
                  

<div className="col-md-12"><br/>
Graphic Design Fee: <span className="price text-price" style={{fontSize:'20px',fontWeight: '600'}}>{Currency+FormatNumber(content.designFee)}</span>
</div><br/>

<div className="col-md-12">
          <label className="font-weight-medium text-red" >Please include all contact details and information you want in your design here. Add all remarks, preferred colours, references and additional instructions here</label>

              <textarea className="form-control" rows="5" value={cartItem.info} onChange={handleChange} name="info">{cartItem.info}</textarea>
   
    </div>

<br/>
    <div className="col-md-12 " style={{textAlign:'right'}}>
         Total: <span className="h3 font-weight-normal price text-price">{Currency+FormatNumber(cartItem.subtotal)}</span>
    </div>



   

           {notice.isLoading?<TableLoader />:    
           
           
        <div className="col-sm-12" id="UploadPhotos" aria-expanded="true" >
          
           <div className="dash"  >
<div className="well well-sm">

<input type="file" style={{display:'none'}} onChange={handleFile('photo')} accept="image/*" name="photo" id="photo" />

<div className="upload-area"  onDragEnter={(e)=>dragEnter(e)} onDrop={(e)=>dropFile(e)} onDragOver={(e)=>dragOver(e)} style={uploadAreaH2} id="uploadfile">
<i className="fa fa-download fa-2x"></i>
<h4 id="dragText">Drag &amp; Drop files here</h4> 
    <span >or</span><br/>
<button type="text"  className="btn btn-danger btn-sm" onClick={openFile}>Browse File</button>
</div>


</div>

</div>
        
<br/>
            <div className="table-responsive font-size-md">
            <table className="table table-bordered mb-0">
              
              <tbody>
                  {cartItem.files.map(item=>
                <tr key={item.ID}>
                  <td ><a href={ImagesUrl+"/design/"+item.fileUrl} target="_blank">{item.fileUrl}</a> </td>
                  <td > <button className="btn btn-outline-danger btn-sm" type="button" onClick={()=>handleRemove(item.ID, item.fileUrl)}><i className="fa fa-trash mr-2"></i>Remove</button>
                  
                 </td>
                  </tr>)}
              </tbody>
            </table>
          </div>
        </div>}
                       
        <br/>
        <div className="col-sm-12">
        <strong>Supported formats:</strong>

<p><img alt=" Accepted files. CDR, PDF, AI and PSD" height="20" src={ImagesUrl+"/support.png"} width="140" /></p>
</div>
<br/>
<div className="col-sm-12 ">
<a  className="btn btn-primary btn-block" href="#designTerms" data-toggle="modal" >  Save</a>
</div>


<div className="col-md-12"><br/>
          <label className="font-weight-medium" >If you need our team to help in editing, updating and redesigning your order, please <a href={'/tuckshop/order/requestExpertDesign/'+params.code}><b>CLICK HERE</b></a> to order with design request option at an additional fee.</label>

   
    </div>

        </div>

        
       <div className="col-lg-4 col-md-6">
       
        
        <div className="col-md-12 img-wrap">
        <img className="img-fluid" src={ImagesUrl+"/product/"+content.coverUrl} alt="" />
       
          <label  ><strong>{content.productName}</strong></label><br/>
   <p>{content.designNote}</p>
         </div>
          </div> 
      </div>
    </div>:''}

</div>
</div>
</div>

    </>)
}


export default React.memo(ExpertDesign) 