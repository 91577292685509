import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {API_URL, config, familyCode} from '../component/include'
import { generateCardNo, shortText, timeSince } from '../component/globalFunction'

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
const SubmitTickets =()=> {
    const theme = 'snow';
    // const theme = 'bubble';
  
const [users, setUsers] = useState([])
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'
          ]
      ],
    };
  
    const placeholder = 'Enter your message...';
  
    const formats =  ['bold', 'italic', 'underline', 'strike'  ];
  
    const {quill, quillRef } = useQuill({ theme, modules, formats, placeholder });
  
//const { quill, quillRef } = useQuill();
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})

const [recentTickets, setRecentTicket]= useState([])   
const [ticket, setTicket]= useState({
   subject:'',
   department:'',
   priority:'',
   message:'',
   senderName:'',
    senderEmail:'',
    ticketID:generateCardNo(6)
})

const [image, setImage] = useState([])

const [errors, setErrors] = useState({
    subject:'',
    department:'',
    senderEmail:'',
    priority:'',
    message:'',
    errorMessage:'',
    successMessage:''
 })

const handleChange =(event)=>{
    let {name, value} = event.target;
    setTicket({...ticket, [name] : value });
   setErrors({...errors, [name]:''});
} 
    
   

const AddMoreFile =()=>{

    var input = document.createElement("input");
    input.setAttribute('type', 'file');
    input.setAttribute('name', 'passport'+image.length);
    input.setAttribute('id', 'passport'+image.length);
    input.className = "form-control mt-2"; 
    input.accept="image/*"
    var parent = document.getElementById("moreFileDiv");
    parent.appendChild(input);
    let allImg = image
                allImg.push({file:'', name:'passport'+image.length})
                    setImage(allImg)
}


function validateImage(id) {
   
    var file = document.getElementById(id).files[0];

    var t = file.type.split('/').pop().toLowerCase();
    if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
        
    Alerts('Error!', 'danger', 'Please select a valid image file')
        document.getElementById(id).value = '';
        return false;
    }

    var FileSize = file.size / 1024 / 1024; // in MB

    if (FileSize > 1) {
        Alerts('Error!', 'danger', 'Max Upload size is 1MB only')
        document.getElementById(id).value = '';
        return false;
    }
    return true; 
}


  const handleSubmit = event =>{
    event.preventDefault(); 
    
    let errors = {};   
     
    
    let imageList = []
    
    

    for(var i=0; i<image.length; i++){
        var file = document.getElementById(image[i].name)
        if(file.value!=='' && validateImage(image[i].name)===true){
            imageList.push({imageUrl:document.getElementById(image[i].name).files[0], name:'t'+Math.random().toString(36).substr(2,9)+'.png' }) 
             
        }else if(file.value!=='' && validateImage(image[i].name)===false){
            
            errors[image[i].name] = 'Max Upload size is 1MB only'
        }
    }

    if(ticket.department.length===0){
        errors.department ='Please select department';
    } 

    
    if(ticket.priority.length===0){
        errors.priority ='Please select priority';
    } 

    if(users.username.length===0){
        errors.senderEmail ='Sender email is important';
    }


    if(ticket.subject.length===0){
        errors.subject ='Please enter subject';
    } 


    if(quill.root.innerHTML==="<p><br></p>"){
        errors.message = 'Please enter message';
    }

    setErrors(errors)

    if(Object.keys(errors).length === 0){
       
        const fd = new FormData(); 
        let link ='[]';
        if(imageList.length!==0){
        link = imageList.filter(item=>item.imageUrl!=='').map(item=>item.name)
             for(let k in imageList){
                if(imageList[k].imageUrl!=='')
                fd.append('imageUrl[]', imageList[k].imageUrl);
            };  
    }
    fd.append('senderCode', familyCode)
      fd.append('senderImage', '')
      fd.append('senderType', 'Parent')
      fd.append('fileUrl', imageList.length!==0?JSON.stringify(link,2, null):link)
      fd.append('messageType', "main")


      fd.append('ticketStatus', "Open")
      fd.append('ticketID', ticket.ticketID)
        fd.append('message', String(quill?.root.innerHTML));
      fd.append('subject', ticket.subject)
      fd.append('department', ticket.department)
      fd.append('priority', ticket.priority)
      fd.append('username', users.username)
      fd.append('familyName', users.familyName)

   setNotice({...notice,  isLoading: true}) 
   let url = API_URL+'/ticket/add';
        axios.post(url, fd, config)
        .then(response =>{
          if (response.data.status ===200){
              Alerts('Saved!', 'success', response.data.message)
              setErrors({...errors, successMessage: 'Your Ticket ID: #'+ticket.ticketID})

              setTicket({
                subject:'',
                department:'',
                priority:'',
                message:'',
                senderName:'',
                 senderEmail:'',
                 ticketID:generateCardNo(6)
             })
          } else{
              setErrors({...errors, errorMessage: JSON.stringify(response.data)})
      }  
      })
      .catch((error)=>{
          setErrors({...errors, errorMessage: JSON.stringify(error.message)}) 
      })
      .finally(()=>{
        setNotice({...notice, isLoading: false}) 
        window.scrollTo(0,0)
        fetchTickets()
      })  

        }

} 


const fetchTickets =()=>{

    var sql ="Select subject, created_at, ticketID, status from tbl_tickets where senderType ='Parent' and messageType ='main' and senderCode  ='"+familyCode+"' order by ID DESC limit 7"
     
    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.data.length!==0){

            setRecentTicket(result.data.data)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
}



const fetchFamily =()=>{

    var sql ="Select familyName, username from tbl_family where code  ='"+familyCode+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw'
    
    axios.post(url, fd, config).then(result=>{ 

        if(result.data.data.length!==0){
            setUsers(result.data.data[0])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
}


useEffect(()=>{
    fetchTickets()
    fetchFamily()
    AddMoreFile()
},[])


useEffect(()=>{

    if(quill){
    if(quill.root.innerHTML!=="<p><br></p>"){
       setErrors({...errors, message:''})
    }
}
},[quill]);


        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Open Ticket">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-envelope"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Student</a> </li>
                            <li className="breadcrumb-item"><a href="/tickets">Tickets</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
              
              {errors.errorMessage?
<div className="alert alert-danger background-danger">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.errorMessage}</strong> 
  </div>:''}


  {errors.successMessage?
<div className="alert alert-success background-success">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <i className=" fa fa-times text-white"></i>
      </button>
      <strong> {errors.successMessage}</strong> 
  </div>:''}
            
  <div className="card">
                              
                              <div className="card-body">
                      <h2>Got any issues? </h2>{/* 
                      <h3 className="text-success"><b>We promise to resolve it within 24 hours. </b> </h3> */}

                              </div>
                          </div>
              <div className="card">
<div className="card-block email-card">
 
  <div className="row">
      <div className="col-lg-12 col-xl-3">
          <div className="user-body">
             
                  
              <div className="card feed-card">
<div className="card-header">
  <h5>   Your Recent Tickets</h5>
</div>
<div className="card-block">

  {recentTickets.length!==0?recentTickets.map((item, index)=>
  
  
  <div className="row m-b-30" key={index}>
      <div className="col-auto p-r-0">
          <i className={`feather icon-file-text ${item.status==='Closed'?'bg-simple-c-pink':item.status==='Open'?'bg-simple-c-green':'bg-simple-c-blue'}  feed-icon`}></i>
      </div>
      <div className="col">
      <a href={"/tickets/reply/"+item.ticketID} >  <h6 className="m-b-5">{shortText(item.subject, 30)} <span className="text-muted f-right f-13">{ timeSince(new Date(item.dateTime))}</span></h6></a> 
      </div>
  </div>
  ):''}


  <div className="text-center">

<a href="/tickets" className="b-b-primary text-primary">  View all Tickets</a>
  </div>
</div>
</div>
          </div>
      </div>

      <div className="col-lg-12 col-xl-9">
      <div className="mail-body">

              <div className="mail-body-content">
                  <form>
                  <div className="form-group">
                          <div className="row">
                              <div className="col-md-6 bold">Name
                                  <input type="text" className="form-control" name="senderName" value={users.familyName} disabled onChange={handleChange} />
                              </div>
                              <div className="col-md-6 bold"> Email Address
                                  <input type="email" className={errors.senderEmail ? 'form-control form-control-danger' : 'form-control'} name="senderEmail" disabled value={users.username}  onChange={handleChange} />
                                  <span style={{color:'red'}}>{errors.senderEmail}</span>
                              </div>
                          </div>
                      </div>


                      <div className="form-group bold">Subject
                          <input type="text" className={errors.subject ? 'form-control form-control-danger' : 'form-control'} placeholder="Subject" name="subject" value={ticket.subject} onChange={handleChange} />
                          <span style={{color:'red'}}>{errors.subject}</span>
                      </div>

                      <div className="form-group">
                          <div className="row">
                              <div className="col-md-6 bold">Department
                              <select  name="department" value={ticket.department} className={errors.department ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange}>
                              <option value="" selected disabled> [Select]</option>
                      <option value="IT Center">IT Center </option>
                      <option value="Security">Security</option>
                      <option value="Admins">Admins</option>
                      <option value="Finance">Finance</option>
                      <option value="Support">Support</option>
                  </select>
                  <span style={{color:'red'}}>{errors.department}</span>
                              </div>
                              <div className="col-md-6 bold"> Priority
                              <select  name="priority" value={ticket.priority} className={errors.priority ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange}>
                              <option value="" selected disabled> [Select]</option>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                  </select>
                  <span style={{color:'red'}}>{errors.priority}</span>
                              </div>
                          </div>
                      </div>
                     
                      <div className="form-group "><b>Message</b>
                      
                     
<div style={{ width: '100%', height: 300 }}>
                      <div ref={quillRef} />

                      </div>
              
                      </div>

                      <br/>
                      <span style={{color:'red'}}>{errors.message}</span>
                      <div style={{ height: 10 }}></div>
                  </form>
              </div>


      </div>
      <div className="row">
  <div className=" col-md-9 ">

  <div className=" bold">Attachments (<span className='text-red'>Allowed: .jpg, .jpeg, .png, Max: 1Mb</span>)
                          
                          
                          <div id='moreFileDiv'>

                          </div>
                      </div> 
  </div>

 
  <div className=" col-md-3"><br/>
  <button type='button' onClick={AddMoreFile} className="btn btn-inverse">+ Add More</button>
      </div>

      </div>
      <div className=" col-md-12 text-center"><br/>
      <button type='button' className="btn btn-success" onClick={handleSubmit}>Submit</button>&nbsp;&nbsp;
      <button type='button' className="btn btn-secondary" onClick={()=>window.open('/tickets', '_self')}>Cancel</button>
  
          </div>



  </div>
  
  </div>
  <div style={{ height: 60 }}></div>
</div>
</div>




 </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 )
        
}

export default React.memo(SubmitTickets) 