import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {ServerUrl,  Token,  config,  ImagesUrl, API_URL, userCode} from '../../component/include'

import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Alerts } from '../component/notify'
import { useParams } from 'react-router'
const Overview =()=> {
    let params = useParams()
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


const countries  = useSelector(state => state.rootReducer.countries);

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
const [content, setContent] = useState([])
const [isSubmitting, setIsSubmitting] = useState(false);

const [schoolClass, setSchoolClass]=useState([])
const [allClasses, setAllClases]=useState([])
    const [errors, setErrors] = useState({});
    const [application, setApplication] = useState([]);
    const [lga, setLga] = useState([])
    const [states, setStates] = useState([])

    const [image, setImage] = useState({
        passport:'',
        transferImage:'',
        recordImage:'',
        birthImage:'',
        passportFile:''
    })

const handleChange = event =>{		
    let {name, value} = event.target;	
   setContent({...content, [name] : value });
   setErrors({...errors, [name]:''});
}



const fetchContent =()=>{
    setNotice({...notice,  isLoading: true}) 
    var sql ="Select * from tbl_admission_request where applicationID ='"+params.code+"'";
     
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{ 
        if(result.data.length!==0){
        setContent(result.data[0])
        }

        if(result.data[0].submitted==='true'){
            window.open('/preview_application/'+result.data[0].email, '_self')
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isLoading: false}) 
    })
    
}



const fetchApplication = async ()=>{

    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchField/tbl_school_class/schoolClass/ID/1'
        const {data} = await axios.post(url, fd, config)
      
        setAllClases(data);

    var sql ="select t.applicationName, a.schoolClass,  r.applicationID, s.startYear, r.applicationType, r.schoolSession from tbl_admission_request r, tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.id = a.schoolSession and t.code = a.applicationType and r.applicationType = a.code and r.applicationID ='"+params.code+"' and r.agent ='"+userCode+"' ";


    fd.append("sql", sql);
    let urli = ServerUrl+'/fetchBySql_controller.php'
    axios.post(urli, fd, config).then(result=>{ 
        
        if(result.data.length!==0){
            var dt = result.data[0]
            setApplication(dt)
            if(dt.schoolClass!=='[]'){
            var options = JSON.parse(dt.schoolClass)

            var value = [];
            for (var i = 0, l = (options || []).length; i < l; i++) {
              value.push({classList:data.filter(list=>list.arms===options[i].sectionID)});
            }

            if(value.length!==0){
            var allValue = [];
            for (var i = 0, l = value.length; i < l; i++) {
                allValue.push(...value[i].classList);
              }
            }


          setSchoolClass(allValue)
            }
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    
}

const getSectionName =(code)=>{
   let ans = allClasses&&allClasses.filter(list=>list.ID===code)
   let response = ans.length!==0?ans[0].arms+' => '+ans[0].cname:''
return response
}



  const removeImageUrl =()=>{
    //const img = document.getElementById('viewLogo');
    //img.src = PictureUrl+"/noImage.png" 
    document.getElementById('passport').value = '';
    setImage({...image, passport:''})
}

  const handleSelect = (option, action)=>{
    if(action.name==='stateCode'){
        handleFetchLga(option.iso2)
      }else if(action.name==='country'){
        handleFetchState(option.value) 
      }


    setContent({...content, [action.name]:option.value});
      
  }


  const handleChangeSelect =  (option, action) =>{		
   setContent({...content, classID : option.value, sectionID:option.value});
   setErrors({...errors, [action.name]:''});
}

const handleChangeSchool =(event, index)=>{

    let {name, value} = event.target
    const school = JSON.parse(content.schoolAttended)
            school[index][name] = value
            setContent({...content, schoolAttended:JSON.stringify(school)})
                
}


  const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

  
  const readURL = (input)=>{
       
    let displayMessage = '';
    const PreviewImage = input.target.name;
        let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = PreviewImage +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
        document.getElementById(PreviewImage).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.

        
            var reader = new FileReader();//Initialize FileReader.
            reader.onload = function (e) {
        if(PreviewImage==='passport'){
                            setImage({...image, passport:e.target.result, passportFile:input.target.files[0]})
                            setErrors({...errors, passport:''});
                        }else{
                            setImage({...image, [PreviewImage]:input.target.files[0]})
                        }
            };
            reader.readAsDataURL(input.target.files[0]);

        }
    }
    }else{

        displayMessage = 'Only jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1 Mb'
        document.getElementById(input.target.name).value = '';
        setImage({...image, passport:''})
    }  
     if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
    
} 


const handleSubmit = event =>{
    event.preventDefault();
     
setErrors(ValidateInput(content));
    setIsSubmitting(true);
    window.scrollTo({top:0, left:0, behavior:'smooth'})
}


const ValidateInput =(values)=>{
    let errors = {};   
     
     if(!values.studentName){
        errors.studentName ='Please enter your fullname';
    }
    if(!values.classID){
        errors.classApply ='Please choose class you are applying for';
    }

    
    if(values.birthDate ==='0000-00-00'){
        errors.birthDate ='Please enter your birthdate';
    }

    if(!values.languageSpoken){
        errors.languageSpoken ='Please type in language spoken';
    }

    if(!values.address){
        errors.address ='Please enter your address';
    }

    if(!values.religion){
        errors.religion ='Please select your religion';
    }

    if(!values.fatherName){
        errors.fatherName ='Please enter your father\'s fullname';
    }

    if(!values.fatherPhone){
        errors.fatherPhone ='Please enter your father\'s mobile';
    }

    if(!values.motherName){
        errors.motherName ='Please enter your mother\'s fullname';
    }

    if(!values.motherPhone){
        errors.motherPhone ='Please enter your mother\'s mobile';
    }
    if(!values.passport && image.passportFile ===''){
        errors.passport ='Please upload your passport';
        Alerts('Error!', 'danger', 'Please upload your passport');
    }
     
    if( !values.birthImage && image.birthImage ===''){
        errors.birthImage ='Please upload your birth certificate';
        Alerts('Error!', 'danger', 'Please upload your birth certificate');
    }

    return errors;
    }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
        },[errors])



function submit(){  
 setNotice({...notice,  isLoading: true})     
          
        let formData = content;
        const fd = new FormData();

        fd.append('jwt', Token);

        for(let k in formData){
        fd.append(k, formData[k])
        }

            if(image.recordImage!==''){
                fd.append('recordImageFile', image.recordImage)
            }
            if(image.birthImage!==''){
                fd.append('birthImageFile', image.birthImage)
            }
            if(image.transferImage!==''){
                fd.append('transferImageFile', image.transferImage)
            }

        let url = ServerUrl+'/update_controller/tbl_admission_request';
        axios.post(url, fd, config)
        .then(response =>{
        if(response.data.type === 'success'){
            
               window.open('/preview_application/'+params.code, '_self')
                  } else{
                //Alerts('Error!', 'danger', response.data.message)
                window.scrollTo(0,0)
          setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

                setNotice({...notice, 
                  isLoading: false
                })
                setIsSubmitting(false);
              })  
          }


          function updatePassport(){  

            if(image.passportFile.length===0){
              Alerts('Error!', 'danger', 'Kindly upload  passport')
            }else{
                      setNotice({...notice, isLoading: true})     
                    
                        const fd = new FormData();
                        fd.append('jwt', Token);
                        
        
                        fd.append('studentName', content.studentName);
                        fd.append('applicationID', params.code);
                        fd.append('imageUrl', image.passportFile);
                            
                       let url = ServerUrl+'/save_controller/tbl_applicant_passport'
                        axios.post(url, fd, config)
                        .then(response =>{
                         if(response.data.type ==='success'){
                          Alerts('Saved!', 'success', response.data.message)
                        
                            } else{
                          Alerts('Error!', 'danger', response.data)
                            }   
                        })
                        .catch((error)=>{
                          Alerts('Error!', 'danger', error.message)
                        }).finally(()=>{
                          setNotice({...notice, 
                            isLoading: false
                          })
                        })  
                    }
                  }


        const fetchNameByState =(code, table, field)=>{
        const result = table.filter(list=>list.iso2===code);
        const answer = result.map(ans=>ans[field])
        return String(answer)
        }
        

        const fetchNameByFiled =(code, table, field)=>{
            const result = table.filter(list=>list.id===code);
            const answer = result.map(ans=>ans[field])
            return String(answer)
            }

            
     const handleFetchState =async(iso2)=>{

            let url = API_URL+'/geo/states/'+iso2
           await  axios.get(url, config).then(result =>{
              if(result.data.data.length!==0){
                  setStates(result.data.data)
                }else{
                    setStates([])
                }
          
            })
      
         }

        
        const handleFetchLga =async(alias)=>{

            let url = API_URL+'/geo/cities/'+alias
           await  axios.get(url, config).then(result =>{
              if(result.data.data.length!==0){
                  setLga(result.data.data)
                }else{
                    setLga([])
                }
          
            })
      
         }

useEffect(()=>{
 
fetchApplication()
 fetchContent()
    },[]);

        return (  <Template menu='overview'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                                    <div className="page-body m-t-50 ">
                         

                          
<div className="row">
    <div className='col-md-12'>

        <div className="row">

<div className="col-md-2"></div>
            <div className="card bg-c-green  col-md-8 ">
            <div className="card-block">
                                <h5 className="text-white m-b-0">{ application.startYear+' '+application.applicationName} </h5> 
                                
                                Note: All field mark * are compulsory
                            </div>
                            </div>
                            <div className="col-md-2"></div>
                            </div>
                                        
                            <div className="row">

<div className="col-md-2"></div>

                                        <div className="col-md-8">
                                                    {errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}
       
</div>
<div className="col-md-2"></div>
</div>

        {content&&content.submitted==='false'?  <div >
        <div className="row ">

        <div className="col-md-2"></div>
<div className="col-md-3">
    <div className="card user-card">
        <div className="card-block text-center">
            <div className="users-image">



                    {content.passport!==''?image.passport===''?
            <img id="viewPassport2" src={ImagesUrl+'/students/'+content.passport} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+content.gender+".png"}} className="img-radius " alt="User-Profile" /> :

            <img id="viewPassport" src={image.passport} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+content.gender+".png"}} className="img-radius " alt="User-Profile" />  :  

            <img id="viewPassport" src={image.passport} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+content.gender+".png"}} className="img-radius " alt="User-Profile" />      }

                    </div>
{/* 
                    <h6 className="m-t-25 m-b-10">{content.studentName}</h6>
                    <p className="text-muted">{content.applicationID}</p> */}

                    <input id="passport"  onChange={handleFile('passport')} className="form-control file" name="passport" type="file" accept="image/*" /> 

                    {image.passport===''? '':<button type='button' onClick={updatePassport} className='btn btn-success btn-sm btn-round'><i className="fa fa-image"></i> Upload</button>}

&nbsp;
                    {image.passport?	<button type="button" onClick={removeImageUrl} className="btn btn-sm btn-danger btn-round"  ><i className="fa fa-trash"></i> Remove</button>	:

<label className="btn btn-secondary btn-round " htmlFor="passport" > Select Passport </label>
                   
                                }
                </div>
            </div>
        </div> 




  <div className="col-md-5">
                   
            <div className="card ">
                <div className="card-block">
                   


                <div className="form-group">
						<label className='text-bold' >Child's Fullname (Surname First)<span className="text-red">*</span></label>
						<input type="text"  name="studentName" value={content.studentName} placeholder="Your fullname" onChange={handleChange} className={errors.studentName  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.studentName}</span>
												
											</div>
											
								<div className="form-group">
						<label className='text-bold'>Place of Birth</label>
						<input type="text"  name="placeOfBirth"  value={content.placeOfBirth} placeholder="Your Place of Birth" onChange={handleChange} className='form-control form-control-lg' /> 
                        				
											</div>
											

                                            <div className="form-group">
						<label className='text-bold'>Date of Birth <span className="text-red">*</span></label>
						<input type="date"  name="birthDate"  value={content.birthDate} placeholder="Your Date of Birth" onChange={handleChange} className={errors.birthDate  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
												
											</div>
				<span className=" form-bar error">{ errors.birthDate}</span>



                <div className="form-group">
						<label className='text-bold'>Nationality</label>
                        <Select   options={countries&&countries.map((data, idx)=> {
                                    return {key:idx, value: data.iso2, label: data.name };
                                  })}
onChange={handleSelect}  name="country"  value={{value: content.country, label: fetchNameByState(content.country, countries, 'name')}} styles={selectStyles} /> 

												
											</div>


                                            <div className="form-group">
						<label className='text-bold' >State of Origin</label>

                        <Select   options={states&&states.map((data, idx)=> {
                                    return {key:idx, value: data.id, label: data.name, iso2:data.iso2 };
                                  })}
onChange={handleSelect}  name="stateCode" value={{value: content.stateCode, label: fetchNameByFiled(content.stateCode, states, 'name')}} styles={selectStyles} /> 
		</div>
                                       

                                            <div className="form-group">
						<label className='text-bold'>City</label>
                        <Select   options={lga&&lga.map((data, idx)=> {
                                    return {key:idx, value: data.id, label: data.name, index:idx };
                                  })}
onChange={handleSelect}  name="lga" value={{value: content.lga, label: fetchNameByFiled(content.lga, lga, 'name')}}  styles={selectStyles} /> 

			</div>

                <div className="form-group">
						<label className='text-bold'>Application Class  <span style={{color:'red'}}>*</span></label>
						<Select  
				  options={schoolClass&&schoolClass.map((list, idx)=> {
					return {key:idx,  value: list.ID, label: list.cname, arms: list.arms};
				  })}

onChange={handleChangeSelect}  name="classApply" className={errors.classApply  ? 'form-control formerror' : ''}  value={{value:content.classID, label:getSectionName(content.classID)}} styles={selectStyles} />
												
											</div>

				<span className="form-bar error">{ errors.classApply}</span>


											
								<div className="form-group">
						<label className='text-bold'>Gender</label>
						<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={content.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={content.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>						
											</div>
											
               
            </div>
        </div>
        </div>
        <div className="col-md-2"></div>
        </div>
            
        <div className="row ">
        <div className="col-md-2"></div>
                                    <div className="card bg-c-green col-md-8">
                                    <div className="card-block">
                                    <h5 className="text-white m-b-0">EDUCATIONAL BACKGROUND</h5>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    </div>
                                
                                
                                
                        {content.length!==0?JSON.parse(content.schoolAttended).map((item, id)=><div key={id}>  
                        <div className="row "> 
                        <div className="col-md-2"></div>
                         <div className="col-md-8" >
            <div className="card ">
                <div className="card-block">
                   
<div className="form-group">
        <label >School Attended ({id+1})</label>
        <input type="text" value={item.institution} placeholder="School Attended" onChange={(e)=>handleChangeSchool(e, id)} className='form-control form-control-lg' name="institution" />
                                
                            </div>
                            
<div className="form-group">
        <label >Year of Graduation</label>
        <input type="text" onChange={(e)=>handleChangeSchool(e, id)} name="awardYear" value={item.awardYear}className="form-control form-control-lg" />
                                
                            </div>
                

                   

<div className="form-group">
        <label >Program /Grade</label>
        <input type="text" name="program" onChange={(e)=>handleChangeSchool(e, id)}  value={item.program} className="form-control form-control-lg" />
                                
                            </div>              
                            
                <div className="form-group">
        <label >Remark</label>
        <input type="text" name="remarks" onChange={(e)=>handleChangeSchool(e, id)}  value={item.remarks} className="form-control form-control-lg" />
                                
                            </div>


                            
                        
                        </div>
                        </div>
                        </div> 
                        <div className="col-md-2"></div>
                        </div>
                        </div>):''} 
                        


                        
                        <div className="row "> 
                        <div className="col-md-2"></div>
                        <div className="card bg-c-green col-md-8">
                                    <div className="card-block">
                                                        <h5 className="text-white m-b-0">OTHER DETAILS</h5>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    </div>

           
                           <div className="row "> 
                        <div className="col-md-2"></div>
            <div className="card col-md-8">
                <div className="card-block">
                   
                   <div className="row">

                   
<section className="col-md-6">
<div className="form-group">
        <label >Language Spoken <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="languageSpoken" value={content.languageSpoken} placeholder="Language Spoken" onChange={handleChange} className={errors.languageSpoken  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.languageSpoken}</span>                         
                            </div>
                            
                            <div className="form-group">
        <label >Religion <span style={{color:'red'}}>*</span></label>
        <select  name="religion" value={content.religion} className='form-control form-control-lg form-control-round' onChange={handleChange}>
                        <option value="">[Select]</option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                    </select>	
                    <span className=" form-bar error">{ errors.religion}</span> 
                            </div>


                            <div className="form-group">
        <label >Father's Fullname <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="fatherName" value={content.fatherName} placeholder="Your father's fullname" onChange={handleChange} className={errors.fatherName  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.fatherName}</span>                         
                            </div>
                            
                

<div className="form-group">
        <label >Mother's Fullname <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="motherName" value={content.motherName} placeholder="Your mother's fullname" onChange={handleChange} className={errors.motherName  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.motherName}</span>                         
                            </div>

               
                            
                           
                <div className="form-group">
        <label >Any Pysical Disability ?</label>
        <select  name="disabilityStatus" value={content.disabilityStatus} className='form-control form-control-round form-control-lg' onChange={handleChange}>
                        <option value="">[Select]</option>
                        <option value="None">None</option>
                        <option value="Yes">Yes</option>
                    </select>		                       
                            </div>

                           
                        </section>
                       

   
<section className="col-md-6">

<div className="form-group">
        <label >Contact Address <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="address" value={content.address} placeholder="Your contact address" onChange={handleChange} className={errors.address  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.address}</span>                         
                            </div>
                            
                            <div className="form-group">
        <label >Tribe</label>
        <input type="text"  name="tribe" value={content.tribe} placeholder="Your Tribe" onChange={handleChange} className='form-control form-control-lg' /> 
				                        
                            </div>

                            <div className="form-group">
        <label >Father's Phone Number <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="fatherPhone" value={content.fatherPhone} placeholder="Your father's telephone number" onChange={handleChange} className={errors.fatherPhone  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.fatherPhone}</span>                         
                            </div>

                <div className="form-group">
        <label >Mother's Phone Number <span style={{color:'red'}}>*</span></label>
        <input type="text"  name="motherPhone" value={content.motherPhone} placeholder="Your mother's telephone" onChange={handleChange} className={errors.motherPhone ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.motherPhone}</span>                         
                            </div>
                            
                           
                            

                            <div className="form-group">
        <label >Details of Disability (If Any)</label>
        <input type="text"  name="disabilityDetails" value={content.disabilityDetails} placeholder="Your disability details" onChange={handleChange} className='form-control form-control-lg' /> 
			                       
                            </div>
                        </section>
                        </div> </div>
                        </div>
                        <div className="col-md-2"></div>
                        </div>

                        <div className="row "> 
                        <div className="col-md-2"></div>
                        <div className="card bg-c-green col-md-8">
                                    <div className="card-block">
                                                        <h5 className="text-white m-b-0">UPLOAD DOCUMENTS</h5>
                                                    </div>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                    </div>


                             <div className="row "> 
                        <div className="col-md-2"></div>
            <div className="card col-md-8">
                <div className="card-block">
                   
                   <div className="row">

                   
<section className="col-md-12">

<div className="table-responsive">
        <table className="table table-bordered">
            <tbody>

                <tr >
                    <th >Last Academic Record</th>
                    <td>
                        {	content.recordImage!==''?<a href={ImagesUrl+"/students/documents/"+content.recordImage} target='_blank'>[Click Here To View]</a>:

                        <div className="form-group">
        <label > <strong style={{color:'red'}}>Max Size of 1 Mb </strong>jpg|png|jpeg Only Allow</label>
        <input type="file" name='recordImage' onChange={handleFile('recordImage')} id='recordImage'  className="form-control form-control-lg" />
                                
                            </div> }</td>
                </tr>

                <tr >
                    <th >Birth Certificate / Affidavit of Birth<span style={{color:'red'}}>*</span></th>
                    <td>
                        
                    {	content.birthImage!==''?<a href={ImagesUrl+"/students/documents/"+content.birthImage} target='_blank'>[Click Here To View]</a>: 
                        <div className="form-group">
                    <label > <strong style={{color:'red'}}>Max size of 1 Mb </strong>jpg|png|jpeg Only Allow</label>
        <input type="file" name='birthImage' onChange={handleFile('birthImage')} id='birthImage' className="form-control form-control-lg" />
                                
                            </div>
                            
}</td>
                </tr>
                <tr >
                    <th >Transfer Certificate <br/> </th>
                    <td>
                    {	content.transferImage!==''?<a href={ImagesUrl+"/students/documents/"+content.transferImage} target='_blank'>[Click Here To View]</a>:
                        <div className="form-group">
                    <label > <strong style={{color:'red'}}>Max size of 1 Mb </strong>jpg|png|jpeg Only Allow</label>
        <input type="file" name='transferImage' onChange={handleFile('transferImage')} id='transferImage'  className="form-control form-control-lg" />
                                
                 </div>
}
                 </td>
                </tr>
            </tbody>
        </table>
    </div>

    </section>
                        </div> </div>
                        </div>
                        <div className="col-md-2"></div>
                        </div>
                         
                  
        </div>:''}
        
                        {content.submitted==='false'?                               
                    <div className="col-md-12 text-center">
    <button className="btn btn-inverse text-bold" onClick={handleSubmit}> <i className='fa fa-save'> </i> Save & Continue</button>&nbsp;&nbsp;

                 

                    </div>:''}
                    </div></div>
                    <br/>
                                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(Overview) 