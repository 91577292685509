import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, ImagesUrl, config, familyCode, Currency, FormatNumber, API_URL,} from '../component/include'

import UsePagination from "../component/pageNumber";
import DetailsOrder from './DetailsOrder'
const MyOrder =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    

const [content, setContent] = useState([]);

//const content = props.data;
let [page, setPage] = useState(1);
const PER_PAGE = 10;

const count = Math.ceil(content.length / PER_PAGE);
const dataList = UsePagination(content, PER_PAGE);

const handleChange = (e, num) => {
  setPage(num);
  dataList.jump(num);
};

var pages = [...Array(dataList.maxPage).keys()].map(i =>  i+1);
    




const fetchContent =()=>{
  setNotice({...notice, 
    isLoading: true
});
  var sql ="Select * from tbl_orders where customerId = '"+ familyCode+"' and orderStatus <> 'In Progress' order by ID DESC" ;
  
  const fd = new FormData();
  fd.append("sql", sql);
  
    let url = API_URL+'/exam/result/fetch_raw'
  axios.post(url, fd, config).then(result=>{
    setContent(result.data.data)
    
  })
  .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
  }).finally(()=>{
    setNotice({...notice, 
      isLoading: false
  });
  })
}


useEffect(()=>{
  fetchContent()
},[]);



        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Tuckshop -Orders">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bar-chart"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Tuckshop</a> </li>
                            <li className="breadcrumb-item"><a href="#!">My Order</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
              



<div className="row  ">   <div className="col-sm-12">
                               
                                                

{content&&dataList.currentData().map(item=>
<article key={item.ID} className="card mb-4">
<header className="card-header">
    <a href="#" className="float-right"> <i className="fa fa-print"></i> Print</a>
    <strong className="d-inline-block mr-3">Order ID: {item.orderId}</strong>
    <span>Order Date: {item.dateTime}</span>
</header>
<div className="card-body">
    <div className="row"> 
        <div className="col-md-8">
            <h6 className="bold">Delivery to</h6>

            {JSON.parse(item.shippingAddress).map((item, id)=> <p key={id}>
                    &nbsp; Fullname:  <b>{item.fullname}</b>
                     <br/>
                      &nbsp; Email:  <b>{item.email}</b>
                     <br/>
                     &nbsp; Telephone:  <b>{item.telephone}</b>
                     <br/><i className="fa fa-map-marker text-muted"></i>  {item.address}
                     &nbsp; 
                </p>)}

        </div>
        <div className="col-md-4">
            <h6 className="bold">Status: 
            <span className={`${item.orderStatus==='Delivered'?'text-success':
                    item.orderStatus==='Pending'?'text-info':
                    item.orderStatus==='Canceled'?'text-danger':
                    item.orderStatus==='Pending'?'text-dark':
                    item.orderStatus==='Delayed'?'text-warning':
                    'text-primary'}`}>

                <i className="fa fa-lg fa-shopping-cart"></i> 
                {item.orderStatus} 
            </span>
            </h6>
            <p>Subtotal: {Currency+FormatNumber(item.subtotal)} <br />
             Shipping fee:  {Currency+FormatNumber(item.shippingFee)} <br /> 
             Discount:  {Currency+FormatNumber(item.discount)} <br /> 
             Tax:  {Currency+FormatNumber(item.tax)} <br /> 

             <span className="b">Total:  {Currency+FormatNumber(item.orderTotal)}  </span>
            </p>
        </div>
    </div> 
</div> 
<DetailsOrder orderId ={item.orderId} />

</article>)}

{content && content.length!==0?

<nav className="mb-4" aria-label="Page navigation sample">
<ul className="pagination">
<li className={page === 1 ? ' page-item disabled' : 'page-item'}> <a className="page-link" href="#!" onClick={(e) =>handleChange(e, page - 1)}>Previous</a></li>

{pages.map((item, index) =>
    <li key={index} className={page===item?' page-item  active':'page-item '}><a className="page-link" href="#!" onClick={(e) =>handleChange(e, item)}>{item}</a></li>    
         )}

<li className={page === dataList.maxPage  ? ' page-item disabled' : 'page-item'}  ><a className="page-link" href="#!" onClick={(e) =>handleChange(e, page + 1)} aria-label="Next">Next</a></li>  
</ul>

</nav> :
<div className="card ">
    <div className="card-body text-center m-b-10">
	<h4>No item found</h4>
	<a href="/tuckshop/products" className="btn btn-secondary m-l-5">Make an order?</a>
    </div>
</div>}

    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(MyOrder) 