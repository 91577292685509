import React, {useEffect, useState} from 'react';
import { config,  ServerUrl,  FormatNumber, Currency,  ImagesUrl, ParentUrl, Token, familyCode, API_URL } from '../component/include';
import axios from 'axios';
import { Alerts} from '../component/notify'
import { ValidateIsNew, compare } from '../component/globalFunction';



const QuickView =({data, code, review, images, cartItem, setCatItem, image, setImage})=>{

    const [attributes, setAttributes] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false); 
      const [errors, setErrors] = useState({});
    
      const [notice, setNotice] = useState({
        isLoading : false,
        isQuick:false,
        isDataFetching: false
    });
  
  
  
  
  const handleSubmit = (event, url) =>{
    event.preventDefault();         
    setErrors(ValidateInput(cartItem));
      setIsSubmitting(true);
  }
  
  const ValidateInput =(values)=>{
    let errors = {};   
    
    if(Number(values.price)<=0){
        errors.price ='item could not be added to cart';
        Alerts('Error!', 'danger', errors.price)
    }
    if(Number(values.subtotal)<=0){
      errors.subtotal ='Invalid total amount';
      Alerts('Error!', 'danger', errors.subtotal)
  }
    if(values.quantity===0){
      errors.quantity ='Quantity is required';
      Alerts('Error!', 'danger', errors.quantity)
  }
  
    if(data.bulkPrices!=='[]' && values.quantityPrice===0){
      errors.quantityPrice ='Quantity is required';
      Alerts('Error!', 'danger', errors.quantityPrice)
    } 
   
   
    return errors;
    }
  
  useEffect(()=>{
  if(Object.keys(errors).length === 0 && isSubmitting){
    handleAddToCart()
  }
  },[errors, isSubmitting])
  
  
  const handleAddToCart = () =>{
  
    setNotice({...notice, isLoading: true})     
          
        let formData = cartItem;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
        
  fd.append('jwt', Token)
          fd.append('fileItems',  '[]')
          fd.append('attribute',  JSON.stringify(cartItem.attributes, null, 2))
    
           let url = ServerUrl+'/save_controller.php?tablename=tbl_product_orders';
          axios.post(url, fd, config)
          .then(response =>{
           if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              window.open('/tuckshop/checkout', '_self')
                  } else{
              Alerts('Error!', 'danger', response.data)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, 
                  isLoading: false
              })
          })  
    }
    
    
  
    const getShippingCharge=()=>{
      const ship = data.shippingDetails !=='[]'?JSON.parse(data.shippingDetails):[]
     const answer = ship.length!==0? Number(ship[0].shippingCharge):0;
     return Number(answer);
    }
  
    const getBulkQuantity=(ID, field)=>{
      let answer =data.bulkPrices !=='[]'?JSON.parse(data.bulkPrices).filter(item=>item.quantity===ID):[];
     let result = answer.length!==0?answer[0][field]:0
     return result
    }
    




    const handleChangeSub =()=>{

      var value = Number(cartItem.quantity)
  
          if(Number(cartItem.quantity) >1){
        value = Number(cartItem.quantity) - 1
      }
  
          var price  = Number(data.price)
          var shipping = data.isShippable==='true'? getShippingCharge():0
          var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
        
          var  subtotal = totalPrice * value
        
          setCatItem({...cartItem, quantity : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
        }
  
  
    const handleChangeAdd =()=>{
  var value = Number(cartItem.quantity) + 1
      
      var price  = Number(data.price)
      var shipping = data.isShippable==='true'? getShippingCharge():0
      var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
    
      var  subtotal = totalPrice * value
    
      setCatItem({...cartItem, quantity : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
    }
  

    const handleChangeCart =(event)=>{
      let {name, value} = event.target;
      var price  = Number(data.price)
      var shipping = data.isShippable==='true'? getShippingCharge():0

      //var totalShipping = shipping * Number(cartItem.quantity)
      var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
      var  subtotal = totalPrice * value
    
      setCatItem({...cartItem, [name] : value, shipping:shipping,  quantityPrice:price, price:totalPrice, subtotal:subtotal });
    
    }
    
    
    
    const handleChangeBulk =(event)=>{
      let {name, value} = event.target;
     let qtyPrice = value!==''? Number(getBulkQuantity(value, 'price')):0
      
      var price  = data.bulkPrices!=='[]'?qtyPrice: Number(data.price)
      var shipping = data.isShippable==='true'? getShippingCharge():0
      var totalPrice = price+  Number(cartItem.totalAttributePrice) + shipping
      var subtotal = price+ Number(cartItem.totalAttributePrice)
    
      setCatItem({...cartItem, quantity : value, shipping:(shipping * Number(cartItem.quantity)),  quantityPrice:price, price:totalPrice, subtotal:subtotal });
    }
    
    
    const getOptionItem=(attributeCode, optionID, field)=>{
    let predefine = getAttributeValue(attributeCode)
    const answer = predefine.length!==0&&optionID!==''?predefine.filter(item=>item.ID===optionID)[0][field]:0
    return answer
    }
    
    
    
    const handleChangeAttribute =(event, code, attName)=>{
              
      let {name, value} = event.target;
      const newAttribute = cartItem.attributes;
    let items =[]
        items.push({attributeCode:code, attributeName:attName, optionID:value, optionName:String(getOptionItem(code, value, 'name')), optionPrice:(Number(getOptionItem(code, value, 'price')) * Number(cartItem.quantity))  })
    
        const attributeList = items.concat(newAttribute)
        const allAttribute =	attributeList.map(e=>e['attributeCode'])
                    .map((e,i,final)=>final.indexOf(e)===i&&i)
                    .filter(e=>attributeList[e])
            .map(e=>attributeList[e])
    
          var totalAttributePrice = 0;
          allAttribute.map(item=>totalAttributePrice += Number(item.optionPrice))
    
          var shipping = data.isShippable==='true'? getShippingCharge():0
          var totalShipping = shipping * Number(cartItem.quantity)
          var bulkAmount = Number(getBulkQuantity(cartItem.quantity, 'price'))
          var qtyPrice  = data.bulkPrices!=='[]'?bulkAmount: Number(data.price)* Number(cartItem.quantity)
          var totalPrice = qtyPrice + totalAttributePrice + totalShipping
          var subtotal = totalPrice 
    
      setCatItem({...cartItem, attributes : allAttribute, shipping:totalShipping, graphicFee:data.designFee,  totalAttributePrice:totalAttributePrice, subtotal:subtotal, price:totalPrice})
    
    }
    
    
    const fetchProductAttribute =()=>{
      
      var sql ="Select code, attributeName, predefineValues from tbl_products_attributes " ;
          
      const fd = new FormData();
      fd.append("sql", sql);
      
    let url = API_URL+'/exam/result/fetch_raw'
      
      axios.post(url, fd, config).then(result=>{  
        setAttributes(result.data.data)
      })
      .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
      })
  
  }
  
    const getAttribute =(code, field)=>{
      const result = attributes&&attributes.filter(item=>item.code===code)
      const answer = result.length!==0?result[0][field]:[]
      return answer
    } 
    const getAttributeValue =(code)=>{
      const result = attributes&&attributes.filter(item=>item.code===code)
      const answer = result.length!==0?result[0].predefineValues:[]
      return answer.length!==0?JSON.parse(answer):[]
    } 
  
  
  
    
    const handleWhislist =(code)=>{
       
          
      setNotice({...notice, isLoading: true})  
    const fd = new FormData();
    fd.append('code', Math.random().toString(36).substring(2,9))
    fd.append('productCode', code)
    fd.append('jwt', Token)
    fd.append('customerCode', familyCode)
    let url = ServerUrl+'/save_controller.php?tablename=tbl_whishlist';
      axios.post(url, fd, config)
      .then(response =>{
          if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message)
              
                  }else if(response.data.type ==='info'){
                      Alerts('Saved!', 'info', response.data.message)
                      
                          } else{
              Alerts('Error!', 'danger', response.data)
                  }    
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
      }).finally(()=>{
          setNotice({...notice, isLoading: false })
      }) 
      
    }

  
  const handleResetImage =()=>{
    setImage('')
  }
  
  const handleToggleImage=(url)=>{

    setImage(url)
}
  
      useEffect(()=>{
       // fetchProductAttribute()
       }, []);
      
       
    return (<div className="modal-quick-view modal fade" id={'product-'} tabIndex="-1">
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title product-title">
              <a href={"/product/"+data.code} data-toggle="tooltip" data-placement="right" title="Product details">{data.productName}
              <i className="fa fa-arrow-right font-size-lg ml-2"></i>
              </a>
        </h4>
          <button className="close" type="button" data-dismiss="modal" onClick={handleResetImage} aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div className="modal-body">
          <div className="row">
    
          <aside className="col-md-6">
<div className="card">

<article className="gallery-wrap"> 
	<div className="img-big-wrap">

	  <div> <a href="#"><img onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+image} /></a></div>
	</div> 

	<div className="thumbs-wrap">
    {images.slice(0,4).map((item, index)=>
	  <a href="#" onClick={()=>handleToggleImage(item.imageUrl)} key={index} className="item-thumb"> <img  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/noImage.png"}}  src={ImagesUrl+"/product/"+item.imageUrl} alt={index} /></a>)}
      
	</div> 
</article> 
</div> 
		</aside>


		<main className="col-md-6">
<article className="product-info-aside">

<h2 className="title mt-3"> &nbsp;{data.productName} </h2>
<div className="rating-wrap my-3">
	
<div className="br-widget">
    
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 m-r-5 text-primary'></i>
    <i className='fa fa-star f-18  m-r-5 text-primary'></i>
    <i className='fa fa-star f-18 '></i>

    
    </div>


	<small className="label-rating text-muted">{review&&review.length===0?  'No reviews yet': review.length+' Reviews'}</small>

    {ValidateIsNew(data.isNew, data.isNewStart, data.isNewEnd)?
            <span className="badge badge-success"> NEW </span>:''}

    {data.isShippable==='true'?
	<small className="label-rating text-success"> <i className="fa fa-clipboard-check"></i> Required shipping Fee of {Currency+FormatNumber((getShippingCharge() * Number(cartItem.quantity)))} </small>
    :''} 


</div> 




<div className="mb-3"> 

	<var className="price h4 text-price">{Currency+FormatNumber(data.price)}</var> 
	{data.oldPrice!=='0'?<del className="text-muted">{Currency+FormatNumber(data.oldPrice)}</del> :''}

</div>

<p dangerouslySetInnerHTML={{__html: data.shortDescription}}></p>




<div className="form-row  mt-4" >

{data.attributes&&JSON.parse(data.attributes).map((datas, id)=>
<div className="form-group" key={id}>
  <div  className="d-flex justify-content-between align-items-center pb-1">
  {getAttributeValue(datas.attribute).length!==0?  <label className="font-weight-medium" htmlFor={id}>{getAttribute(datas.attribute, 'attributeName')}</label>:''}
    
  </div>
  {datas.controlType==='select'?

  <select className="mr-2 form-control" 
  name={getAttribute(datas.attribute, 'attributeName')}
  onChange={(e)=>handleChangeAttribute(e, datas.attribute, getAttribute(datas.attribute, 'attributeName'))} 

  value={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===datas.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===datas.attribute)[0].optionID:'' }>
    <option value="">Select {getAttribute(datas.attribute, 'attributeName')}</option>

  {getAttributeValue(datas.attribute).map(list =>
  <option  name={list.name}  value={list.ID} price={list.price} key={list.ID}>
    {list.name}
  </option>
)} 
  </select>:
  datas.controlType==='radio'?

  <div className=" filter-content">
  <div className="form-radio"> 
 
 {getAttributeValue(datas.attribute).map(list => <div key={list.ID} className="radio radiofill radio-inline">
 <label>
 <input type="radio" 
 checked={cartItem.attributes.length!==0&&cartItem.attributes.filter(item=>item.attributeCode===datas.attribute).length!==0?cartItem.attributes.filter(item=>item.attributeCode===datas.attribute)[0].optionID===list.ID?true:false:false} name={list.name} 
 onChange={(e)=>handleChangeAttribute(e, datas.attribute, getAttribute(datas.attribute, 'attributeName'))} value={list.ID} price={list.price} id={list.ID} />
 <i className="helper"></i>{list.name}
 </label>
 </div>)}
 </div>
   </div>
:
  
  ''}
</div>)} 

</div>


<br/>

{data.isPrinting!=='false'?
<div>Total Price: <span className="price price-colour" style={{fontSize:'20px',fontWeight: '600'}}>{Currency+FormatNumber(cartItem.subtotal)}</span></div>:''}



<div className="form-row  mt-6">



{data.isOutOfStock==='false'? 

<div className="form-row  mt-6">

{data.bulkPrices!=='[]'?
<select className="mr-2 form-control" value={cartItem.quantity} name="bulkQuantity" onChange={handleChangeBulk} style={{width: '15rem'}}>
    <option  value=''   >Select Quantity</option>
    {data.bulkPrices&&JSON.parse(data.bulkPrices).map(price =>
    <option key={price.ID} value={price.quantity} price={price.price}>{price.quantity} </option>
    )}
  </select>:
  <div className="form-group col-md flex-grow-0">
  <div className="input-group mb-3 input-spinner">
    <div className="input-group-prepend">


    <button className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-minus" onClick={handleChangeSub}> − </button>
    </div>
    <input className="form-control" type="text" onFocus={(e)=>e.target.select()} name="quantity" onChange={handleChangeCart} value={cartItem.quantity} min='1' />
    <div className="input-group-append">
        
      <button  className="btn btn-default btn-number shadow-none btn-sm" type="button" id="button-plus" onClick={handleChangeAdd}> + </button>
    </div>
  </div>
</div> 

}
 


</div>:<div className="price-wrap">
<span className="price text-price">Out of stock</span> 
</div>}




<div className="col-lg-12 col-sm-12 mob-product-btn m-t-20">

{data.isPrinting==='true'?
<a className="btn btn-primary waves-effect col-md-5 waves-light m-r-20 " href="#orderOption" data-toggle="modal" > <i className="icofont icofont-cart-alt f-16"></i>Add to cart</a>:

<button className="btn btn-primary  col-md-5 waves-effect waves-light m-r-20 " disabled={notice.isLoading?true:false} onClick={handleSubmit} ><i className="icofont icofont-cart-alt f-16"></i>{notice.isLoading?'Please wait..':' Add to Cart'}</button>}

<button type="button" onClick={()=>handleWhislist(data.code)} className="btn btn-outline-primary waves-effect waves-light m-r-20" data-toggle="tooltip" title="" data-original-title="Add to wishlist">
<i className="icofont icofont-heart-alt f-16 m-0"></i>
</button>

</div>


</div>


<div className="col-lg-12 ">
             
<h6 className="d-inline-block align-middle font-size-base my-2 mr-2">Share:</h6>
              
              <a className="share-btn sb-twitter mr-2 my-2" href={"http://www.twitter.com/share?url="+ParentUrl+"/product/"+code} target='_blank' ><i className="fa fa-twitter"></i> Twitter</a>
              
              <a className="share-btn sb-instagram mr-2 my-2" href="#!"><i className="fa fa-instagram"></i> Instagram</a>
              
              
              <a className="share-btn sb-instagram mr-2 my-2" href={"http://wa.me/?text="+ParentUrl+"/tuckshop/product/details/"+code}><i className="fa fa-whatsapp"></i> Whatsapp</a>

              <a className="share-btn sb-facebook my-2" href={"http://www.facebook.com/share.php?u="+ParentUrl+"/product/"+code} target='_blank' ><i className="fa fa-facebook-f"></i> Facebook</a>

            </div>

</article> 
		</main> 

  
    
<a  href={"#"+data.code} id={'product-'+data.code} data-toggle="modal" > </a>

          </div>
        </div>
      </div>
    </div>
    
    
    
    <div className="modal fade" id="orderOption" tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
          <div className="modal-content">
           
            <div className="modal-header">
            <h4>How would you love to order?</h4>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
    
            </div>
            <div className="modal-body tab-content py-4">
    
            <div className="row">
          
          <div className="col-md-6 col-sm-12">
          <div className="sales-pitch">
          <a href={"/tuckshop/order/customDesign/"+data.code} style={{color:'blue'}}>
          <img src={ImagesUrl+"/icon2.png"} alt="" className="round" width="100" /> <p>Upload your complete design<br />(AI, PDF, PSD, CDR, JPEG, PNG)</p>
          </a>
          </div>
          </div>
          <div className="col-md-6 col-sm-12">
          <div className="sales-pitch">
          <a href={"/tuckshop/order/requestExpertDesign/"+data.code} style={{color:'blue'}}>
          <img src={ImagesUrl+"/icon3.png"} alt="" className="round" width="100" /> <p>Let our experts help<br />you with your design</p>
          </a>
          </div>

          </div>
          </div>
    
          </div>
          </div>
        </div>
      </div>
    
    
    </div>)
}


export default React.memo(QuickView) 