import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token, config, sectionID, familyCode, API_URL} from '../component/include'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { getTime } from '../component/globalFunction'

const StudentDeparture =()=> {
   
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   

    const [allArrival, setAllArrival]= useState([])
    const [students, setStudents] = useState([]);

   
const long=(conv)=>{
    var date = new Date(conv).toDateString().slice(3, 15)
    
    return date
}

   
    const fetchStudents =()=>{
        const fd = new FormData();
            fd.append('jwt', Token); 
            let sql = "Select ID, code, studentName, sectionID,admissionNumber, gender, fatherName, father_mobile from tbl_students where family_name ='"+familyCode+"' order by ID DESC"

            let url = API_URL+'/exam/result/fetch_raw'      
            fd.append("sql", sql)
        axios.post(url, fd, config).then(result=>setStudents(result.data.data))
    }

   
   
    const fetchAllArrival =()=>{
        const fd = new FormData();
        let sql = "Select a.ID, a.code, s.studentName, s.admissionNumber, a.arrivalType, a.arrivalDate,	a.arrivalTime,	a.description from tbl_student_arival_departure a, tbl_students s  where a.student = s.code and s.sectionID ='"+sectionID+"' order by a.ID DESC"

        let url = API_URL+'/exam/result/fetch_raw'    
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>setAllArrival(result.data.data)   
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
    }





   
    const tableHeader = [
        {dataField: 'studentName', text: 'Student',  editable:false, formatter: (cell, row)=>`(${row.admissionNumber}) ${cell}  `},
        {dataField: 'arrivalType', text: 'Type', editable:false },       
        {dataField: 'arrivalDate', text: 'Date', formatter:(cell, row)=>long(cell), editable:false },
    {dataField: 'arrivalTime', text: 'Time', formatter:(cell)=>getTime(cell), editable:false}, 
    {dataField: 'description', text: 'Description',  editable:false, formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>}
     ];

     
    const TableRecord=(props)=>{
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
          
            let url = ServerUrl+'/updateById_controller/tbl_student_arival_departure'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
useEffect(()=>{
    fetchAllArrival()
    fetchStudents();
},[]);

        return (  
            <React.Fragment>
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Arrival & Departure">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Late Arrival & Early Departure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
				
		
	

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Student Records</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allArrival}  table={'tbl_student_arival_departure'} columns={tableHeader}  />} 

    </div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
</React.Fragment>
 );
        
}

export default React.memo(StudentDeparture) 