import React, {useState, useEffect} from 'react';
import Preloader from '../component/preloader'
import Footer from '../../component/footer'
import Header from '../component/header'
import Sidebar from './sidebar'

import { config,  userCode, API_URL} from '../../component/include'
import axios from 'axios'
import { Alerts } from './notify';

import { BrowserRouter as Router} from 'react-router-dom'
import { useDispatch } from 'react-redux';

import ProtectedView from '../protected_view';

const StudentTemplate = (props) =>
{
    const [user, setUser] = useState({
		userPassword:'',
        loading:false
    })
    const [errors, setErrors] = useState({});
    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
    }    
    const dispatch = useDispatch()


    const fetchStaff = async ()=>{
   let url = API_URL + '/fetch/data/tbl_staff'
        const {data} = await axios.get(url,  config)
        dispatch({
            type:'SET_RECORDS',
            name:'staff',
            data:data.data
        })
    }

    const fetchSchoolInfo = async ()=>{
        let url = API_URL + '/fetch/data/tbl_basic_details'
        const {data} = await axios.get(url,  config)
            dispatch({
                type:'SET_RECORDS',
                name:'schoolInfo',
                data:data.data
            })
    }




    const fetchClass = async ()=>{
    let url = API_URL+'/fetchfield/tbl_school_class/schoolClass/ID/1'
        const {data} = await axios.get(url, config)
        dispatch({
            type:'SET_RECORDS',
            name:'schoolClass',
            data:data.data
        })
    }


    


	const submit=()=>{
       
		if(!user.userPassword){
            setErrors({errors, userPassword:'Please enter your password'})
        }else{

             setUser({...user, loading:true})
        const fd = new FormData();
       
		fd.append('code', userCode)
		fd.append('password', user.userPassword)
           let url = API_URL+'/student/password/unlock';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.status ===200){
            localStorage.removeItem('stlcksr')
            document.getElementById('closeLockscreens').click()
			
			}else{
                setErrors({errors, userPassword:response.data.message})
               
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
            window.location.href='/'
			 
		}).finally(()=>{
            setUser({...user, loading:false})
        })     
} 

    }



    useEffect(()=>{

        fetchStaff();
        fetchSchoolInfo()
        fetchClass()
    }, [])
    return (<Router>

        
        {/* <!-- Pre-loader start --> */}
        <Preloader />
        {/* <!-- Pre-loader end --> */}


        <div id="pcoded" className="pcoded">
    <div className="pcoded-overlay-box"></div>

    <div className="pcoded-container navbar-wrapper">

       {/* Header Component Here */}

       <Header />
       <a href="#!" data-toggle="modal" id='openlockscreenModals' data-target='#lockscreens' ></a>
  <div className="modal fade " data-backdrop="static" data-keyboard="false" id='lockscreens' tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
               
                <div className="modal-body">
                <div className="row m-b-20">
			<div className="col-md-12">
				<h3 className="text-center"><i className="feather icon-lock text-primary f-60 p-t-15 p-b-20 d-block"></i></h3>
			</div>
</div>
			<div className="form-group form-primary">
        <button type="button" className="close" id='closeLockscreens' data-dismiss="modal" aria-label="Close"></button>
				<input type="password"  name="userPassword"  value={user.userPassword} placeholder="Enter your password to unlock" onChange={handleChange} className={errors.userPassword  ? 'form-control form-control-danger' : 'form-control'} /> 
				
				
			</div><span className=" form-bar error">{ errors.userPassword}</span>
			<div className="row">
				<div className="col-md-12">
				{user.loading===false?	<button type="button" onClick={submit}  className="btn btn-primary btn-md btn-block "><i className="icofont icofont-lock"></i> UnLock Screen </button>:<button type="button" disabled='true' className="btn btn-warning btn-md btn-block "><i className="fa fa-refresh fa-spin"></i> Please wait... </button>}
				</div>
			</div>
                </div>
            </div>
        </div>
    </div>
{/* The  header End here */}


 {/* 
 <!-- Sidebar inner chat end--> */}
 <div className="pcoded-main-container">
     <div className="pcoded-wrapper">

     
        {/* Navbar start here */}

        <Sidebar menu = {props.menu}
        submenu= {props.submenu} /> 

{/* Navbar end here */}

    <div className="pcoded-content">
        <div className="pcoded-inner-content">

            {/* <!-- Main-body start --> */}
           

            <ProtectedView />

            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div> 
           {/* The Main Body close here */}            
           </div></div>

            


</div>
            </div> 
        
        
        
            </Router>)
}
export default React.memo(StudentTemplate)