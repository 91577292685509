import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, Token, KAYUS_URL, PortalType, API_URL} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'

 const CentraLogin =()=> {


var usertype = new URLSearchParams(window.location.search).get("type")

	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [user, setUser] = useState({
		email:'',
		admissionNumber:'',
		username:'',
		password:'',
		remember:false,
		type:'Parent',
		title:'Parent Login',
		schoolInfo:[],
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});


    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    
	

	function submitAdmin(event){  
		event.preventDefault();

		if(navigator.onLine){
		setNotice({...notice, isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }


		let url = ServerUrl+'/login_parent_controller.php?tablename=tbl_login_user';
		
		if(user.type==='Student'){
			url = API_URL+'/student/login';

		}else if(user.type==='Applicant'){
			url = ServerUrl+'/login_agent_controller.php?tablename=tbl_login_user';
		}

           
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
		
	var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
	var inOneMinutes = Date.now() + 58 * 60 * 1000;
	var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
	
	Cookies.set('_kapexpt', inSixtyMinutes, {expires: inOneHours })
	Cookies.set('_kapbexpt', inOneMinutes, {expires: inOneHours })

	Cookies.set('katkrspw', response.data.reset_password, {expires: inOneHours })
Cookies.set('kapkauscd', response.data.code, {expires: inOneHours })
Cookies.set('kapkamtk', response.data.jwt,  {expires: inOneHours })   

Cookies.set('kaportal', user.type, {expires: inOneHours })		
Cookies.set('kafmcdk', response.data.familyCode,  {expires: inOneHours })
Cookies.set('kasclkysd', response.data.classID,  {expires: inOneHours })
Cookies.set('kassckayd', response.data.sectionID,  {expires: inOneHours })

Alerts('Welcome!', 'success', response.data.message)
var queryString = new URLSearchParams(window.location.search).get("refferer")



if(user.type==='Parent'){

	if(response.data.reset_password ==='Yes'){
		window.open('/change_password', '_self')
	}else if(queryString!==null){
		window.open(queryString, '_self')
	  }else{
		window.open('/dashboard', '_self')
	  }

}else if(user.type==='Student'){
	if(response.data.reset_password ==='Yes'){
		window.open('/student_portal/student/change_password', '_self')
	}else if(queryString!==null){
		window.open(queryString, '_self')
	  }else{
		window.open('/student_portal/exam/myresult', '_self')
	  }
}else if(user.type==='Applicant'){
//applicant here
window.open('/applicant_portal/dashboard', '_self')
} 


			}else if (response.data.type ==='error'){
				Alerts('Error!', 'info', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'info', error.message)
			 
		}).finally(()=>{
            setNotice({...notice, 
				isLoading: false
			}) 
        })   
		
	}else{
		Alerts('Error!', 'info', 'No internet connection')
	}
 }


useEffect(()=>{  
 if(Token!== null && Token !==undefined){

		if(PortalType==='Parent'){
			window.open('/dashboard', '_self')
		}else if(PortalType==='Student'){
			window.open('/student_portal/exam/myresult', '_self')
		}else{
			window.open('/applicant_portal/dashboard', '_self')
		}
		
		 }  
},[]);


useEffect(()=>{

	if(usertype && usertype!==null){
		setUser({...user, title:usertype+' Login', type:usertype})
	}

}, [])

        return (<>
		
		
<div className="" style={{backgroundColor:'white', height: '100vh'}}>

<div className="row">

	<div className="col-md-5 ">

<div className="  border p-4 ">



<div className=" m-b-20 mt-20 ">
		
			  <div className="text-center">
	  <img src={ImagesUrl+'/about/logo.png'} className="img-100" alt="logo.png" />
	  </div>
				  <h3 className="text-center" >{user.title} </h3>
	
		  </div>


		  <form action="#" method="post" role={'presentation'} onSubmit={submitAdmin}>



<div className="form-group form-primary">

<input type="text"  name="username"  required autoComplete='off' role={'presentation'} value={user.username} placeholder="Your Email Address or Admission Number" onChange={handleChange} className={errors.username  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
<span className=" form-bar error">{ errors.username}</span>

</div>

<div className="form-group form-primary mb-4">
<input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
<span className=" form-bar error">{errors.password }</span>


</div>



<div className="row m-t-25 mb-4 text-left">
			  <div className="col-12">
				  <div className="checkbox-fade fade-in-primary d-">
					  <label>
						  <input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
						  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
						  <span className="text-inverse">Remember me</span>
					  </label>
				  </div>
				  <div className="forgot-phone text-right f-right">
					  <a href="/forgot_password" className="text-right f-w-600"> Forgot Password?</a>
				  </div>
			  </div>
		  </div>

		  {notice.isLoading ?	<button type="button" disabled className="btn btn-block btn-primary"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
<button type="submit" className="btn btn-block btn-lg btn-primary" >Login as {user.type} </button>}

<span className="d-block text-center my-4  text-bold">&mdash; OR &mdash;</span>
<div className="social-login">

	

<a href="#!" onClick={()=>setUser({...user, title:'Parent Login', type:'Parent'})} className="facebook btn ">
<span className="fa fa-users mr-3"></span> PARENT LOGIN
</a>



<a href="#!" onClick={()=>setUser({...user, title:'Student Login', type:'Student'})} className=" btn  btn-success ">
<span className="fa fa-user mr-3"></span> STUDENT LOGIN
</a>






<a href="#!" onClick={()=>setUser({...user, title:'Applicant Login', type:'Applicant'})}  className=" btn  btn-danger ">
<span className="fa fa-user mr-3"></span> NEW APPLICANT LOGIN
</a>
</div>
</form>
<hr/>
		  <div className="row">
			  <div className="col-md-10">
				  <p className="text-inverse text-left m-b-0">Thank you.</p>
				  <p className="text-inverse text-left"><a href={KAYUS_URL} target={'_blank'}><b className="f-w-600">Powered By KAYUS</b></a></p>
			  </div>
			  <div className="col-md-2">
			  <a href={KAYUS_URL} target={'_blank'}>
			  <img src={ImagesUrl+'/about/kayus.png'} alt="KAYUS" className="img-30" />
			  </a>
			  </div>
		  </div>
		



</div>
</div>

<div className="col-md-7">

<div className=" img-bg" style={{backgroundImage: `url(${ImagesUrl+'/advert/ad.png'})`, backgroundRepeat:'no-repeat', backgroundSize:'contain', height: '100vh'}}>

	
	</div>
</div>



</div>
</div>	
</>
 );
        
}

export default React.memo(CentraLogin)  