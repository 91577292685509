import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const FrontUrl = "https://form."+mainUrl 
export  const loginUrl = FrontUrl+'/applicant_login';
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const API_URL = "https://api."+mainUrl+'/api'
export  const ImagesUrl = "https://app."+mainUrl+'/images'
export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const LocalUrl = 'localhost'
export  const KAYUS_ULR = 'https://kayus.org'




export const studentName = Cookies.get('appstnm')?CryptoJS.AES.decrypt(Cookies.get('appstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const agentCode =  Cookies.get('appid')?Cookies.get('appid'):'null'

export const Token = Cookies.get('apptk')?Cookies.get('apptk'):'null'




export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'

export  const helpEmail = 'admin@aduvieinternationalschool.org';
export  const helpMobile = '080-8939-4474';


export const schoolName = 'ADUVIE INTERNATIONAL SCHOOLS'


export  const PSKLIVE = 'pk_live_a678773fd32141f0c716254badad828122a503be';
export  const PSKTEST = 'pk_test_bc1dcf31f11d37d3e8eecd2367f6b8560ff929a5';

export const configApi = {
    headers: { 
        'content-type': 'application/json',
    'Authorization': `Bearer FLWSECK-aed05886d2886cce20d998480b009fea-X`}
} 